import React from 'react';
import "./base/sitebase1.css"
import Lottie from 'react-lottie'
import animation from './lottie.json'
// import "./dashboard/dashboard.css"


class Loader extends React.Component {
    constructor(props) {
        super(props);
    //    this.state = {};

        this.state = this.props;
        
        
    }

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet'
            }
          };
          if(this.props.fullHeight){
            return (
                <div className={this.props.theme == "light" ? "border-box bgwhite blackspan56 weight500 shadow-box" : "border-box bgdark-theme2 eespan weight500 shadow-box"} style={{width : "100%", height : "100%"}}>
                    <table className="stdtable"><tbody><tr><td valign="center">
                            <Lottie options={defaultOptions}
                                height={150}
                                width={150}
                            />
                    </td></tr></tbody></table>
                </div>
              );
          }else{
            return (
                <div className={this.props.theme == "light" ? "border-box bgwhite blackspan56 weight500" : "border-box bgdark-theme2 eespan weight500"} style={{width : "100%", height : "auto"}}>
                    <table className="stdtable"><tbody><tr><td valign="center">
                            <Lottie options={defaultOptions}
                                height={150}
                                width={150}
                            />
                    </td></tr></tbody></table>
                </div>
              );
          }
      
    }
  }


export default Loader;