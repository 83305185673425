import React,{useState, useContext} from 'react'
import Constants,{getCurrencySymbol} from "../../constants";
import API from '../../api';
import {countryContext} from './NewRightPanel'
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Copy from './../copy.svg';
import Copy2 from './../copy1.png';

const Order = (props) => {
    const { country } = useContext(countryContext)  
    const [copyText,setCopyText] = useState('')  
    const [orderDetails,setOrderDetails] = useState({
                expand : false,
                downloadBill: false,
                isManager: (   localStorage.getItem("role") == "manager" || 
                            localStorage.getItem("user_id") == "204358" ||
                            localStorage.getItem("user_id") == "204359" ||
                            localStorage.getItem("user_id") == "174721" ||
                            localStorage.getItem("user_id") == "280466" || 
                            localStorage.getItem("user_id") == "904965225" || 
                            localStorage.getItem("user_id") == "98874660191"
                            ) ? true :false
            })     
    const  status = props.order && Constants.getOrderStatus(props.order.order_type, props.order.order_type==110?props.order.acc_line_items?props.order.acc_line_items.current_status:1:props.order.status_id);
    var update_items = ""; 
    var update_services =  "";
    if(props.order.update_items && props.order.update_items.length > 0){
    for (var i = 0; i < props.order.update_items.length; i++){
        var item = props.order.update_items[i];
        // console.log(item);
        if (i === 0){
            update_items+=(item.package_name ? item.package_name : "");
            update_services += (item.service_name ? item.service_name : "")
        }else{
            if(item.package_name!="")
                update_items+= (item.package_name ? update_items ?  ", "+item.package_name : item.package_name : "");
            if(item.service_name)
                update_services+= (item.service_name ? update_services ? ", "+ item.service_name : item.service_name : item.service_name);
        }
    }
    }
    if (props.order.update_items && props.order.update_items.length == 0){
        if(props.order.initial_problem){
            var items = props.order.initial_problem.split("|");
            items.forEach(function(item){
                if(item.startsWith(" Title:")){
                    update_items += item.substring(7, item.length);
                }
            });
        }
    }
    let orderType = props.order.order_type ? props.order.order_type : "0";
    let status_id = props.order.status_id ? props.order.status_id : "0";
    let complete_status = status_id == 21 ? 'Cancelled' : status_id < 90 ? 'Ongoing' : 'Completed';
    let pickup_status = status_id == 21 ? 'Cancelled' : status_id < 20 ? 'Ongoing' : 'Completed';
    let pickup_date = props.order.pick_date ? (props.order.pick_date.$date ? Constants.get_DateString1Date(props.order.pick_date.$date) : Constants.get_DateString1Date(props.order.pick_date)) : "NA";
    let pickup_time = status_id != 21 ? pickup_date && props.order.time_slot ? `${pickup_date} ${props.order.time_slot}` : pickup_date : "NA";
    let showDownloadBtn = ((orderType == 101 || orderType == 107) && status_id >= 50) || (orderType == 103 && status_id >= 1200) ? true : false;

       const clickEscalationBtn = (event, orderId, amount, action, statusId,orderType,skuCode='') => {
            props.addEscalation(orderId, amount, action, statusId, orderType, skuCode);
            event.stopPropagation();
        }
    
        const handleClick = () =>  {
            props.order && props.order.order_type!==110 && props.onRightOrderClick({...props.order, ...{goAppEarning : props.goAppEarning}});
        }
        const showFile = (pdfData, name) => {
            var anhor = document.createElement("a");
            const blob = new Blob([pdfData], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            anhor.href = url;
            anhor.download = name;
            anhor.click();
            setOrderDetails((prev)=>({...prev,downloadBill: false}))
        };

        const billDownload = (event, orderId) => {
            if(!orderDetails.downloadBill){
                  event.stopPropagation();
                API.downloadBill(orderId,country)
                  .then((res) => res.blob())
                  .then((res) => showFile(res, "Bill_" + orderId))
                  .catch((err) => setOrderDetails((prev)=>({...prev,downloadBill: false})));
                  setOrderDetails((prev)=>({...prev,downloadBill: false}))
                }
            //   event.stopPropagation();
        }
        const handleActions = (e, source, text) => {
            let obj = {};
            obj['source'] = source;
            obj['text']   = text;
            obj['orderId'] = props.order.order_id;
            obj['skewId'] = props.order && props.order.acc_line_items?props.order.acc_line_items.sku_code:''
            let refund_amount = 0;
            let refund_id = "NA"
            if(props && props.order && props.order.refund && props.order.refund.length > 0){
                let refund = props.order.refund
                refund_amount = refund && refund.length > 0 && refund.reduce((acc, val) => acc + (parseInt(val['amount_refunded'] )|| 0),0);
                refund_id = refund && refund.length > 0 ? refund[0].refund_id : "NA"
            }
            obj['refundId'] = refund_id;
            obj['refundAmount'] = refund_amount;
            obj['orderType'] = props.order && props.order.refund ? props.order.order_type : "101";
            obj['amount'] = props.order && props.order.payments?props.order.payments.reduce((acc, val) => acc + (parseInt(val['transaction_amount'] )|| 0),0):0
            props.handleAccountAction(obj);
            e.stopPropagation();
        }

        const handleMaskedCopy=(text, id)=>{
            if(text){
                navigator.clipboard.writeText(text);
                setCopyText(`Copied: ${text}`)
            }
        }

        const sendCurrentStatus = (awbNumber,trackLink) =>{
            const text = `Your <b>GoMechanic Accessories</b> Order has been <b>Dispatched</b> from our Warehouse. <br> 
            - <b>AWB Number</b> : ${awbNumber}
            - <b>Delivery Partner Tracking Link</b> : <a href=${trackLink} target='_blank'>${trackLink}</a> <br> <br>
            <b>We'll notify you once the Order is Out for Delivery.</b>`
            props.onGlobalSend(text,'statusMessage',props.selectedTab)
        }
            return(
                <>
                <div className={props.theme == "light" ? "border-box clearfix card bgwhite addboxes commutes leftalign orderMain blackspan7" : "border-box clearfix card bgdark-theme1 addboxes commutes leftalign orderMain ddspan"} onClick={() => {handleClick()}}>
                    <div className={props.theme == "light" ? "flexBox border-box clearfix leftalign title weight500 uppercased blackspan7" : "flexBox border-box clearfix shadow-box leftalign title weight500 uppercased"}>
                        <div className="border-box clearfix left">
                            <div className="border-box clearfix leftalign weight600">
                                {props.order.order_id}
                            </div>
                            <div className="border-box clearfix leftalign weight400 code">
                            <span style={{textTransform:'none'}}>Placed on</span> {props.order.created_at ? (props.order.created_at.$date ? Constants.get_DateString2(props.order.created_at.$date) : Constants.get_DateString2(props.order.created_at)) : ""}
                            </div>
                        </div>
                        <div className="border-box clearfix rightalign right weight700 orderBoxStatus">
                            <span className="position">
                                {status}
                            </span>
                        </div>
                    </div>
                    {Constants.checkViewPermission('order','order-middle')&& props.order && props.order.order_type !==110 &&
                    <div style={{borderBottom: "1px solid #dddddd"}} className={props.theme == "light" ? "border-box clearfix leftalign details blackspan9 orderSecondBox" : "border-box clearfix leftalign details bbspan orderSecondBox"}>
                        {props.order.car && 
                        <div className="orderMiddle">
                            <img className="car-logo" src={props.order.car ? props.order.car.car_tbl.model.image_path : ""} width="50" />
                            <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                                <div className={props.theme == "light" ? "regNo" : "regNo1"}> {props.order.car ? props.order.car.registration_no : ""} </div>
                                <div className={props.theme == "light" ? "carName" : "carName1"}>{props.order.car ? props.order.car.car_tbl.brand.name : ""} {props.order.car ? props.order.car.car_tbl.model.name : ""} {props.order.car ? props.order.car.car_tbl.car_type.name : ""}</div>
                            </div>
                        </div>
                        }
                        <div style={{borderTop: "1px solid #dddddd"}}>
                        <div className="orderDates">
                            <div>
                                <div className="orderDetailItem1">Pick up Time</div>
                                <div className="orderDetailItem2">{pickup_time}</div>
                            </div>
                            <div className="orderDetailItem3">
                                <div className={`orderDetailItem2 orderStatus  ${pickup_status == "Completed" ? "orderStatusComplete" : "orderStatusOngoing"}`}>{pickup_status}</div>
                            </div>
                        </div>
                        <div className="orderDates">
                            <div>
                                <div className="orderDetailItem1">Order Completed</div>
                                <div className="orderDetailItem2">{props.order.delivery_date ? (props.order.delivery_date.$date ? Constants.get_DateString2(props.order.delivery_date.$date) : Constants.get_DateString2(props.order.delivery_date)) : "NA"}</div>
                            </div>
                            <div className="orderDetailItem3">
                                <div className={`orderDetailItem2 orderStatus ${complete_status == "Completed" ? "orderStatusComplete" : "orderStatusOngoing"}`}>{complete_status}</div>
                            </div>
                        </div>
                        <div className="orderPackages">
                            <div>
                                <div className="orderDetailItem1">Package</div>
                                <div className="orderDetailItem2">{update_items ? update_items : "NA"}</div>
                            </div>
                            <div>
                                <div className="orderDetailItem1">Parts & Services</div>
                                <div className="orderDetailItem2">{update_services}</div>
                            </div>
                        </div></div>
                    </div>}
                    {props.order && props.order.order_type===110 && props.order.acc_line_items && <div style={{borderBottom: "1px solid #dddddd"}} className={props.theme == "light" ? "border-box clearfix leftalign details blackspan9 orderSecondBox" : "border-box clearfix leftalign details bbspan orderSecondBox"}>
                    <div className='order-middle-row'>    
                    <div className="orderMiddle">
                            <img className="car-logo" src={props.order && props.order.acc_line_items ? props.order.acc_line_items.product_image_url : ""} width="50" />
                            <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                                <div className={props.theme == "light" ? "regNo" : "regNo1"}> {props.order && props.order.acc_line_items ? props.order.acc_line_items.sku_code : ""} </div>
                            </div>
                    </div>
                    <div className={props.theme == "light" ?'view-details-button' :'view-details-button-dark'} onClick={()=>props.handleShowAccOrderDetails(true,props.order.acc_line_items.service_tbl_id)}>View Details</div>
                    </div>
                    </div>}
                    <div  className={props.theme == "light" ? "border-box clearfix details orderSecondBox" : "border-box clearfix details orderSecondBox"}>
                            {/* <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                                <div className={props.theme == "light" ? "regNo" : "regNo1"}> {props.order.car ? props.order.car.registration_no : ""} </div>
                                <div className={props.theme == "light" ? "carName" : "carName1"}>{props.order.car ? props.order.car.car_tbl.brand.name : ""} {props.order.car ? props.order.car.car_tbl.model.name : ""} {props.order.car ? props.order.car.car_tbl.car_type.name : ""}</div>
                            </div> */}
                            {props.order && props.order.order_type===110 &&  props.order.acc_line_items && <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    Item Id:
                                </div>
                                <div className="orderDetailItem2">
                                {props.order.acc_line_items.sku_code} 
                                </div>
                            </div>}
                            {props.order && props.order.order_type===110 && props.order.acc_line_items && <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                Title & SubTitle: 
                                </div>
                                <div className="orderDetailItem2">
                                {props.order.acc_line_items.product_title}, {props.order.acc_line_items.product_sub_title} 
                                </div>
                            </div>}
                            <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    Discount:
                                </div>
                                <div className="orderDetailItem2">
                                {getCurrencySymbol(country)} {props.order && props.order.order_type===110 && props.order.acc_line_items? props.order.acc_line_items.total_discount
                                :!!props.order.discount_amount ? props.order.discount_amount : 0} 
                                </div>
                            </div>
                            <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    Amount:
                                </div>
                                <div className="orderDetailItem2">
                                    {getCurrencySymbol(country)} {props.order && props.order.order_type===110 && props.order.acc_line_items?props.order.acc_line_items.total
                                    :props.order.amount ? props.order.amount : "NA"}
                                </div>
                            </div>
                            <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    Payment Status:
                                </div>
                                <div className="orderDetailItem2">
                                    {props.order.payment_status ? props.order.payment_status : "NA"} 
                                </div>
                            </div>
                            { props.order && props.order.order_type===110 &&  props.order.address && <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    Address: 
                                </div>
                                <div className="orderDetailItem2">
                                {props.order.address} 
                                </div>
                            </div>}
                            { props.order && props.order.order_type===110 &&  props.order.acc_line_items && props.order.acc_line_items && 
                            props.order.acc_line_items.awb_number &&
                            <div className="orderDetailInner">
                                <div className="orderDetailItem1">
                                    AWB Number: 
                                </div>
                                <div className="orderDetailItem2">
                                {props.order.acc_line_items.awb_number} 
                                </div>
                                <div className="tooltip">
                                <div className="copy-btn" onClick = {() => {handleMaskedCopy(props.order.acc_line_items.awb_number, "customer-mobile")}}>
                                    <span className="tooltiptext" id="myTooltip">{copyText}</span>
                                    <img src={props.theme == "light" ? Copy : Copy2} alt="Copy" className="copyImg" />
                                </div>
                                </div>
                            </div>}
                            { props.order && props.order.order_type===110 &&  props.order.acc_line_items && props.order.acc_line_items && 
                            props.order.acc_line_items.partner_tracking_url &&
                            <div className="orderDetailInner">
                                 <a href={props.order.acc_line_items.partner_tracking_url} className='w-full' target='_blank'>    
                                <button className='track-acc-button'>
                                   <img width='14px' height='14px' src ='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/currentLoc.png'/> Track Live Order
                                </button>
                                </a>
                            </div>}
                            { props.order && props.order.order_type===110 &&  props.order.acc_line_items && props.order.acc_line_items && 
                            props.order.acc_line_items.awb_number &&
                            <div className="orderDetailInner">
                                <button className='share-live' onClick={()=>sendCurrentStatus(props.order.acc_line_items.awb_number,props.order.acc_line_items.partner_tracking_url)}>
                                <img width='14px' height='14px' src ='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/sendTemplate.png'/> Send Live Status to Customer
                                </button>
                            </div>}
                            {Constants.checkActionPermission('order_actions') && props.order && props.order.order_type !==110 && <div className="orderDetailInner"onClick={(e) => e.stopPropagation()} style={{marginTop: "10px", justifyContent:"space-between"}}>
                                <a className={props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"} target="_blank" href={`https://central.dashboard.gomechanic.app/#/orders/show/${props.order.order_id}?orderOf=gomechanic`}>
                                    Order Details
                                </a>
                                <a className={props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"} target="_blank" href={`https://lead.gomechanic.app/master-lead/${props.order.phone}?lead_id=${props.order.lead_id}&lead_type=general&lead_type_id=undefined`}>
                                    Lead Details
                                </a>
                                {showDownloadBtn &&
                                    <div onClick={(e) => billDownload(e, props.order.order_id)} className={props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"}>
                                        {orderDetails.downloadBill ? "Downloading..." : "Download Bill"}
                                    </div>
                                }
                            </div>}
                    </div>
                    
                    { 
                        <React.Fragment>
                            {
                            // (props.order && props.order.order_type===110?(props.order.acc_line_items && props.order.acc_line_items.current_status<=10):true) && 
                            <div style={{borderTop: "1px solid #dddddd"}} className={props.theme == "light" ? "border-box clearfix details orderSecondBox" : "border-box clearfix details orderSecondBox"}>
                                    <div className="orderDetailInner" style={{justifyContent: "space-between"}} onClick={(e) => {setOrderDetails((prev)=>({...prev,expand: !prev.expand})); e.stopPropagation();}}>
                                        <div className="actionDetailItem1">
                                            Actions:
                                        </div>
                                        <div className="actionDetailItem2" >
                                            View More
                                            <img className={orderDetails.expand ? "view-more" : "view-more expand"} loading="lazy" src="/images/expand_more.svg" alt="expand"></img>
                                        </div>
                                    </div>
                            </div>}
                            { orderDetails.expand ?
                            <div className={props.theme == "light" ? "border-box clearfix actionsButtonContainer" : "border-box clearfix details actionsButtonContainer"}>
                                {Constants.checkActionPermission('escalations_voucher') && props.order && props.order.order_type!==110 && <button onClick={(e) => {handleActions(e, "voucher","Do you want to send Voucher?")}}>
                                    Free Escalation Vouchers
                                </button>}
                                {Constants.checkActionPermission('add_escalations') && props.order && props.order.order_type!==110 && <button onClick={(e) => {clickEscalationBtn(e, props.order.order_id, 0, 'escalation',props.order.status_id,props.order.order_type)}}>
                                    Add Escalation
                                </button>}
                                {orderDetails.isManager &&
                                <React.Fragment>
                                    {Constants.checkActionPermission('cancel_order') && props.order && props.order.order_type!==110 &&<button onClick={(e) => {handleActions(e, "cancellation","Do you want to cancel the order?")}}>
                                        Cancel Order
                                    </button>}
                                    {Constants.checkActionPermission('add_refund') && props.order && props.order.order_type!==110 && <button onClick={(e) => {handleActions(e, "gomoney","Do you want to add go app money?")}}>
                                        Add Refund / GoApp Money
                                    </button>}
                                </React.Fragment>
                                }
                               {props.order && props.order.order_type===110 &&  
                                <React.Fragment>
                                    {Constants.checkActionPermission('cancel_order') && props.order.acc_line_items && props.order.acc_line_items.current_status<=10 && <button onClick={(e) => {
                                      if(props.order.acc_line_items && props.order.acc_line_items.current_status===10){     
                                        if(props.order.acc_line_items && props.order.acc_line_items.refund_initiated )
                                            NotificationManager.error('Refund Already Initiated, Will be updated in some time', 'Error', 5000); 
                                        else 
                                        handleActions(e, "cancellation","Do you want to return the order?")
                                      }
                                      else if(props.order.acc_line_items && props.order.acc_line_items.current_status===1)  {
                                        handleActions(e, "cancellation","Do you want to cancel the order?") 
                                      }
                                      else 
                                      NotificationManager.error('Order Dispatched cannot cancel', 'Error', 5000); 
                                    }
                                    }>
                                    {props.order.acc_line_items&&props.order.acc_line_items.current_status===10?'Return Order':'Cancel Order'}
                                    </button>}
                                    <button onClick={(e) => {clickEscalationBtn(e, props.order.order_id, 0, 'escalation',props.order.status_id,props.order.order_type,props.order.acc_line_items.sku_code)}}>
                                    Add Escalation
                                </button>
                                </React.Fragment>
                                }
                            </div>
                            :""
                            }  
                        </React.Fragment>
                    }           
                </div>
                </>
            );
        }

export default Order