import React, { useState, useEffect, useRef } from "react";
import Constants from '../../constants';
import './BookingDetails.css';


function BookingDetails(props) {
  const timeSlot = props.data.time_slot ? Constants.getTime12HourFormat(props.data.time_slot): "";
  const pickDate = props.data.pick_date ? Constants.get_DateString1(props.data.pick_date): "";
  const date = `${timeSlot}, ${pickDate}`;


  return (
        <div className="booking-container">
            <div className="bitem-container">
                <div className="booking-item">
                    <div className="b-icon">
                        <img height="18px" src="/images/place.svg" />
                    </div>
                    <div className="b-detail">{props.data.address}</div>
                </div>
                <div className="booking-item">
                    <div className="b-icon">
                        <img height="16px" src="/images/schedule.svg" />
                    </div>
                    <div className="b-detail" style={{paddingTop: "18px"}}>{date}</div>
                </div>
                <div className="booking-item">
                    <div className="b-icon">
                        <img height="15px" src="/images/directions_car.svg" />
                    </div>
                    <div className="b-detail" style={{paddingTop: "18px"}}>{props.data.car_details}</div>
                </div>
            </div>
            
        </div>
);
}

export default BookingDetails;
