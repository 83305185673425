import React, {useEffect, useState, useContext } from 'react';
import API from '../../api';
import { NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './Escalation.css';
import { countryContext } from '../NewRightPannel/NewRightPanel';
import Constants from "../../constants";

const Escalation = (props) => {
    const orderId = props && props.orderId ? props.orderId : "";
    const [open, setOpen] = useState(false);
    const [dotted, setDotted] = useState(false);
    const [previousRemarks, setPreviousRemarks] = useState();
    const [remarks, setRemarks] = useState();
    const [department,setDepartment] = useState('Department');
    const [centralLinks, setCentralLinks] = useState();
    const user_access = sessionStorage.getItem("user_access") ? sessionStorage.getItem("user_access") : "retail"
    const executive_name = localStorage.getItem("userName")
    const [showDropdown,setShowDropdown] = useState(false);
    const {country} = useContext(countryContext)

    const departmentOptions = [{name:'Procurement',value:'procurement'},
    {name:'Warehouse',value:'warehouse'},
    {name:'Refund',value:'refund'},
    {name:'Return',value:'return'}]; 

    const saveEscalation = () => {
        if(user_access==='retail'){
        if(!remarks){
            NotificationManager.error('Please fill remarks', 'Error', 5000);
            return;
        }
        let params = {
            order_id: orderId,
            remark: remarks,
            status_id: 1,
            source_id: 10,
        };
        
        API.postEscalation(params, orderId,country).then(function (response) {
            if (response.data.status){
                NotificationManager.success('Escalation saved', 'Successfully', 5000);
                props.closeClick();
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    }
    else{
        if(previousRemarks && previousRemarks.remark){
            let params = {
                channel: 'chat',
                order_id: previousRemarks.order_id,
                customer_remark: remarks,
                status_id: 1, 
                source_id: 10,
                updated_by_cr: 0,
                department: department,
                remark: remarks,
                user_name: executive_name,
                escalation_id: previousRemarks.escalation_id,   
            };
            if(!remarks){
                NotificationManager.error('Please fill remarks', 'Error', 5000);
                return;
            }
            API.putSpareEscalationDetails(params,previousRemarks.order_id).then(function (response) {
                if (response.data.status){
                    NotificationManager.success('Escalation saved', 'Successfully', 5000);
                    props.closeClick();
                }
            })
            .catch(function (error) {
                console.log(error);
                NotificationManager.error('Something went wrong', 'Error', 5000);
            })
        }
        else{
        if(!remarks){
            NotificationManager.error('Please fill remarks', 'Error', 5000);
            return;
        }
        let params = {
            order_id: orderId,
            customer_remark: remarks,
            status_id: 1,
            source_id: 10,
            updated_by_cr: 0,
            department,
            remark: remarks,
            user_name: executive_name,
        };
        API.postSparesEscalation(params).then(function (response) {
            if (response.data.status){
                NotificationManager.success('Escalation saved', 'Successfully', 5000);
                props.closeClick();
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    }   
    }
    }

    const handleRemarkChange = (e) => {
        console.log(e.target.value);
        let remark =  e.target.value ? e.target.value.trim() : null;
        setRemarks(remark);
    }

    useEffect(() => {
        if(user_access==='retail'){
        API.getEscalationDetails(orderId,country).then(function (response) {
            if (response.data.status){
                let previous_remarks = response.data.data ? response.data.data.remark.join("\n") : "";
                setPreviousRemarks(previous_remarks);
                let central_links = response.data.data ? response.data.data.central_link : null;
                setCentralLinks(central_links);
                console.log(response.data);
            }else{
                NotificationManager.error('Something went wrong', 'Error', 5000);
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })}
        else{
            API.getSparesEscalationDetails(orderId).then(function (response) {
                if (response.data.status){
                    let previous_remarks = response.data.data[0];
                    let history = previous_remarks.history ? previous_remarks.history : [];
                    let spares_remarks = history.length > 0 && history.reduce((str, val) => 
                        `${str} ${val.created_at ? `${Constants.get_DateString2(val.created_at)}: ` : ""} ${val.updated_by_name ? `${val.updated_by_name}: ` : ""} ${val.customer_remark ? `${val.customer_remark}` : ""} \n`
                    , "")
                    previous_remarks = {...previous_remarks, 'remark' : spares_remarks};
                    setPreviousRemarks(previous_remarks);
                    let central_links = previous_remarks ? previous_remarks.central_link : null;
                    setCentralLinks(central_links);
                    previous_remarks.department && setDepartment(previous_remarks.department);
                }else{
                    NotificationManager.error('No Escalation Exist', 'Error', 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                setPreviousRemarks({})
                //NotificationManager.error('Something went wrong', 'Error', 5000);
            })} 
        
    }, [orderId]);
    return (
        <>
          {/* <div className="add-escalation-es" onClick={() => setOpen(true)}>ADD ESCALATION</div> */}
            <>
            <React.Fragment>
            
                <div className="modalComponent" onClick={() => setOpen(false)} />
                <div className="modalBackground">
                    <div style={{ padding: '1.5rem' }}>
                        <h2 className="escalation-es">Add Escalation</h2>
                        <div className="inputBoxes-es">
                            <div className="box1-es disable-input-es">
                                <div>
                                    <span className="inputTitle-es">Order ID</span>
                                    <input type="text" value={orderId} defaultValue={orderId} className="mainInput-es"  />
                                </div>
                            </div>
                            <div className="box1-es disable-input-es">
                                <div>
                                    <span className="inputTitle-es">Escalation Source</span>
                                    <input type="text" defaultValue="Chat Team" className="mainInput-es" disabled={true} />
                                </div>
                            </div>
                        </div>

                        <div className="inputBoxes-es">
                            <div className="box3-es">
                                <div>
                                    <span className="inputTitle-es">Remarks</span>
                                    <textarea onChange={(e) => handleRemarkChange(e)} defaultValue={previousRemarks} className="textarea-es" />
                                </div>
                            </div>
                            <div className="box3-es disable-input-es">
                                    <div>
                                        <span className="inputTitle-es">Previous Remarks</span>
                                        <textarea value={user_access==='retail'?previousRemarks:previousRemarks  && previousRemarks.remark} defaultValue={user_access==='retail'?previousRemarks:previousRemarks  && previousRemarks.remark} className="textarea-es" disabled={true} />
                                    </div>
                            </div>
                        </div>
                        {user_access!=='retail' && <div className={previousRemarks && previousRemarks.remark ? 'disabled-button department-dropdown':"department-dropdown"}>
                            <div className={previousRemarks && previousRemarks.remark ? 'disabled-button':""} onClick={()=>(previousRemarks && !previousRemarks.remark) && setShowDropdown((prev)=>!prev)}>{department}</div>
                            {showDropdown && <div className='department-options'>
                            {departmentOptions.map((each)=>{
                                return <div className='department-option' onClick={()=>{setDepartment(each.value)
                                    setShowDropdown(false)    
                                }}>{each.name}</div>
                            })}  
                            </div>}  
                        </div>}

                        {centralLinks ?
                            <div style={{ margin: '1rem 0px', textAlign: 'left' }}>
                                <a href={centralLinks} target="_blank">Escalation Link</a>
                            </div>
                        : null }

                        <div className="bottom-button-es">
                            <button onClick={() => props.closeClick()} className="whiteButton-es">Cancel</button>
                            <button
                                onClick={() => { 
                                    setDotted(true);
                                    setTimeout(() => {
                                        setOpen(false);
                                        setDotted(false);
                                    }, 1500);
                                    saveEscalation();
                                }} 
                                className="purpleButton-es"
                            >
                                {dotted ? 'Adding...' : 'Add'}
                            </button>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            </> 
        </>
    )
}

export default Escalation