import React, { useState, useEffect,createContext } from 'react';
import Loader from "../../loader";
import API from '../../api';
import PanelRight1 from './PanelRight1'
import PanelRight2 from './PanelRight2'
import RefundPopup from './../refundPopup/RefundPopup';

export const countryContext = createContext(null);

const statuses = JSON.parse(localStorage.getItem("statuses"));
const fleets = JSON.parse(localStorage.getItem("fleets_summary"));

const NewRightPanel = ({ selectedTab, theme, threadsList ,...props}) => {
    const thread = threadsList && threadsList.find(each => each.id === selectedTab);
    const user_access = sessionStorage.getItem("user_access") ? sessionStorage.getItem("user_access") : "retail"
    const [countryDetail,setCountryDetail] = useState()
    const [panelContent, setPanelContent] = useState({
        escalation : false,
        orderId: '',
        showRightContent1: false,
        showRightContent2: false,
        showAccOrderDetails : false,
        rightContent1: NaN,
        rightContent2: NaN,
        showLoader:false,
        rightOrder: NaN,
        showRefundPopUp: false,
        paidAmount:0,
        source: "",
        popupHeader:"",
        totalRefund : "",
        orderType : "",
        refundId : "",
        skewId: "",
        service_id:"",
    })
    
    const handleRightScroll = () => {
        if(!panelContent.showLoader){
        setPanelContent((prev)=>({...prev,showLoader : true}))
        API.getOrderDetailsPaginateRight(selectedTab,panelContent.rightContent2,thread.country).then((response) => {
            if (response.data.status){
                    if(response.data.data.length == 0)
                    setPanelContent({showLoader : false});
                    else
                    setPanelContent((prev)=>({...prev,showRightContent2 : true, rightContent2: prev.rightContent2 && prev.rightContent2.concat(response.data.data), showLoader : false}))
            }else{
                setPanelContent((prev)=>({...prev,showLoader : false, showRightContent2 : false, rightContent2: NaN}))
            }
            setPanelContent((prev) => ({ ...prev, showLoader: false }))
        }
        )
        .catch(function (error) {
                setPanelContent((prev) => ({ ...prev, showLoader: false }))
            })
        }
    }

    const handleShowAccOrderDetails = (value,serviceId) => {
        setPanelContent((prev)=>({...prev,showAccOrderDetails:value,service_id:serviceId}))
    }

    const handleScroll = (e) => {
        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) ||
            Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
        if (bottom && panelContent.rightContent2 && panelContent.rightContent2.length !== 0 && !panelContent.orderDetailLoader)
            handleRightScroll()
    }
    const handleEcalation = (orderId, amount, action, statusId, orderType, skuCode) => {
        if (action == "escalation")
            setPanelContent((prev) => ({ ...prev, escalation: !prev.escalation, orderId: orderId , statusId, orderType, skuCode}))
        else if (action == "refund")
            setPanelContent((prev) => ({ ...prev, showRefundPopUp: !prev.showRefundPopUp, paidAmount: amount, orderId: orderId }))
        else
            setPanelContent((prev) => ({ ...prev, showRefundPopUp: false, escalation: false }))
    }

    const handleTransferChat = () => {
        setPanelContent((prev) => ({ ...prev, rightContent1: NaN, rightContent2: NaN, showRightContent1: false, showRightContent2: false }))
        props.transferChat()
    }

    const handleOrderActions = (obj) => {
        setPanelContent((prev) => ({
            ...prev,
            showRefundPopUp: true,
            source: obj.source,
            popupHeader: obj.text,
            orderId: obj.orderId,
            totalRefund: obj.refundAmount,
            orderType: obj.orderType,
            skewId : obj.skewId,
            refundId: obj.refundId,
            paidAmount: obj.amount ? obj.amount : 0,
        }))
    }

    const handleRightOrderClick = (order) => {
        setPanelContent((prev) => ({ ...prev, rightOrder: order }))
    }

    const handleSendVoucher = (text) => {
        props.sendVouchers(text);
    }


    const handleBackOrderClick=()=> {
        setPanelContent((prev)=>({...prev,rightOrder : NaN}))
    }

    useEffect(()=>{
        threadsList  && 
         setCountryDetail({country: threadsList.find(each => each.id === selectedTab) 
            && threadsList.find(each => each.id === selectedTab).country})
    },[threadsList,selectedTab])

    useEffect(() => {
        if (selectedTab) {
            setPanelContent((prev) => ({ ...prev, showRightContent1: false, 
                showRightContent2: false , 
                rightContent2:NaN, 
                rightContent1:NaN, 
                showLoader : true,
                rightOrder: NaN}))
            API.getChatUserDetailsRight(selectedTab,thread.country).then((res) => {
                if (res.data.status)
                    setPanelContent((prev) => ({ ...prev, rightContent1: res.data.data, showRightContent1: true, showLoader:false }))
                else
                    setPanelContent((prev) => ({ ...prev, rightContent1: "no_user" , showLoader:false}))
            })
                .catch(function (error) {
                    setPanelContent((prev) => ({ ...prev, showLoader: false ,rightContent1: "no_user" }))
                })
            API.getOrderDetailsPaginateRight(selectedTab, [],thread.country).then((response) => {
                if (response.data.status){
                        let rightContent2 = response.data.data;
                        let paid_amount = 0;
                        if(rightContent2.length > 0){
                            let order = rightContent2[0].payments;
                            paid_amount = order && order.length > 0 && order.reduce((acc, val) => acc + (parseInt(val['transaction_amount'] )|| 0),0);
                        }
                        setPanelContent((prev)=>({...prev,showRightContent2 : true, rightContent2: rightContent2, showLoader : false, paidAmount: paid_amount}))
                }else{
                    setPanelContent((prev)=>({...prev,showLoader : false, showRightContent2 : false, rightContent2: NaN,showLoader: false}))
                }
            }
            )
                .catch(function (error) {
                    setPanelContent((prev) => ({ ...prev, showLoader: false,showRightContent2 : true, rightContent2: NaN }))
                })
        }
    }, [selectedTab])

    return (
        <countryContext.Provider value={countryDetail}>
            {(!panelContent.showRightContent1 || !panelContent.showRightContent2) ?
                <>
                    {(panelContent.showLoader) &&
                        <div id="dashboardchatmainright"
                            className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"}
                            >
                            <Loader theme={theme} fullHeight={true} />
                        </div>}
                    {panelContent.rightContent1 === 'no_user' &&
                        (<div id="dashboardchatmainright"
                            className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"}
                            >
                             <table style={{textAlign:'center'}}className="stdtable"><tbody><tr><td valign="center">
                                No existing user.
                            </td></tr></tbody></table>
                        </div>)}
                    
                    {!selectedTab&&<div id="dashboardchatmainright"
                        className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"}
                        >
                        <table className="stdtable"><tbody><tr><td valign="center">
                            Select a thread on the left to view details.
                        </td></tr></tbody></table>
                    </div>}
                    <div id="dashboardchatmainright"
                            className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"}>
                            <table className="stdtable"><tbody><tr><td valign="center">
                            </td></tr></tbody></table>
                        </div>
                </> :

                panelContent.rightOrder ?
                    <div id="dashboardchatmainright" 
                         className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"} 
                         >
                        <PanelRight1 
                          selectedTab={selectedTab} 
                          theme={theme} 
                          thread={thread} 
                          showContent1={true} 
                          showRightOrder={true} 
                          content={panelContent.rightOrder} 
                          onBackOrderClick={handleBackOrderClick} 
                          statuses={statuses} 
                          fleets={fleets} />
                    </div>
                    : <>
                        {panelContent.showRefundPopUp &&
                            <RefundPopup
                                userName={panelContent.rightContent1 && panelContent.rightContent1.name ? panelContent.rightContent1.name : "Customer"}
                                userId={panelContent.rightContent1 && panelContent.rightContent1.id ? panelContent.rightContent1.id : ""}
                                goMoney={panelContent.rightContent1 && panelContent.rightContent1.go_app_money ? panelContent.rightContent1.go_app_money : "0"}
                                orderId={panelContent.orderId ? panelContent.orderId : "No Order"}
                                refundId={panelContent.refundId}
                                totalRefund={panelContent.totalRefund}
                                paidAmount={panelContent.paidAmount}
                                theme={panelContent.theme}
                                source={panelContent.source}
                                header={panelContent.popupHeader}
                                orderType={panelContent.orderType}
                                closeClick={() => { setPanelContent((prev) => ({ ...prev, showRefundPopUp: false })) }}
                                selectedTab={selectedTab}
                                onSendVoucher={handleSendVoucher}
                                sentVoucher={props.sentVoucher}
                                skewId = {panelContent.skewId}
                            />
                        }
                        <div id="dashboardchatmainright"
                            className={theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"}
                            onScroll={handleScroll}>

                            <PanelRight1
                                selectedTab={selectedTab}
                                theme={theme}
                                thread={thread}
                                showContent1={panelContent.showRightContent1}
                                content1={panelContent.rightContent1}
                                lastOrder={panelContent.rightContent2 ? panelContent.rightContent2[0] : {}}
                                showRightOrder={false}
                                statuses={statuses}
                                fleets={fleets}
                                orderId={panelContent.orderId}
                                skuCode ={panelContent.skuCode}
                                selectedOrderStatus = {panelContent.statusId} 
                                selectedOrderType = {panelContent.orderType}
                                handleEscalationOnclick={handleEcalation}
                                isEscalation={panelContent.escalation}
                                showAccOrderDetails = {panelContent.showAccOrderDetails}
                                onTransferChat={handleTransferChat}
                                handleShowAccOrderDetails={handleShowAccOrderDetails}
                                leadCreated={props.leadCreated}
                                onGlobalSend={props.onGlobalSend}
                                service_id={panelContent.service_id}
                                totalOrder={panelContent.rightContent2 ? panelContent.rightContent2.length : 0}
                            />
                            <PanelRight2
                                isOnline={props.isOnline}
                                theme={theme}
                                showContent2={panelContent.showRightContent2}
                                content1={panelContent.rightContent1}
                                content2={panelContent.rightContent2}
                                goAppEarning={panelContent.rightContent1.go_app_earning ? panelContent.rightContent1.go_app_earning : "0"}
                                showLoader={panelContent.showLoader}
                                onRightOrderClick={handleRightOrderClick}
                                statuses={statuses} fleets={fleets}
                                handleEscalationOnclick={handleEcalation}
                                handleAddAmount={(obj) => handleOrderActions(obj)}
                                isEscalation={panelContent.escalation}
                                handleShowAccOrderDetails={handleShowAccOrderDetails}
                                onGlobalSend={props.onGlobalSend}
                                selectedTab={selectedTab}
                                service_id={panelContent.service_id}
                            />
                        </div>
                    </>
            }
        </countryContext.Provider>
    )
}

export default NewRightPanel