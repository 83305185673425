import React,{ useState,useContext ,useEffect, useRef} from 'react';
import Timeline from './../timeline/Timeline';
import SparesTimeline from './../timeline/SparesTimeline'
import NewOrder from './../newOrder/NewOrder';
import Constants,{getCurrencySymbol} from "../../constants";
import Escalation from './../escalation/Escalation';
import NewEscalation from './../escalation/NewEscalation';
import AccOrderDetails from './../AccOrderDetails/AccOrderDetails'
import ChatTransfer from './../chatTransfer/ChatTransfer';
import Copy from './../copy.svg';
import Copy2 from './../copy1.png';
import SubscriptionBox from './../subscriptionBox/SubscriptionBox';
import { Link } from 'react-router-dom';
import { countryContext } from './NewRightPanel';

const PanelRight1=(props)=>{

    const [showMoreInfo,setShowMoreInfo] = useState(false)
    const popupRef = useRef(null)
    const popupOpenerRef = useRef(null)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [popupRef])

    function handleClickOutside(event) {
        if (popupRef.current && !popupRef.current.contains(event.target) && popupOpenerRef.current && !popupOpenerRef.current.contains(event.target)) {
            setShowMoreInfo((prev) => !prev)
        }
    }
        const [pannel1Content,setPannel1Content] = useState({
            escalation : false,
            orderId: '',
            copyText : 'Copy to clipboard',
        })
        
        const countryDetail = useContext(countryContext)
        const  status = props.content ? 
            Constants.getOrderStatus(props.content.order_type, props.content.status_id):
            props.lastOrder && Constants.getOrderStatus(props.lastOrder.order_type, props.lastOrder.status_id)
        const cr = (props.lastOrder && props.lastOrder.cr) ? props.lastOrder.cr.name : ""
        const goAppMoney = props.content1 && props.content1.go_app_money    
        const car  = (props.lastOrder && props.lastOrder.car) ? `${props.lastOrder.car.car_tbl.brand.name} ${props.lastOrder.car.car_tbl.model.name} ${props.lastOrder.car.car_tbl.car_type.name}`: "";
        const orderType = (props.lastOrder && props.lastOrder.order_type) ? props.lastOrder.order_type : "0";
        const status_id = (props.lastOrder && props.lastOrder.status_id) ? props.lastOrder.status_id : "0";
        const latestOrderId = (props.lastOrder && props.lastOrder.order_id) ? props.lastOrder.order_id : "";
        const orderDetails = props.thread && props.thread.orderDetails
        const filteredfleets = props.fleets && props.fleets.length > 0 && props.content? props.fleets.filter(each => (each.id == props.content.fleet_id)) : "NA";
        const agentComment = props.content1 && props.content1.chat_remarks && props.content1.chat_remarks.chat_agent_comment ? props.content1.chat_remarks.chat_agent_comment : "";
        const crName = props.lastOrder && props.lastOrder.cr ? props.lastOrder.cr.name : "";
        const crMobile = props.lastOrder && props.lastOrder.cr ? props.lastOrder.cr.mobile : "";
        const garageName = props.lastOrder && props.lastOrder.garage ? props.lastOrder.garage.display_address : "";
        const user_access = sessionStorage.getItem("user_access") ? sessionStorage.getItem("user_access") : "retail"
        
        let fleet = "NA";
        if(filteredfleets.length > 0){
            fleet = filteredfleets[0].name;
        }
        let update_items = "";
        if(props.content  && props.content.update_items && props.content.update_items.length > 0){
        for (var i = 0; i < props.content.update_items.length; i++){
            var item = props.content.update_items[i]
            if (i === 0){
                update_items+=(item.package_name ? item.package_name : item.service_name);
            }else{
                update_items+=", "+ (item.package_name ? item.package_name : item.service_name);
            }
        }
        }

        if(props.content && props.content.initial_problem){
            var items = props.content.initial_problem.split("|");
            items.forEach(function(item){
                if(item.startsWith(" Title:")){
                    update_items += item.substring(7, item.length);
                }
            });
        }

        const handleBackClick = () =>{
            props.onBackOrderClick()
        }

        const statusText = (statusId, orderType) => {
                if(orderType==110){
                    return {value:'before_pickup', name:'Before Pickup'}
                }
                const beforePickupStatus = orderType===103?[850,1010]:[5,21]
                const atWorkshopStatus = orderType===103?[900,1000,1040,1060,1050,1100,1075,1085,1090,1091,1150,1170,1180,1250,1200]:[10,20,30,31,40,50]
                const workshopAfterStatusIns = [1350,1400]
                if(beforePickupStatus.includes(statusId)){
                    return {value:'before_pickup', name:'Before Pickup'}
                }
                else if(atWorkshopStatus.includes(statusId))
                {
                    return {value:'at_workshop', name:'At Workshop'}
                }
                else if(orderType===103?workshopAfterStatusIns.includes(statusId):statusId>50){
                    return {value:'after_delivery', name:'After Delivery'}
                }
        }
        
        const handleSubscriptionData=(details,header)=>{
            return {header,
                data:details.map((detail)=>({...detail, 
                expires_at:detail.expires_at ? Constants.get_DateString2(new Date(detail.expires_at),true) : "",
                started_at:detail.started_at ? Constants.get_DateString2(new Date(detail.started_at),true): "",
                amount:detail.cars && detail.cars.deal_car_price_mapping && detail.cars.deal_car_price_mapping.price,
                carDetails:{
                    car_image:detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model && detail.cars.car_tbl.model.image_path,
                    car_number:detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model&& detail.cars.car_tbl.modelregistration_no,
                    car_name:(detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.brand)+' '+
                       (detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model && detail.cars.car_tbl.model.name)
                },
                coverage:{
                    year:detail.coverage && detail.coverage.year,
                    distance:detail.coverage && detail.coverage.year,
                },
                isActive:(new Date()).getTime()<=(new Date(detail.expires_at)).getTime()
            }))}
        }

        const handleCloseEscalation=() =>{
            setPannel1Content((prev)=>({...prev,escalation:!prev.escalation}))
        }

        const handleCopy=(text, id)=>{
            if(text){
                var r = document.createRange();
                r.selectNode(document.getElementById(id));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                setPannel1Content((prev)=>({...prev,copyText:`Copied: ${text}`}))
            }
        }

        const handleMaskedCopy=(text, id)=>{
            if(text){
                navigator.clipboard.writeText(text);
                setPannel1Content((prev)=>({...prev,copyText:`Copied: ${text}`}))
            }
        }

        return  (
            !props.showContent1?
                    <React.Fragment />
           :props.showRightOrder?
                    <React.Fragment>
                        <div className="border-box clearfix header"><div className={props.theme == "light" ? "blackspan9" : "aaspan"}><table className="stdtable"><tbody><tr><td valign="center">
                                        <span style={{textTransform:'none'}}>Placed on</span> {props.content.created_at ? (props.content.created_at.$date ? Constants.get_DateStringX(props.content.created_at.$date) : Constants.get_DateStringX(props.content.created_at)) : ""}
                                    </td></tr></tbody></table></div><div className={props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}><table className="stdtable"><tbody><tr><td valign="center"></td></tr></tbody></table></div></div>
    
                                    <div className="border-box clearfix buttons"><div className={props.theme == "light" ? "border-box blackspan9 weight500" : "border-box aaspan weight500"}><table className="stdtable"><tbody><tr><td valign="center">
                                        <button className={props.theme == "light" ? "btn btn_small btncolorprim btncard move btnbackorder" : "btn btn_small btncolorprimdark btncard move btnbackorder"} onClick={handleBackClick}>Back</button>
                                    </td></tr></tbody></table></div>
                                    
                                    <div className={props.theme == "light" ? "border-box blackspan9 weight500 rightalign" : "border-box aaspan weight500 rightalign"}>
                                    
                                    </div></div>
                                    <div className={props.theme == "light" ? "border-box clearfix prime blackspan4 leftalign weight700" : "border-box clearfix prime whitespan leftalign weight700"}>{props.content.car && props.content.car.car_tbl.brand.name} {props.content.car && props.content.car.car_tbl.model.name} {props.content.car && props.content.car.car_tbl.car_type.name}</div>
                                    {Constants.checkViewPermission('orderDetail','upper_order_id')&&<div className={props.theme == "light" ? "border-box clearfix name blackspan65 leftalign weight500" : "border-box clearfix name ccspan leftalign weight500"}><span style={{textTransform:'none'}}>Order Id: </span><span className="weight700">{props.content.order_id}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','spares_order_type')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Order Type: </span><span className="weight700">{props.content.order_type}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','registration_no')&&<div className={props.theme == "light" ? "border-box clearfix name blackspan65 leftalign weight500" : "border-box clearfix name ccspan leftalign weight500"}><span style={{textTransform:'none'}}>Reg. No.: </span><span className="weight700">{props.content.car && props.content.car.registration_no}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','location')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Location: </span><span className="weight700">{props.content.location}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','status')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Status: </span><span className="weight700">{status}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','amount')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Amount: </span><span className="weight700">{getCurrencySymbol(countryDetail.country)} {props.content.amount}</span></div>}
                                    <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Discount: </span><span className="weight700">{getCurrencySymbol(countryDetail.country)} {props.content.discount_amount}</span></div>
                                    {Constants.checkViewPermission('orderDetail','cr_name')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>CR Assigned: </span><span className="weight700">{props.content.cr && props.content.cr.name? props.content.cr.name : "Not assigned"}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','payment_status')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Payment status: </span><span className="weight700">{props.content.payment_status}</span></div>}
                                    {Constants.checkViewPermission('orderDetail','upper_address')&&<div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Address: </span><span className="weight700">{props.content.address}</span></div>}
                                    {Constants.checkViewPermission('retailTimeline')&&<Timeline 
                                        theme={props.theme}
                                        content={props.content}
                                    />}
                                    {Constants.checkViewPermission('sparesTimeline')&&<SparesTimeline
                                        theme={props.theme}
                                        content={props.content}
                                    />}
                                    {Constants.checkViewPermission('orderDetail','orderDetailsHeader') &&<div className={props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}>
                                    <div className={props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>Order Details</div>
                                        <div className="border-box clearfix">
                                            <div>
                                                {Constants.checkViewPermission('orderDetail','order_id') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order ID</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {props.content.order_id}
                                                    </div>
                                                </div>}
                                                {Constants.checkViewPermission('orderDetail','retail_order_type') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order Type</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {Constants.orderTypes()[props.content.order_type]}
                                                    </div>
                                                </div>}
                                                {Constants.checkViewPermission('orderDetail','go_app_earning') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Go App Earning</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {props.content.goAppEarning}
                                                    </div>
                                                </div>}
                                                {Constants.checkViewPermission('orderDetail','pick_date') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Pickup</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {props.content.pick_date ? (props.content.pick_date.$date ? Constants.get_DateString2(props.content.pick_date.$date) : Constants.get_DateString2(props.content.pick_date)) : ""}
                                                    </div>
                                                </div>}
                                                {Constants.checkViewPermission('orderDetail','lower_address') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Address</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {props.content.address}
                                                    </div>
                                                </div>}
                                                {Constants.checkViewPermission('orderDetail','update_items') && <div className="border-box clearfix orderIdMain">
                                                    <div className={props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order items</div>
                                                    <div className={props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                        {update_items}
                                    {/* </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tbody><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                    {props.content1.login_type}
                                    </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                    {props.content1.login_type}
                                    </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                    {props.content1.login_type}
                                    </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                    {props.content1.login_type} */}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>}
                        </React.Fragment>
             :
                    <React.Fragment>
                        <div className="border-box clearfix header">
                            <div className={props.theme == "light" ? "blackspan9" : "aaspan"}>
                                <table className="stdtable">
                                    <tbody><tr>
                                        <td valign="center">
                                            <span style={{textTransform:'none'}}>Joined on</span> {props.content1.created_at ? (props.content1.created_at.$date ? Constants.get_DateStringX(props.content1.created_at.$date) : Constants.get_DateStringX(props.content1.created_at)) : ""}
                                        </td>
                                    </tr></tbody>
                                </table>
                            </div>
                            <div className={props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}>
                                <table className="stdtable">
                                    <tbody><tr>
                                        <td valign="center"></td>
                                    </tr></tbody>
                                </table>
                            </div>
                        </div>
                        {props.content1 && props.content1.amc_details && props.content1.amc_details.length > 0 && 
                        <div className="border-box header" style={{marginBottom: "10px", marginTop:"0px"}}>
                            <div className={props.theme == "light" ? "amc-strip" : "amc-strip-dark"}>
                                <span className="amc-label">Miles Member</span>
                            </div>
                        </div>
                        }
                        <div className="lastOrderDetailContainer chatTransfer">
                                <div className='transferDropdown'>
                                <ChatTransfer  onTransferChat={props.onTransferChat} selectedTab={props.selectedTab} />
                                </div>
                                <div className="border-box fieldbox double archiveButton">
                                    <Link style={{width:"80%", height:"39px"}} className={props.theme == "light" ? "send-voucher-btn archive-button" : "archive-button send-voucher-btn send-voucher-btn-dark"} 
                                    to={{pathname:`/chat-archives/${props.selectedTab}?executive=true&user_access=${sessionStorage.getItem("user_access") || 'retail'}&country=${countryDetail.country}`
                                    }} 
                                    target="_blank">Archive</Link>
                                </div>
                        </div>
                        {/* <div className="border-box" style={{display: "flex", margin: "1rem 0px", textAlign: "left"}}>
                            <div className={props.theme == "light" ? "blackspan9" : "aaspan"}>
                                <a className={props.theme == "light"? "blackspan4" : "ccspan" } href={`/chat-archives/${props.selectedTab}?executive=true`} target="_blank">Archive Chat Link</a>
                            </div>
                            {
                                (   localStorage.getItem("role") == "manager" || 
                                    localStorage.getItem("user_id") == "204358" ||
                                    localStorage.getItem("user_id") == "204359" || 
                                    localStorage.getItem("user_id") == "174721"
                                )
                                &&
                            <div className={props.theme == "light" ? "blackspan9" : "aaspan"}>
                                <div className={props.theme == "light"? "blackspan4 cancel-btn" : "ccspan cancel-btn" } onClick={()=>props.handleAddAmount("cancellation","Do you want to cancel the order?")}>Cancel Order</div>
                            </div>
                            }
                        </div> */}
                        {/* <div className="border-box" style={{margin: "1rem 0px", textAlign: "left"}}>
                        <button style={{marginTop:"5px",width:"50px"}}  className="add-escalation-es">Refund Amount</button> 
                        </div> */}
                        {
                            props.isEscalation && 
                                <div className="border-box clearfix header">
                                    <div className={props.theme == "light" ? "blackspan9" : "aaspan"}>
                                    {user_access==='retail'?<NewEscalation 
                                            orderId={props.orderId}
                                            skuCode = {props.skuCode}
                                            orderType = {props.selectedOrderType}
                                            selectedOrderStatus ={statusText(props.selectedOrderStatus,props.selectedOrderType)}
                                            closeClick={() => {props.handleEscalationOnclick()}}    
                                        />:
                                        <Escalation 
                                            orderId={props.orderId}
                                            closeClick={() => {props.handleEscalationOnclick()}}    
                                        />}
                                    </div>
                                </div> 
    
                        }
                        {props.showAccOrderDetails &&
                            <div className={props.theme == "light" ? "blackspan9" : "aaspan"}>
                                <AccOrderDetails service_id={props.service_id} theme={props.theme} closeClick={props.handleShowAccOrderDetails}/>
                            </div>}                          
                                    <div className="lastOrderDetailContainer" style={{marginTop : "15px"}}>
                                        <div className="flexBox">
                                            <div className={props.theme == "light" ? "border-box clearfix customer-name" : "border-box clearfix prime customer-name"}>{props.content1.name}</div>
                                            {props.totalOrder == 1 &&
                                                <span className="orderNoLabel">1st Order</span>
                                            }
                                            {
                                                props.totalOrder > 1 &&
                                                <span className="orderNoLabel orderRepeatedLabel">Repeated Orders</span>
                                            }
                                            {
                                                props.totalOrder == 0 &&
                                                <span className="orderNoLabel">No order</span>
                                            }
                                        </div>
                                        <div className={props.theme == "light" ? "border-box userDetailItem2 orderStatus1" : "border-box userDetailItem2 orderStatus1"}>
                                            <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/phone.png" alt="phoneIcon" className="contactIcon"/>
                                            {props.content1.mobile && <span id="customer-mobile" style={{textTransform:'none'}}>{Array(7).fill('X')}{props.content1.mobile.slice(7)}</span>}
                                            <div className="tooltip">
                                                <div className="copy-btn" onClick = {() => {handleMaskedCopy(props.content1.mobile, "customer-mobile")}}>
                                                    <span className="tooltiptext" id="myTooltip">{pannel1Content.copyText}</span>
                                                    <img src={props.theme == "light" ? Copy : Copy2} alt="Copy" className="copyImg" />
                                                </div>
                                            </div>
                                        </div>
                                        {props.content1.email &&
                                            <div className={props.theme == "light" ? "border-box userDetailItem2 orderStatus1" : "border-box userDetailItem2 orderStatus1"}>
                                                <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/mail.png" alt="phoneIcon" className="contactIcon"/>
                                                <span id="customer-email" style={{textTransform:'none'}}>
                                                    {props.content1.email}
                                                </span>
                                                <div className="tooltip">
                                                    <div className="copy-btn" onClick = {() => {handleCopy(props.content1.email, "customer-email")}}>
                                                        <span className="tooltiptext" id="myTooltip">{pannel1Content.copyText}</span>
                                                        <img src={props.theme == "light" ? Copy : Copy2} alt="Copy" className="copyImg" />
                                                    </div>
                                                </div>  
                                            </div>
                                        }
                                        {
                                            !!goAppMoney && <div className={props.theme == "light" ? "border-box userDetailItem2 goAppMoney" : "border-box userDetailItem2 orderStatus1"}>
                                                <div className='moneyIcon'>{getCurrencySymbol(countryDetail.country)}</div>
                                                <span id="ruppe" style={{ textTransform: 'none' }}>
                                                    {`GoApp Money : ${goAppMoney}`}
                                                </span>
                                            </div>
                                        }
                                                {status && orderDetails && orderDetails.order_type!==110&&  <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        Order Status :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {status}
                                                    </div>
                                                </div>}

                                                {orderDetails && orderDetails.order_type===110 &&  orderDetails.order_status && <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        Order Status :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {orderDetails&& orderDetails.order_status}
                                                    </div>
                                                </div>}

                                                {/* More Information featue need to uncomment in upcoming release */}

                                                         {crName  && 
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        CR Assigned :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {crName}
                                                    </div>
                                                </div>
                                                }
                                                {crMobile && 
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        CR mobile number :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {crMobile}
                                                    </div>
                                                </div>
                                                }
                                                {garageName  && orderDetails && orderDetails.order_type!==110 &&  
                                                    <div className="userDetailInner">       
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        Garage Name :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {garageName}
                                                    </div>
                                                </div>
                                                }
                                                {car && orderDetails && orderDetails.order_type!==110 && 
                                                    <div className="userDetailInner">
                                                    
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        Last Car :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {car}
                                                    </div>

                                                </div>
                                                }
                                                {(Constants.checkViewPermission('orderDetail','new_order_id')||(orderDetails&& orderDetails.order_type===110)) && orderDetails && orderDetails.order_id && 
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                        Order ID :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {orderDetails.order_id}
                                                    </div>
                                                </div>
                                                }
                                                {Constants.checkViewPermission('orderDetail','item_id') && orderDetails &&orderDetails.item_id &&
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                         Item ID :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {orderDetails.item_id}
                                                    </div>
                                                </div>
                                                }
                                                {(Constants.checkViewPermission('orderDetail','sku_code')||(orderDetails&& orderDetails.order_type===110)) && orderDetails && orderDetails.sku_code &&
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                         {orderDetails.order_type===110?'Item ID:':'SKU Code'}
                                                     </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {orderDetails.sku_code}
                                                    </div>
                                                </div>
                                                }
                                                {Constants.checkViewPermission('orderDetail','sku_brand') && orderDetails && orderDetails.sku_brand &&
                                                    <div className="userDetailInner">
                                                    <div className="border-box userDetailItem1 orderStatus1">
                                                         SKU Brand :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                    {orderDetails.sku_brand}
                                                    </div>
                                                </div>
                                                }
                                                {/* {cr &&
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1 orderStatus1">
                                                        CR Assigned :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                        {cr}  
                                                    </div>
                                                </div>
                                                }
                                                {car &&
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1 orderStatus1">
                                                        Last Car :
                                                    </div>
                                                    <div className="userDetailItem2 orderStatus1">
                                                        {car}
                                                    </div>
                                                </div>
                                                } */}
                                    </div>  
                                {props.content1 && props.content1.escalation_data && props.content1.escalation_data.length>0 && props.content1.escalation_data[0].escalations && <div className='live-p-relative'>                   
                                    {showMoreInfo && <div ref={popupRef}className='live-esc-pop-up'>
                                            <div className='live-esc-pop-up-header'>More Info</div>
                                            <div className='live-esc-flex-row'>
                                                <div className='live-esc-pop-up-label'>Escalation Manager :</div>   
                                                <div className='live-esc-pop-up-value'>{props.content1.escalation_data[0].escalations.users &&  props.content1.escalation_data[0].escalations.users.name}</div>     
                                            </div>
                                            <div className='live-esc-flex-row'>
                                                <div className='live-esc-pop-up-label'>Remarks :</div>
                                                <div className='live-esc-pop-up-value'>{props.content1 && props.content1.escalation_data[0].escalations.remark} </div>        
                                            </div>
                                    </div>}    

                                      {/* Escalation Box Ui */}
                                      
                                <div className='main-escalation-box' ref={popupOpenerRef}>
                                      <div className='live-escalation-header live-esc-flex-row'>
                                          <div className='live-escalation-header-text'>Live Escalation</div>
                                          <img onClick={()=>setShowMoreInfo((prev)=>!prev)} style={{paddingBottom:'8px'}} height='18px' width='18px' src='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/OrderTracking/information.png'/>
                                      </div>
                                     <div className='live-escalation-content'> 
                                      <div className='live-esc-flex-row'>
                                          <div className='live-esc-subheader'>Escalation Status</div>
                                          <div className='live-esc-subheader live-text-right'>Escalation ID</div>
                                      </div>
                                      <div className='live-esc-flex-row'>
                                          <div className='live-esc-value'>{props.content1.escalation_data[0].escalations.escalation_status && props.content1.escalation_data[0].escalations.escalation_status.name}</div>
                                          <div className='live-esc-value live-text-right'>{ props.content1 && props.content1.escalation_data[0].escalations.escalation_id}</div>
                                      </div>
                                      <div className='live-esc-flex-row live-second-part'>
                                          <div className='live-esc-flex-col'>
                                              <div className='live-esc-subheader'>Escalation Manager No.</div>
                                              
                                          </div>
                                          <div className='live-esc-value'>{props.content1.escalation_data[0].escalations.users && props.content1.escalation_data[0].escalations.users.mobile}</div>
                                          {/* <button className='live-esc-button'>Send Info</button> */}
                                      </div>
                                      </div>
                                </div>


                                </div>}
                        {Constants.checkViewPermission('subscriptionBox') &&
                            <>
                                {props.content1 && props.content1.amc_details && props.content1.amc_details.length > 0 && <div className='lastOrderDetailContainer' style={{ marginTop: "15px" }}>
                                    <SubscriptionBox subscriptionDetail={handleSubscriptionData(props.content1.amc_details, 'Miles')} />
                                </div>}

                                {props.content1 && props.content1.obd_details && props.content1.obd_details.length > 0 && <div className='lastOrderDetailContainer' style={{ marginTop: "15px" }}>
                                    <SubscriptionBox subscriptionDetail={handleSubscriptionData(props.content1.obd_details, 'OBD')} />
                                </div>}

                                {/* {this.props.content1 && this.props.content1.warranty_details && <div className='subs-card lastOrderDetailContainer'style={{marginTop : "15px"}}>
                                 <SubscriptionBox subscriptionDetail={this.handleSubscriptionData(this.props.content1.warranty_details,'Warranty')} /> 
                                 </div>} */}
                            </>
                        }
                        {Constants.checkViewPermission('orderDetail','agentComment') && agentComment &&
                            <div className="border-box fieldbox double">
                                <div className="agent-note">
                                    <div style={{color: "black", "marginBottom": "10px"}}>
                                        Agent Comment:
                                    </div>
                                     {agentComment}
                                </div>
                            </div>
                        }
                               {Constants.checkActionPermission('new_order')&&<NewOrder 
                                        theme={props.theme}
                                        orderContent={props.content1}
                                        mobile={props.selectedTab}
                                        leadCreated={props.leadCreated}
                                        onGlobalSend={props.onGlobalSend}
                                        name={props.content1.name}
                                        leadDetails={props.thread && props.thread.leadDetails ?  props.thread.leadDetails : {}}
                                    /> }
                                    {/* <div className={props.theme == "light" ? "lastOrderDetailContainer bgwhite" : "lastOrderDetailContainer bgdark-theme1" } style={{margin: "20px"}}>
                                        <Voucher 
                                            onSendVoucher={props.onSendVoucher} 
                                            selectedTab={props.selectedTab} 
                                            theme={props.theme}
                                            mobile={props.selectedTab}
                                            sentVoucher={props.sentVoucher}
                                        />
                                    </div> */}
                                    {/* <div style={{ borderTop: 'none' }} className={props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}>
                                            <div className={props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>
                                            User Details
                                            </div>
                                            <div className="border-box clearfix">
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1">
                                                        GoApp Money :
                                                    </div>
                                                    <div className="userDetailItem2">
                                                        {props.content1.go_app_money}  
                                                    </div>
                                                </div>
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1">
                                                        GoApp Earning :
                                                    </div>
                                                    <div className="userDetailItem2">
                                                        {props.content1.go_app_earning}  
                                                    </div>
                                                </div>
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1">
                                                        Login Type :
                                                    </div>
                                                    <div className="userDetailItem2">
                                                        {props.content1.login_type} 
                                                    </div>
                                                </div>
                                                <div className="userDetailInner">
                                                    <div className="userDetailItem1">
                                                        OS Type :
                                                    </div>
                                                    <div className="userDetailItem2">
                                                        {props.thread ? props.thread.source : ""}
                                                    </div>
                                                </div>
                                            </div>
                                    </div> */}
                        </React.Fragment>
                );
            }

export default PanelRight1