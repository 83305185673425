import React from 'react';
import "../dashboard/dashboard.css"
import  "../dashboard/dashboard2chat.css"
import $ from 'jquery';

import PanelLeftArchives from './leftpanel_archives';
import PanelRightArchives from './rightpanel_archives';
import PanelMiddleArchives from './middlepanel_archives';
import PanelTopBarArchives from './panel_topbar_archives';
import Loader from '../loader';
import Topbar from '../topbar';

import API from '../api';
import Constants from '../constants';
import {Redirect} from 'react-router-dom';

import firebase from '../firebase';
import LogoutMain from '../LogoutMain';
import '../notifications';

const axios = require('axios');






class SupportChatArchives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rightContent1: NaN,
      rightContent2: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent1: false,
      showRightContent2: false,
      rightOrder: NaN,
      showLoader : false,
      showLeftLoader : false,
      searchText : NaN,
      searchArchiveText : NaN,
		selectedTab: 0,
		selectedRow: "",
		filter_showing : false,
		filter_args : undefined,
    threads_list : [],
    threads_list_constant : [],
		divK : [],
		divM : [],
		divH : [],
    all_models : {},
    keyArchives : NaN,
    currentView : "live",
    keyX : NaN,
    threadsStatus : "all", 
    timeline : "all",
    country:{id:'1',name:'India'},
    searchExecutive :'',
    showSearchLoader:false
  };

  this.handleTabClick = this.handleTabClick.bind(this);
	this.handleRowClick = this.handleRowClick.bind(this);
	this.handleFilterClick = this.handleFilterClick.bind(this);
	this.handleSearchClick = this.handleSearchClick.bind(this);
  this.handleThreadSearch = this.handleThreadSearch.bind(this);
  this.handleThreadSearch1 = this.handleThreadSearch1.bind(this);
  this.handleArchiveSearch = this.handleArchiveSearch.bind(this);

  this.handleTopbarSwitchClick = this.handleTopbarSwitchClick.bind(this);

	this.handleRightScroll = this.handleRightScroll.bind(this);
  this.handleLeftScroll = this.handleLeftScroll.bind(this);
  this.handleRightOrderClick = this.handleRightOrderClick.bind(this);
  this.handleBackOrderClick = this.handleBackOrderClick.bind(this);
  this.handleUpdateCountry = this.handleUpdateCountry.bind(this)
  this.handleAgentSearch = this.handleAgentSearch.bind(this)
  this.handleExecutiveArchiveSearch = this.handleExecutiveArchiveSearch.bind(this)
  }


  handleArchiveSearch(val,country){
    API.getSupportChatArchivesByKeySearch(val, this, country);
    // if(this.state.live){
    //   ChatUtils.searchThreads(val, this);
    // }else{
    //   ChatUtilsArchive.searchThreads(val, this);
    // }
  }

  handleExecutiveArchiveSearch(val,country){
    API.getSupportChatArchivesByExecutive(val, this, country);
  }

  handleUpdateCountry(val){
    this.setState({country: val})
  }

  handleTopbarSwitchClick(type, mSwitch){
    console.log("mSwitch", mSwitch);
    if(type == "threads"){
      if(this.state.threadsStatus != mSwitch){
        console.log("mSwitch1", mSwitch);
        this.setState({threadsStatus : mSwitch});
        this.handleThreadSearch1(mSwitch, this.state.timeline, this.state.searchText);
      }
    }else if(type == "timeline"){
      if(this.state.timeline != mSwitch){
        this.setState({timeline : mSwitch});
        this.handleThreadSearch1(this.state.threadsStatus, mSwitch, this.state.searchText);
      }
    }else{
      
    }
  }

  handleThreadSearch(val){
    this.handleThreadSearch1(this.state.threadsStatus, this.state.timeline, val);
  }

  handleAgentSearch(val){
    API.getSupportChatArchivesByKeySearch(val, this, this.state.country.name);
  }

  

  handleThreadSearch1(threadsStatus, timeline, val){
    console.log("search-1-1", threadsStatus)
    console.log("search00");
    if(threadsStatus == "all" && timeline == "all" && !(val && val != null && val.trim() != "")){
      console.log("search11");
      var threads_list = this.state.threads_list_constant;
      this.setState({threads_list : threads_list, searchText : NaN});
    }else{
      console.log("search22");
      var threads_list = this.state.threads_list_constant;
      var codes = [];
      var threads_list_filtered = threads_list;

      if(threadsStatus == "mine"){
        console.log("search33");
        let obj1 = threads_list.filter(o => (o.assigned_executive && o.assigned_executive_name == localStorage.getItem("user_id")));
        // obj1.forEach(function(obj) { 
        //   codes.push(obj.id);
        // });
        threads_list_filtered = obj1;
      }

      if(timeline != "all"){
        let obj1 = threads_list_filtered.filter(o => (o.last_messagetime && Constants.get_DateComparison(o.last_messagetime, timeline)));
        // obj1.forEach(function(obj) { 
        //   codes.push(obj.id);
        // });
        threads_list_filtered = obj1;
      }

      if (val && val != null && val.trim() != "") {
        console.log("search44");
        //console.log("thread_listing", threads_list.length);
        
  
        let obj1 = threads_list_filtered.filter(o => (o.name && o.name.toLowerCase().indexOf(val.toLowerCase()) != -1));
        let obj2 = threads_list_filtered.filter(o => (o.mobile ? (o.mobile.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
        let obj3 = threads_list_filtered.filter(o => (o.assigned_executive_name ? (o.assigned_executive_name.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
        // let obj3 = thread_people.filter(o => (o.people == "" ? false : (o.people.toLowerCase().indexOf(val.toLowerCase()) != -1) ));
  
  
        
  
        obj1.forEach(function(obj) { 
            codes.push(obj.id);
        });
        obj2.forEach(function(obj) {
            codes.push(obj.id);
        });
        obj3.forEach(function(obj) {
            codes.push(obj.id);
        });
  
        
        
        var keyArchives = this.state.keyArchives;
        keyArchives.chats.forEach(element => {
          var messages = [];
          if(element.chat){
              messages = Object.keys(element.chat).map((k) => element.chat[k]);
          }
          
          if (messages.find(function(item) {return (item.text && item.text.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
            codes.push(element.oid);
          }
  
        });
        
        var new_codes = codes.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        });
  
        var newArray = threads_list_filtered.filter(function(item) {
            return new_codes.includes(item.id);    
        });
  
  
        this.setState({threads_list : newArray, searchText : val});
        
      }else{
        this.setState({threads_list : threads_list_filtered, searchText : val});
      }


      
    }
  }


  handleFilterClick() {
	this.setState({filter_showing : !this.state.filter_showing});
  }

  handleSearchClick(args) {
	  console.log("searching");
	  console.log(args);
	  this.setState({filter_args : args, filter_showing : !this.state.filter_showing});
	  API.tabSearchCall(this.state.selectedTab, this, args);
	//this.setState({filter_showing : !this.state.filter_showing});
  }

  handleRowClick(data, tag) {
	console.log(data);
	console.log(tag);
	// this.setState({selectedRow : data.order_id, showRightContent : true, rightContent : data});
  }

  handleTabClick(tag, keyX) {
    var that = this;
    console.log("chatuser1", tag);
    console.log("archival", "1", this.state.keyArchives, "2", this.state.keyArchives.chats.find(o => o.oid == tag));
    var chatList = this.state.keyArchives.chats.find(o => o.oid == tag).chat;
    
    var arr = [];

    if(chatList){
      const ordered = {};
      Object.keys(chatList).sort().forEach(function(key) {
          ordered[key] = chatList[key];
      });

      
      var count = 0;
      var currentModel;
      for(var key in ordered){
        if(!currentModel || (currentModel && !Constants.get_TimeComparison(currentModel['time'], ordered[key].time))){
              var textH = "";
              var textM = "";
                  
              
              var message= {
                          senderName:null,
                          senderUserID:null,
                          time:ordered[key].time,
                          notification:false,
                          textH : textH,
                          textM : textM
                          };

                  arr.push(message);
        }
          
          var message = ordered[key];
          var textH = '';
          var textM = '';
              
          if (message.notification){
              textH = message.type.toUpperCase();
              textM = message.text.toUpperCase();
              
          } else{
              textH = '';
              textM = message.text;
          }

          var message= {orderID:message.orderID,
                      senderName:message.senderName,
                      senderUserID:message.senderUserID,
                      time:message.time,
                      notification:message.notification,
                      type:message.type,
                      senderInternalID : message.senderInternalID,
                      textH : textH,
                      textM : textM,
                      action: message.action,
                      action_type:message.action_type,
                      logic : message.logic,
                      uploadedImages:message.uploadedImages,
                      }
          currentModel = message;
          arr.push(message);
          count += 1;
      }
    }

    this.setState({selectedTab : tag, all_models : arr});
	// API.tabClickCall(tag, that);
  }

  handleRightScroll(tag, orders) {
    var that = this;
    console.log("scrollHandling");
    API.getOrderDetailsPaginate(tag, orders, this, this.state.country.name.toLowerCase());
	// API.tabClickCall(tag, that);
  }

  handleLeftScroll(live, mine) {
    // var that = this;
    // console.log("this", this);
    // console.log("scrollHandling");
    // if(!live){
    //   API.getSupportChatArchives(this, this.state.archived_threads_list.length, mine, this);
    // }
	// API.tabClickCall(tag, that);
  }

  handleRightOrderClick(order) {
    var that = this;
    console.log("RightRightOrder");
    console.log(order);
    this.setState({rightOrder : order});
	// API.tabClickCall(tag, that);
  }

  handleBackOrderClick() {
    var that = this;
    console.log("RightRightOrder");
    this.setState({rightOrder : NaN});
	// API.tabClickCall(tag, that);
  }
  

  componentWillReceiveProps(){
    
  }

  componentWillMount() {
    
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
    
  }

  render() {
    console.log("themeeee", this.props.theme);
	  return(
      <React.Fragment>
        {this.state.showSearchLoader && <><div className='search-Loader'></div><div className='search-Loader-sb'></div></>} 
        <PanelTopBarArchives theme={this.props.theme} keyArchives={this.state.keyArchives} country={this.state.country}
          searchArchiveText={this.state.searchArchiveText} onArchiveSearch={this.handleArchiveSearch} onExecutiveArchiveSearch={this.handleExecutiveArchiveSearch} updateCountry={this.handleUpdateCountry}
          threadsStatus={this.state.threadsStatus} timeline={this.state.timeline} onTopbarSwitchClick={this.handleTopbarSwitchClick} 
          handleAgentSearch={this.handleAgentSearch} searchExecutive={this.state.searchExecutive} />
        <div className="chatarchivemainouter border-box clearfix">
        <PanelLeftArchives theme={this.props.theme} onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab} 
          keyArchives={this.state.keyArchives}
          threadsList = {this.state.threads_list} allModels = {this.state.all_models} onThreadSearch={this.handleThreadSearch}
          searchText={this.state.searchText} onLeftScroll={this.handleLeftScroll} showLeftLoader={this.state.showLeftLoader}/>
        <PanelMiddleArchives theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} 
          isOnline = {this.state.is_online} threadsList = {this.state.threads_list} allModels={this.state.all_models} searchText={this.state.searchText}
          keyArchives = {this.state.keyArchives} onRowClick={this.handleRowClick}/>
        <PanelRightArchives theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} 
          allModels={this.state.all_models} country={this.state.country}
          threadsList = {this.state.threads_list} showLoader={this.state.showLoader} rightOrder={this.state.rightOrder} onRightOrderClick={this.handleRightOrderClick}
          content1={this.state.rightContent1} content2={this.state.rightContent2} showContent1={this.state.showRightContent1} showContent2={this.state.showRightContent2} onRightScroll={this.handleRightScroll}
          onBackOrderClick={this.handleBackOrderClick} />
        </div>
        </React.Fragment>
    );
  }
}


export default SupportChatArchives;
