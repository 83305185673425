import React from 'react';
// import "../dashboard/dashboard.css"
import Row from "./row";
import KeyArchiveRow from "./keyarchiverow";

class MiddleMain extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }

    componentDidUpdate(prevProps) {
      if(this.props.selectedTab != 0 && this.props.selectedTab == prevProps.selectedTab){
        // console.log(this.props.selectedTabLenght, "chatRef1middlemain",prevProps.selectedTabLenght);
        this.props.scrollChat(prevProps);
      }else if(prevProps.selectedTab != this.props.selectedTab){
        // console.log(prevProps.selectedTab, "chatRef2middlemain");
        this.props.scrollChat(prevProps);
      }
    }

    scrollToBottom = () => {
      // this.props.chatRef.current.scrollTop = this.props.chatRef.current.scrollHeight;
    };
  
    render() {
        var that = this;
        // console.log(that.props.tag);
        
        if(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)){
          return (
            <React.Fragment />
          );    
        }else{
          var arr = [];
          //console.log("arr1", arr);
          if(this.props.live){
            arr = this.props.allModels[this.props.selectedTab];
          }else{
            arr = this.props.archivedAllModels[this.props.selectedTab];
          }


          // var arrKeyArchives = this.props.keyArchives;
          // var keyArchiveRows = []
          // if(arrKeyArchives && arrKeyArchives.key == that.props.selectedTab){
          //   keyArchiveRows = arrKeyArchives.chats.filter(o => o.chat != null).map((obj, index) =>
          //     <KeyArchiveRow key={index} theme={this.props.theme} data={obj} count={arrKeyArchives.chats.length} tag={that.props.tag} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText} />
          //   );
          // }

          if(arr){
            const rows = arr.map((obj, index) =>
              <Row key={index} theme={this.props.theme} data={obj} tag={that.props.tag} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText}
                prevModel={arr.indexOf(obj) == 0 ? null : arr[arr.indexOf(obj)-1]} nextModel={arr.indexOf(obj) == (arr.length-1) ? null : arr[arr.indexOf(obj)+1]} orderDetails={this.props.thread ? this.props.thread.orderDetails : {} }/>
            );
            return (
                <React.Fragment>
                  {/* { keyArchiveRows.length > 0 &&
                  keyArchiveRows
                  // <div className="archiveDivider border-box clearfix whitespan shadowtext weight600">^<br></br>Archives</div>
                  } */}
                  {rows}
                </React.Fragment>
              );
          }else{    
            return (
              <React.Fragment>
                
              </React.Fragment>
            );
          }
          
      }
    }
  }

export default MiddleMain;