import React, { useState } from 'react';
import firebase from "firebase/app";
import "firebase/storage";
import Constants from "../../constants";
import './ImageUploader.css';
import { NotificationContainer, NotificationManager} from 'react-notifications';

const ImageUploader = (props) => {
    const { theme } = props;
    const [imageUpload, setImageUpload] = useState(false);
    const [fileUpload, setFileupload] = useState(false);
    const [image, setImage] = useState([]);
    const [activeThumbIndx, setActiveThumbIndx] = useState(0);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [firebaseUploadedImages, setFirebaseImages] = useState([]);
    const [dashed, setDashed] = useState(false);
    const [Loader, setLoader] = useState(false);

    const fileObj = [];
    const fileArray = [];
    const firebaseImages = [];
    const handleChange = (e) => {
        setLoader(true);
        fileObj.push(e.target.files);
        setTimeout(() => {
            for (let i = 0; i < fileObj[0].length; i++) {
                // if (fileObj[0][i].type.match('image.*')) {
                    const { name } = fileObj[0][i];
                    const lastDot = name.lastIndexOf('.');
                    const ext = name.substring(lastDot + 1);
                    fileArray.push({
                      preview: URL.createObjectURL(fileObj[0][i]),
                      extension: ext,
                    });
                    // fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    firebaseImages.push(fileObj[0][i]);
                    // let url = handleFirebaseUpload(fileObj[0][i].name, fileObj[0][i]);
                // }
            }
            setImage([...image, ...fileArray]);
            setUploadedImages([...uploadedImages, ...firebaseImages]);
            setFileupload(true);
            setLoader(false);
        }, 500);
    }

    const handleFirebaseUpload = () =>{
        console.log('------------',uploadedImages);
        if(uploadedImages.length < 1 && props.selectedTab){
            NotificationManager.error('Please try later', 'Error', 5000);
            return false;
        }
        setLoader(true);
        
        const firebaseConfig = Constants.getFirebaseConfig();
        if (firebase.apps.length) {
            var otherProject = firebase.apps[0];
        } else {
            var otherProject = firebase.initializeApp(firebaseConfig);
        }

        const storage = otherProject === undefined ? firebase.storage() : otherProject.storage();
        var imagesFirebase = []; 
        var itemsProcessed = 0;
        for (let i = 0; i < uploadedImages.length; i++) {
            let name = uploadedImages[i].name;
            let file = uploadedImages[i];
            let path = `/chat_assets/images/executive/${props.selectedTab}/${name}`;
            let ref  = storage.ref().child(path);
            
            // var data = ref.put(file).then((snapshot) => {
            //     snapshot.ref.getDownloadURL().then(function (downloadURL) {
            //         console.log('download url',downloadURL);
            //         imagesFirebase.push(downloadURL);
            //         if(downloadURL)
            //             itemsProcessed++;
            //         if (downloadURL && itemsProcessed === uploadedImages.length - 1) {
            //             setFirebaseImages(imagesFirebase);
            //             setImageUpload(false); 
            //             setImage([]); 
            //             setUploadedImages([]);
            //             setActiveThumbIndx(0);
            //             setLoader(false);
            //             props.imageUploadButton(image, imagesFirebase, props.selectedTab);
            //         }
            //     });
            // });

            ref.put(file).then((snapshot) => snapshot)
            .then((snapshot) => snapshot.ref.getDownloadURL())
            .then((downloadURL) =>  {
                console.log('download url',downloadURL);
                // imagesFirebase.push(downloadURL);
                if(downloadURL){
                    itemsProcessed++;
                    imagesFirebase.push(downloadURL);
                }
                if (downloadURL && itemsProcessed === uploadedImages.length) {
                    setFirebaseImages(imagesFirebase);
                    setImageUpload(false); 
                    setImage([]); 
                    setUploadedImages([]);
                    setActiveThumbIndx(0);
                    props.imageUploadButton(image, imagesFirebase, props.selectedTab);
                    setLoader(false);
                }
            });
        }
        return;
    }

    const deleteImage = (index) =>{
        let arr = [...image];
        let uploadedArr = [...uploadedImages];
        let x = image.length - 1;

        if (x === index) {
            arr.splice(index,1); 
            uploadedArr.splice(index,1);
            setImage(arr);
            setUploadedImages(uploadedArr);
            if(arr.length > 1){
                setActiveThumbIndx(x-1);
            }else{
                setActiveThumbIndx(0);
            }
        } else {
            arr.splice(index,1);
            uploadedArr.splice(index,1);
            setImage(arr);
            setUploadedImages(uploadedArr);
            if(index == 0 && arr.length > 1){
                setActiveThumbIndx(1);
            }else{
                setActiveThumbIndx(0);
            }
        }
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        setDashed(true);
    };

    const onDragOver = (e) => {
        e.preventDefault();
        setLoader(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setDashed(false);
        setLoader(false);
    };

    const onDropEvent = (e) => {
        e.preventDefault();
        setDashed(false);
        fileObj.push(e.dataTransfer.files);
        setTimeout(() => {
            setLoader(true);
            for (let i = 0; i < fileObj[0].length; i++) {
                // if (fileObj[0][i].type.match('image.*')) {
                    const { name } = fileObj[0][i];
                    const lastDot = name.lastIndexOf('.');
                    const ext = name.substring(lastDot + 1);
                    fileArray.push({
                        preview: URL.createObjectURL(fileObj[0][i]),
                        extension: ext,
                    });
                    // fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    firebaseImages.push(fileObj[0][i]);
                // }
            }
            setImage(fileArray);
            setUploadedImages(firebaseImages);
            setFileupload(true);
            setLoader(false);
        }, 1000);
    };

    const handleUploadImages = () => {
        handleFirebaseUpload();
        
    };

    return (
        <>
          <button onClick={() => setImageUpload(true)} type="button" className={theme == "light" ? "btn btn_small btnwhite btncard move" : "btn btn_small btndark-theme1 btncard move"}>
              <img className="attachButton-iu" src={theme == "light" ? "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/attachRed.png" : "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/attachBlue.png"} alt="Attach" />
          </button>

          {imageUpload
            ? (
                <div className="modalComponent-iu">
                    <div className="modalBackground-iu">
                        <div
                            className="component-iu"
                            draggable="true"
                            onDragEnter={(e) => onDragEnter(e)}
                            onDragOver={(e) => onDragOver(e)}
                            onDragLeave={(e) => onDragLeave(e)}
                            onDrop={(e) => onDropEvent(e)}
                        >
                            <div className="header-iu">
                                <span>Preview</span>
                                <img onClick={() => { setImageUpload(false); setImage([]); setActiveThumbIndx(0); setUploadedImages([]) }} alt="img" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/cross.png" />
                            </div>
                            {dashed ? (
                                <div className="dragDotted-iu">Drag File Here</div>
                            ) : (
                                <>
                                    {Loader ? (
                                        <div className="loader-component-iu">
                                            <div className="loader-iu"></div>
                                            <div className="middle-iu">
                                                <span>Please Upload Image</span>
                                                <img className="uploadLogo-iu" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/upload.svg"  alt="Upload Image Logo" />
                                            </div>
                                        </div>
                                        ) : (
                                            <>
                                                <div className="middle-iu">
                                                    {image && image.length > 0
                                                    ? image[activeThumbIndx]['extension'] == "pdf" ? 
                                                    <embed
                                                        src={`${image[activeThumbIndx]['preview']}#toolbar=0&navpanes=0&zoom=30&scrollbar=0`}
                                                        type="application/pdf"
                                                        height="100%"
                                                        width="400px"
                                                    ></embed>
                                                    : <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={image[activeThumbIndx]['preview']} alt="img" />
                                                    : <>
                                                        <span>Please Upload/Drag Image</span>
                                                        <img className="uploadLogo-iu" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/upload.svg"  alt="Upload Image Logo" />
                                                        </>
                                                    }
                                                </div>

                                                {image && image.length > 0 ?
                                                    <div className="postButton-iu" onClick={() => {handleUploadImages()}}>
                                                        <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/enter.png" alt="sent button" />
                                                    </div> 
                                                : null }

                                                <div className="bottomBackground-iu">
                                                    <div role="button" style={{ width: 'fit-content', marginRight: '0.5rem' }}>
                                                        <label htmlFor="files">
                                                            <div className="uploadImageText-iu">
                                                                <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/OrderTracking/Components/escalation/path.svg" alt="img" />
                                                            </div>
                                                        </label>
                                                        <input multiple={true} id="files" name="invoice" type="file" onChange={(e) => {handleChange(e)}} style={{ display: 'none' }} />
                                                    </div>
                                                    {fileUpload ? 
                                                        <>
                                                            {image && image.length > 0 ? image.map((imgs, index) => {
                                                                return (
                                                                <div key={index} style={{ position: 'relative' }}>
                                                                    {imgs.extension == "pdf" ? 
                                                                        <img onClick={() => setActiveThumbIndx(index)} className={activeThumbIndx === index ? "imagePreview-iu activeBorder-iu" : "imagePreview-iu"} src={"/images/pdf-download.png"} alt="img" />
                                                                        :
                                                                    <img onClick={() => setActiveThumbIndx(index)} className={activeThumbIndx === index ? "imagePreview-iu activeBorder-iu" : "imagePreview-iu"} src={imgs.preview} alt="img" />
                                                                    }
                                                                    <img onClick={() => deleteImage(index)} className="crossImageEsc-iu" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/OrderTracking/Components/escalation/shape.svg" alt="img" />
                                                                </div>
                                                                )
                                                            }) : null }
                                                        </> : null }
                                                </div>
                                            </>
                                        )
                                    }
                            </>
                            )}
                        </div>
                    </div>
                </div>
             ) : null }
        </>
    )
}

export default ImageUploader;