import React, {useEffect, useState, useContext} from 'react';
import debounceData from './Debounce';
import {mapCheckerOnHTML, updateMapKeyStatus} from './MapInjector';
import { countryContext } from '../NewRightPannel/NewRightPanel'

const autocompleteService = {current: null};
let debounceMethod = null;
const MapLocator = (props) => {
    const [searchInput, updateSearchInput] = useState('');
    const [googleMapSession, setGoogleMapSession] = useState(null);
    const [searchOptions, setSearchOptions] = useState([]);
    const [oldMapAPIKey, setOldMapKey] = useState('');
    const countryDetail = useContext(countryContext)

    useEffect(() => {
        if(!localStorage.getItem("map_key")){
            localStorage.setItem('map_key', JSON.stringify({
                key: 'AIzaSyCB_7DV83b0isZlTvj6C7NoReSPYHC9Kc8',
                id: -1
        }));
        }
        if (localStorage.getItem("map_key")) {
			mapCheckerOnHTML(JSON.parse(localStorage.getItem("map_key")).key, true);
		}
    },[])
    useEffect(() => {
        window && window.google && setGoogleMapSession(new window.google.maps.places.AutocompleteSessionToken());
        if (localStorage.getItem('map_key')) {
            setOldMapKey(JSON.parse(localStorage.getItem('map_key')).key);
        }
        let searchPlaces = (inputValue) => {
            let countryCode = countryDetail.country==='malaysia'?'MY':'IN';
            if (inputValue && inputValue !== '') {
                let obj = {
                    input: inputValue,
                    componentRestrictions: {country: countryCode}
                }
                if (localStorage.getItem('map_key')) {
                    if (oldMapAPIKey !== JSON.parse(localStorage.getItem('map_key')).key) {
                        setGoogleMapSession(new window.google.maps.places.AutocompleteSessionToken());
                    }
                }
                if (googleMapSession) {
                    obj.sessionToken = googleMapSession;
                }
                if (localStorage.getItem('map_key')) {
                    if (oldMapAPIKey !== JSON.parse(localStorage.getItem('map_key')).key) {
                        if (window.google) {
                            autocompleteService.current = new window.google.maps.places.AutocompleteService();
                        } else {
                            if (!autocompleteService.current && window.google) {
                                autocompleteService.current = new window.google.maps.places.AutocompleteService();
                            }
                        }
                    }
                }
                if (!autocompleteService.current) {
                    return undefined;
                }
                autocompleteService.current.getPlacePredictions(obj, (result, status) => {
                    if (result && status === 'OK') {
                        setSearchOptions(result);
                    } else if (status === 'ZERO_RESULTS') {
                        setSearchOptions([]);
                    } else if (status === 'OVER_QUERY_LIMIT') {
                        console.log("bhai map exhaust hogya :(");
                        setSearchOptions([]);
                        updateMapKeyStatus(JSON.parse(localStorage.getItem('map_key')).id);
                    }
                });
            } else {
                setSearchOptions([]);
            }
        };
        debounceMethod = debounceData(searchPlaces, 1000);
    }, []);

    useEffect(() => {
        if (props.data && props.data !== '') {
            updateSearchInput(props.data);
        } else {
            updateSearchInput('');
        }
    }, [props.data]);

    // searchPlaces = debounceData(searchPlaces, 1000);

    const getPlaceDetails = (location) => {
        if (location) {
            const placeDetails = new window.google.maps.places.PlacesService(document.getElementById("google-map-demo"));
            placeDetails.getDetails({
                placeId: location.place_id,
                sessionToken: googleMapSession
            }, (data) => {
                setSearchOptions([]);
                let final_details = JSON.parse(JSON.stringify(data));
                props.parentCallback((findCityName(data, 'locality')), final_details.geometry.location, `${final_details.name},${final_details.formatted_address}`, findStateName(data, 'administrative_area_level_1'));
            })
        }
    };

    const findCityName = (addr, type) => {
        const comp = addr.address_components.find(
            (item) => item.types.indexOf(type) >= 0
        );
        if (comp && comp.short_name) {
            if (comp.short_name === 'Gurugram' || comp.short_name === 'Gurgaon') {
                return comp ? 'Gurgaon' : null;
            } else if (comp.short_name === 'New Delhi' || comp.short_name === 'Delhi') {
                return comp ? 'Delhi' : null;
            } else if (comp.short_name === 'Bangalore' || comp.short_name === 'Bengaluru') {
                return comp ? 'Bengaluru' : null;
            } else {
                return comp ? comp.short_name : null;
            }
        } else {
            return null;
        }
    }

    const findStateName = (addr, type = 'administrative_area_level_1') => {
        const comp = addr.address_components.find(
            (item) => item.types.indexOf(type) >= 0
        );
        return comp && comp.long_name ? comp.long_name : null;
    }

    let optionsList;
    if (searchOptions.length) {
        optionsList = (
            <ul className="dd-list">
                {searchOptions.map((item, index) => <li
                    className={`global-search-auto-complete dd-list-item`} key={index}
                    onClick={() => getPlaceDetails(item)}>
                    {item.description ? item.description : ''}
                </li>)}
            </ul>
        )
    }
    return (
        <div className="margin_r_16 addressContainer">
            <div className="custom-input">
                <div id="google-map-demo"/>
                <div className="inputContainer active x2-height ">
                    
                    <div className="mainInputContainer">
                        <textarea rows="3" className="mainInput x2-mainInput map-textarea"
                                  placeholder="Enter Map Location" 
                                  value={searchInput}
                                  onChange={(event) => {
                                      updateSearchInput(event.target.value);
                                      debounceMethod(event.target.value);
                                  }
                                  }
                                  disabled={props.isDisabled}
                                  />
                        {optionsList}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MapLocator