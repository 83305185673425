import React from 'react';

var statuses = [
    {
        status_type: 101,
        status: "READY FOR DELIVERY",
        id: 140
    },
    {
        status_type: 101,
        status: "DELIVERED/FEEDBACK/NOC",
        id: 150
    },
    {
        status_type: 101,
        status: "AT WORKSHOP",
        id: 100
    },
    {
        status_type: 101,
        status: "JOB CARD UPDATED",
        id: 120
    },
    {
        status_type: 101,
        status: "COMPLETED",
        id: 180
    },
    {
        status_type: 101,
        status: "WORK IN PROGRESS",
        id: 130
    },
    {
        status_type: 101,
        status: "DIAGNOSTIC COMPLETE",
        id: 110
    },
    {
        status_type: 101,
        status: "BILL GENERATED",
        id: 160
    },
    {
        status_type: 101,
        status: "CANCELLED",
        id: 95
    },
    {
        status_type: 101,
        status: "BILL RAISED",
        id: 170
    },
    {
        status_type: 101,
        status: "CANCELLED",
        id: 101
    },
    {
        status_type: 101,
        status: "AT WORKSHOP",
        id: 20
    },
    {
        status_type: 101,
        status: "PAYMENT SUCCESSFUL",
        id: 300
    },
    {
        status_type: 101,
        status: "PAYMENT TEMPERED",
        id: 325
    },
    {
        status_type: 101,
        status: "WORK IN PROGRESS",
        id: 40
    },
    {
        status_type: 101,
        status: "READY FOR DELIVERY",
        id: 50
    },
    {
        status_type: 101,
        status: "BILL GENERATED",
        id: 70
    },
    {
        status_type: 101,
        status: "DIAGNOSTIC COMPLETE",
        id: 30
    },
    {
        status_type: 101,
        status: "PAYMENT OPEN",
        id: 285
    },
    {
        status_type: 101,
        status: "NEW ORDER",
        id: 5
    },
    {
        status_type: 101,
        status: "DELIVERED",
        id: 60
    },
    {
        status_type: 101,
        status: "PAYMENT CANCELLED",
        id: 290
    },
    {
        status_type: 101,
        status: "CANCELLED",
        id: 21
    },
    {
        status_type: 101,
        status: "BILL RAISED",
        id: 80
    },
    {
        status_type: 101,
        status: "PAYMENT INITIATED",
        id: 280
    },
    {
        status_type: 101,
        status: "PAYMENT UNSUCCESSFUL",
        id: 350
    },
    {
        status_type: 101,
        status: "PICKUP COMPLETE",
        id: 10
    },
    {
        status_type: 101,
        status: "COMPLETED",
        id: 90
    },
    {
        status_type: 101,
        status: "ESTIMATE SEND",
        id: 31
    },
    {
        status_type: 103,
        status: "SURVEYOR VISIT",
        id: 1100
    },
    {
        status_type: 103,
        status: "D.O. RECEIVED",
        id: 1250
    },
    {
        status_type: 103,
        status: "CANCELLED",
        id: 1010
    },
    {
        status_type: 103,
        status: "REINSPECTION REQUESTED",
        id: 1170
    },
    {
        status_type: 103,
        status: "WORK IN PROGRESS",
        id: 1150
    },
    {
        status_type: 103,
        status: "ESTIMATE PREPARED",
        id: 1060
    },
    {
        status_type: 103,
        status: "REINSPECTION DONE",
        id: 1180
    },
    {
        status_type: 103,
        status: "PICKUP COMPLETED",
        id: 900
    },
    {
        status_type: 103,
        status: "SURVEY COMPLETE",
        id: 1070
    },
    {
        status_type: 103,
        status: "CUSTOMER APPROVED",
        id: 1085
    },
    {
        status_type: 103,
        status: "PAYMENT RECEIVED",
        id: 1300
    },
    {
        status_type: 103,
        status: "AT WORKSHOP",
        id: 1000
    },
    {
        status_type: 103,
        status: "PARTS ORDERED",
        id: 1090
    },
    {
        status_type: 103,
        status: "SURVEYOR APPROVED",
        id: 1075
    },
    {
        status_type: 103,
        status: "ESTIMATE APPROVED",
        id: 1080
    },
    {
        status_type: 103,
        status: "CLAIM FILE",
        id: 1050
    },
    {
        status_type: 103,
        status: "PARTS RECEIVED",
        id: 1091
    },
    {
        status_type: 103,
        status: "Q.C REQUIRED",
        id: 1160
    },
    {
        status_type: 103,
        status: "READY FOR DELIVERY",
        id: 1200
    },
    {
        status_type: 103,
        status: "DELIVERED",
        id: 1350
    },
    {
        status_type: 103,
        status: "COMPLETED",
        id: 1400
    },
    {
        status_type: 103,
        status: "DEAD",
        id: 1015
    },
    {
        status_type: 103,
        status: "DOCUMENTS COMPLETE",
        id: 1040
    },
    {
        status_type: 103,
        status: "NEW ORDER",
        id: 1500
    },
    {
        status_type: 103,
        status: "NEW ORDER",
        id: 850
    },
    {
        status_type: 104,
        status: "NEW ORDER",
        id: 2000
    },
    {
        status_type: 104,
        status: "PENDING",
        id: 2010
    },
    {
        status_type: 104,
        status: "SOLD",
        id: 2020
    },
    {
        status_type:110,
        status:"ORDER PLACED",
        id:1
    },
    {
        status_type:110,
        status:"ORDER PLACED",
        id:2
    },
    {
        status_type:110,
        status:"ORDER PLACED",
        id:5
    },
    {
        status_type:110,
        status:"DISPATCHED",
        id:6
    },
    {
        status_type:110,
        status:"RETURNED/CANCELLED",
        id:7
    },
    {
        status_type:110,
        status:"OUT FOR DELIVERY",
        id:8
    },
    {
        status_type:110,
        status:"IN-TRANSIT",
        id:9
    },
    {
        status_type:110,
        status:"DELIVERED",
        id:10
    },
    {
        status_type:110,
        status:"RETURNED",
        id:11
    },
    {
        status_type:110,
        status:"CANCELLED",
        id:12
    },
    {
        status_type: 107,
        status: "READY FOR DELIVERY",
        id: 140
    },
    {
        status_type: 107,
        status: "DELIVERED/FEEDBACK/NOC",
        id: 150
    },
    {
        status_type: 107,
        status: "AT WORKSHOP",
        id: 100
    },
    {
        status_type: 107,
        status: "JOB CARD UPDATED",
        id: 120
    },
    {
        status_type: 107,
        status: "COMPLETED",
        id: 180
    },
    {
        status_type: 107,
        status: "WORK IN PROGRESS",
        id: 130
    },
    {
        status_type: 107,
        status: "DIAGNOSTIC COMPLETE",
        id: 110
    },
    {
        status_type: 107,
        status: "BILL GENERATED",
        id: 160
    },
    {
        status_type: 107,
        status: "CANCELLED",
        id: 95
    },
    {
        status_type: 107,
        status: "BILL RAISED",
        id: 170
    },
    {
        status_type: 107,
        status: "CANCELLED",
        id: 101
    },
    {
        status_type: 107,
        status: "AT WORKSHOP",
        id: 20
    },
    {
        status_type: 107,
        status: "PAYMENT SUCCESSFUL",
        id: 300
    },
    {
        status_type: 107,
        status: "PAYMENT TEMPERED",
        id: 325
    },
    {
        status_type: 107,
        status: "WORK IN PROGRESS",
        id: 40
    },
    {
        status_type: 107,
        status: "READY FOR DELIVERY",
        id: 50
    },
    {
        status_type: 107,
        status: "BILL GENERATED",
        id: 70
    },
    {
        status_type: 107,
        status: "DIAGNOSTIC COMPLETE",
        id: 30
    },
    {
        status_type: 107,
        status: "PAYMENT OPEN",
        id: 285
    },
    {
        status_type: 107,
        status: "NEW ORDER",
        id: 5
    },
    {
        status_type: 107,
        status: "DELIVERED",
        id: 60
    },
    {
        status_type: 107,
        status: "PAYMENT CANCELLED",
        id: 290
    },
    {
        status_type: 107,
        status: "CANCELLED",
        id: 21
    },
    {
        status_type: 107,
        status: "BILL RAISED",
        id: 80
    },
    {
        status_type: 107,
        status: "PAYMENT INITIATED",
        id: 280
    },
    {
        status_type: 107,
        status: "PAYMENT UNSUCCESSFUL",
        id: 350
    },
    {
        status_type: 107,
        status: "PICKUP COMPLETE",
        id: 10
    },
    {
        status_type: 107,
        status: "COMPLETED",
        id: 90
    },
    {
        status_type: 107,
        status: "ESTIMATE SEND",
        id: 31
    },
    {"status_type":109,"status":"READY FOR DELIVERY","id":140},{"status_type":109,"status":"DELIVERED/FEEDBACK/NOC","id":150},{"status_type":109,"status":"AT WORKSHOP","id":100},{"status_type":109,"status":"JOB CARD UPDATED","id":120},{"status_type":109,"status":"COMPLETED","id":180},{"status_type":109,"status":"WORK IN PROGRESS","id":130},{"status_type":109,"status":"DIAGNOSTIC COMPLETE","id":110},{"status_type":109,"status":"BILL GENERATED","id":160},{"status_type":109,"status":"CANCELLED","id":95},{"status_type":109,"status":"BILL RAISED","id":170},{"status_type":109,"status":"CANCELLED","id":109},{"status_type":109,"status":"AT WORKSHOP","id":20},{"status_type":109,"status":"PAYMENT SUCCESSFUL","id":300},{"status_type":109,"status":"PAYMENT TEMPERED","id":325},{"status_type":109,"status":"WORK IN PROGRESS","id":40},{"status_type":109,"status":"READY FOR DELIVERY","id":50},{"status_type":109,"status":"BILL GENERATED","id":70},{"status_type":109,"status":"DIAGNOSTIC COMPLETE","id":30},{"status_type":109,"status":"PAYMENT OPEN","id":285},{"status_type":109,"status":"NEW ORDER","id":5},{"status_type":109,"status":"DELIVERED","id":60},{"status_type":109,"status":"PAYMENT CANCELLED","id":290},{"status_type":109,"status":"CANCELLED","id":21},{"status_type":109,"status":"BILL RAISED","id":80},{"status_type":109,"status":"PAYMENT INITIATED","id":280},{"status_type":109,"status":"PAYMENT UNSUCCESSFUL","id":350},{"status_type":109,"status":"PICKUP COMPLETE","id":10},{"status_type":109,"status":"COMPLETED","id":90},{"status_type":109,"status":"ESTIMATE SEND","id":31},
];

const user_access = (sessionStorage.getItem("user_access")!="undefined" &&  sessionStorage.getItem("user_access")!=undefined)? sessionStorage.getItem("user_access") : 'retail'
const role = localStorage.getItem("role")
const nodeFolders = { retail: 'support', spares: 'supportSpares' }
export const initialStates = { spares: "support_spares_message_start", retail: 'support_message_start' }
export const initialLogicStates = { retail: "support_message_logic_start", spares: "support_spares_message_logic_start" }

export const URLS = {
    baseURL: {
        retail: {
            base_url: "https://comms.gomechanic.app/", 
            base_url_other: "https://gomechanic.app/",
            base_url_wf: "https://eapp.gomechanic.app/",
            base_url_ml: "https://leads.gomechanic.app/",
            base_url_other_mal: "https://mly.gomechanic.app/",
            base_url_ml_mal: "https://mly.leads.gomechanic.app/",
            base_url_central: "https://central.gomechanic.app/",
            base_url_central_mal :'https://mly.central.gomechanic.app/'
        },
        spares: {
            base_url: "https://comms.gomechanic.app/",
            base_url_other: "https://gomechanic.app/",
            base_url_wf: "https://eapp.gomechanic.app/",
            base_url_ml: "https://leads.gomechanic.app/",
            base_url_other_mal:"https://mly.gomechanic.app/",
            base_url_ml_mal: "https://mly.leads.gomechanic.app/",
            base_url_central: "https://central.gomechanic.app/",
            base_url_central_mal :'https://mly.central.gomechanic.app/'
        }
    },
    endpoints: {
        retail: {
            get_orders: "api/v1/oauth/orders/get_orders",
            order_paginate: "api/v1/order/order_details_paginate/",
            get_fleets: "api/v1/oauth/fleets/get_fleets",
            get_fleets_summary: "api/v1/oauth/fleets/get_fleets_summary",
            get_garages_summary: "api/v1/oauth/garages/get_garages_summary",
            get_garages: "api/v1/oauth/garages/get_garages",
            search_orders: "api/v1/oauth/orders/search_orders",
            get_chat_archive: "api/v1/support/get_support_chat_archives/",
            get_user_profile_chat: "api/v1/profile/get_user_profile_chat/",
            get_chat_archive_search: "api/v1/support/get_support_chat_archives_search/",
            get_chat_archive_search_mobile: "api/v1/support/get_support_chat_archives_by_mobile/",
            send_otp: "api/send_otp",
            verify_login: "api/verify_login_web",
            logout: "api/logout",
            credit: "api/v1/money/wallet/credit",
            razorpay_refund: "razorpay_initiate_refund",
            escalation: "api/v1/oauth/escalation/",
            postEscalation : "central/escalations/detail/", 
            putEscalation : "central/escalations/detail/",
            getEscalation : "central/escalations/detail/",
            chat_order_services: "api/v1/oauth/customer/chat_order_services",
            chat_lead: "chat-leads/",
            get_cities: "api/get_cities_v1",
            get_order_statuses: "api/v1/oauth/orders/get_order_statuses",
            order_cancel: "api/order_cancel_by_agent",
            post_coupons: "api/v1/oauth/customer/voucher_coupon_get_post",
            gst_bill: "api/v1/oauth/order/gst_bill/",
            acc_invoice:"api/v1/oauth/accessories/acc_download_dth_invoice",
            customer_overview: "api/v1/profile/get_user_overview_chat/",
            save_remarks:"api/v1/oauth/customer/save_customer_agent_chat_remarks",
            sos_services:"api/v5/oauth/customer/sos_services",
            chat_checkout:"api/v1/oauth/customer/chat_checkout",
            cancel_accessories_order:"api/v1/oauth/accessories/acc_dth_cancel_order",
            getComplaintTypes : "central/escalations-types/",
            acc_details : "api/v1/oauth/accessories/acc_service_details_by_id_chat"
        },
        spares: {
            get_orders: "api/v1/oauth/orders/get_orders",
            order_paginate: "api/v1/order/order_details_paginate_spares/",
            search_orders: "api/v1/oauth/orders/search_orders",
            send_otp: "api/send_otp",
            logout: "api/logout",
            verify_login: "api/verify_login_web",
            escalation: "spares-escalations/",
            get_user_profile_chat: "api/v1/profile/get_user_profile_chat_spares/",
            get_chat_archive: "api/v1/support/get_support_chat_archives_spares/",
            get_chat_archive_search: "api/v1/support/get_support_chat_archives_search_spares/",
            get_chat_archive_search_mobile: "api/v1/support/get_support_chat_archives_by_mobile_spares/",
            get_order_statuses: "api/v1/oauth/orders/get_order_statuses",
            get_escalation: "spares-escalations-detail/",
            customer_overview: "api/v1/profile/get_user_overview_chat/",
            save_remarks:"api/v1/oauth/customer/save_customer_agent_chat_remarks",
            sos_services:"api/v5/oauth/customer/sos_services",
            chat_checkout:"api/v1/oauth/customer/chat_checkout"
        }
    }
}

const base_url = URLS.baseURL[user_access].base_url
const base_url_other = URLS.baseURL[user_access].base_url_other
const base_url_wf = URLS.baseURL[user_access].base_url_wf
const base_url_ml = URLS.baseURL[user_access].base_url_ml
const base_url_other_mal = URLS.baseURL[user_access].base_url_other_mal
const base_url_ml_mal = URLS.baseURL[user_access].base_url_ml_mal
const base_url_central = URLS.baseURL[user_access].base_url_central
const base_url_central_mal = URLS.baseURL[user_access].base_url_central_mal

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const disposition = () => {
    return [{
    "id":1,
    "sub_status":"Mistakenly booked/App testing /Price check",
    },
    {
    "id":8,
    "sub_status":"Unanswered"
    },
    {
    "id":4,
    "sub_status":"Looking for authorized",
    },
    {
    "id":9,
    "sub_status":"Done by Local Mechanic/Authorized",
    },
    {
    "id":2,
    "sub_status":"Location constraint",
    },
    {
    "id":3,
    "sub_status":"Not Interested",
    },
    {
    "id":21,
    "sub_status":"RSA",
    },
    {
    "id":22,
    "sub_status":"Bike/No Car/Job/Marketing",
    },
    {
    "id":23,
    "sub_status":"Workshop Tie-Ups",
    },
    {
    "id":24,
    "sub_status":"Customer unreachable as the required attempts are completed",
    },
    {
    "id":33,
    "sub_status":"Duplicate Lead",
    },
    {
    "id":76,
    "sub_status":"Location constraint for non-operational city",
    }];
}

const viewPermissions = {
    spares: {
        sparesTimeline: { permittedKeys: [] },
        order: { permittedKeys: [] },
        orderDetail: {
            permittedKeys:
                ['upper_order_id', 'location', 'amount', 'payment_status', 'upper_address', 'spares_order_type', 'status', 'new_order_id', 'item_id', 'sku_code', 'sku_brand', 'agentComment']
        },
        agentComment: { permittedKeys: ['agentComment'] },
    },
    retail: {
        retailTimeline: { permittedKeys: [] },
        subscriptionBox: { permittedKeys: [] },
        order: { permittedKeys: ['order-middle'] },
        orderDetail: {
            permittedKeys:
                ['orderDetailsHeader', 'registration_no', 'location', 'status', 'amount', 'payment_status', 'cr_name', 'lower_address', 'order_id', 'go_app_earning', 'pick_date', 'update_items', 'retail_order_type', 'agentComment']
        },
    }
}

const actionPermissions = {
    retail: ['new_order', 'order_actions', 'add_escalations', 'escalations_voucher', 'cancel_order', 'add_refund','agent_comment', 'customer_overview'], spares: ['add_escalations','agent_comment']
}

const databaseURL = "https://gomechanic-retail.firebaseio.com/";

export const databaseFolder = () => {
    if (role === 'manager') {
        if (user_access)
            return nodeFolders[user_access]
        else return nodeFolders.retail
    }
    else
        return user_access ? nodeFolders[user_access] : nodeFolders.retail
}

export const getCurrencySymbol = (country) =>{
    switch(country){
        case "malaysia":
            return 'RM'
        case "india":
            return '₹'
    }
}  

export const getToken = (type) => {
    switch(type){
        case "ml":
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2OTMwMzU2MDguNzU4NzcxLCJpYXQiOjE2NjE1ODQxNzguNzU4NzksInN1YiI6IjI1ODI5MSJ9.VwwSBfD5parnh_U3CaNsBjMGoLe4pG28wGDT_Qt_fUY"
        case "main": 
            return 
        case "comms":
            return 
    }
}

const firebase_config = {
    apiKey: "AIzaSyBrl7ms6peFc8DgUKwJpp231XoCeI23PpU",
    authDomain: "gomechanic-retail.firebaseapp.com",
    databaseURL: databaseURL,
    projectId: "gomechanic-retail",
    storageBucket: "gomechanic-retail.appspot.com",
    messagingSenderId: "5389558266",
    appId: "1:5389558266:web:e7a91a4051e54edb"
};

class Constants {
    getDatabaseURL() {
        return databaseURL;
    }
    getFirebaseConfig() {
        return firebase_config;
    }
    getBaseURL() {
        return base_url;
    }
    getBaseURLOther(country) {
        if(country==='india')
        return base_url_other;
        else if(country === 'malaysia')
        return base_url_other_mal;
        else 
        return base_url_other;
    }
    getBaseURLWF() {
        return base_url_wf;
    }
    getBaseURLML(country) {
        if(country==='india')
        return base_url_ml;
        else if(country === 'malaysia')
        return base_url_ml_mal;
        else 
        return base_url_ml;
    }
    getBaseURLCentral(country='india') {
        if(country==='india')
        return base_url_central;
        else if(country === 'malaysia')
        return base_url_central_mal ;
    }
    orderTypes() {
        return { 101: "General", 100: "Preventive", 103: "Insurance", 102: "Document" };
    }

    setStatuses(data) {
        localStorage.setItem("statuses", JSON.stringify(data));
    }
    setFleetsSummary(data) {
        localStorage.setItem("fleets_summary", JSON.stringify(data));
    }

    setGaragesSummary(data) {
        localStorage.setItem("garages_summary", JSON.stringify(data));
    }

    statuses() {
        return statuses;
    }

    getOrderStatus(order_type, status_id) {
        if (user_access == "retail") {
            var filteredstatuses = statuses.filter(o => (o.status_type == order_type && o.id == status_id));
            var status = "NA";
            if (filteredstatuses.length > 0) {
                status = filteredstatuses[0].status;
            }
            return status;
        } else {
            return status_id;
        }
    }

    getTimeDifferenceFromMill(diff) {
        var secs = Math.floor(diff / 1000);
        if (secs < 60) {
            return secs + "s";
        } else {
            var mins = Math.floor(secs / 60);
            if (mins < 60) {
                secs = secs % 60;
                return mins + "m" + " " + secs + "s";
            } else {
                var hours = Math.floor(mins / 60);
                mins = mins % 60;
                return hours + "h" + " " + mins + "m";
            }
        }
    }

    get_TimeString = function (date) {
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";	
        var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;	

        return timeString;
    };

    get_DateString = function (date) {
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };

    get_DateStringX = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };

    getCalenderMinMax(increment = "") {
        var date = new Date();

        if (increment) {
            date.setDate(date.getDate() + increment);
        }
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (date <= 9) {
            date = "0" + date;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        var dateString = date.getFullYear() + "-" + month + "-" + day;
        return dateString;
    }


    get_DateString1 = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        if (date.getDay() == nowdate.getDay() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()) {
            return hour + ":" + minute + " " + ampm;
        } else {
            if (date.getYear() != nowdate.getYear()) {
                return monthNames[month - 1] + " " + date.getFullYear();
            } else {
                return day + " " + monthNames[month - 1];
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";	
        // var timeString = hour + ":" + minute + " " + ampm;	
        // dateString += timeString;	

        //         return dateString;	
    };

    get_DateString1Date = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (date <= 9) {
            date = "0" + date;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        if (date.getDay() == nowdate.getDay() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()) {
            return "Today";
        } else {
            if (date.getYear() != nowdate.getYear()) {
                return day + " " + monthNames[month - 1] + " " + date.getFullYear();
            } else {
                return day + " " + monthNames[month - 1];
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";	
        // var timeString = hour + ":" + minute + " " + ampm;	
        // dateString += timeString;	

        //         return dateString;	
    };

    get_DateString2 = function (date1, onlyDate = false) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        if (onlyDate) {
            return day + " " + monthNames[month - 1] + " " + date.getFullYear()
        }
        var dateString = day + " " + monthNames[month - 1] + " " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };

    get_DateString3 = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }
        var dateString = "";
        if (date.getDay() == nowdate.getDay() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()) {
            dateString = "Today";
        } else {
            if (date.getYear() != nowdate.getYear()) {
                dateString = day + " " + monthNames[month - 1] + " " + date.getFullYear();
            } else {
                dateString = day + " " + monthNames[month - 1];
            }
        }
        return dateString + ", " + hour + ":" + minute + " " + ampm;

    };

    get_TimeComparison = function (date1, date2) {
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDay() == date22.getDay() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear()) {
            return true;
        } else {
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";	
        // var timeString = hour + ":" + minute + " " + ampm;	
        // dateString += timeString;	

        //         return dateString;	
    };

    get_TimeComparison1 = function (date1, date2) {
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDay() == date22.getDay() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear() && date11.getHours() == date22.getHours() && date11.getMinutes() == date22.getMinutes()) {
            return true;
        } else {
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";	
        // var timeString = hour + ":" + minute + " " + ampm;	
        // dateString += timeString;	

        //         return dateString;	
    };

    get_DateComparison = function (epoch, type) {
        var date = new Date(epoch);
        var datenow = new Date();
        switch (type) {
            case "today": {
                console.log("datenow", datenow, date);
                if (date.getDay() == datenow.getDay() && date.getMonth() == datenow.getMonth() && date.getFullYear() == datenow.getFullYear()) {
                    console.log("true");
                    return true;
                }
                break;
            }
            case "yesterday": {
                datenow.setDate(datenow.getDate() - 1);
                console.log("datenow", datenow, date);
                if (date.getDay() == datenow.getDay() && date.getMonth() == datenow.getMonth() && date.getFullYear() == datenow.getFullYear()) {
                    console.log("true");
                    return true;
                }
                break;
            }
            case "lastweek": {
                datenow.setDate(datenow.getDate() - 7);
                console.log("datenow", datenow, date);
                if (epoch > datenow.getTime()) {
                    console.log("true");
                    return true;
                }
                break;
            }
            case "lastmonth": {
                datenow.setMonth(datenow.getMonth() - 1)
                console.log("datenow", datenow, date);
                if (epoch > datenow.getTime()) {
                    console.log("true");
                    return true;
                }
                break;
            }
            case "last6months": {
                datenow.setMonth(datenow.getMonth() - 6)
                console.log("datenow", datenow, date);
                if (epoch > datenow.getTime()) {
                    console.log("true");
                    return true;
                }
                break;
            }
            case "lastyear": {
                console.log("datenow", datenow.getFullYear());
                if (date.getFullYear() == datenow.getFullYear() - 1) {
                    console.log("true");
                    return true;
                }
                break;
            }
            default: {
                return false;
            }
        }
        return false;
    }

    initializeTinyMCE() {
        // tinymce.init({	
        //     selector: '#mytextarea'	
        // });	
    }
    getTime12HourFormat = function (time) {
        console.log("time12", time);
        var timeSplit = time.split(":");
        var hour = parseInt(timeSplit[0]);
        var minute = parseInt(timeSplit[1]);
        var ampm = hour < 12 ? "AM" : "PM";
        // var value = "";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (minute == 0) {
            return hour + ":00 " + ampm;
        } else {
            if (minute <= 9) {
                minute = "0" + minute;
            }
            return hour + ":" + minute + " " + ampm;
        }
    }
    checkAvailableTimings = function (start_time, end_time) {
        if (start_time && end_time) {
            var nowdate = new Date();
            var nowhour = nowdate.getHours();
            var nowminute = nowdate.getMinutes();
            var startSplit = start_time.split(":");
            var endSplit = end_time.split(":");

            var startHour = parseInt(startSplit[0]);
            var startMinute = parseInt(startSplit[1]);
            var endHour = parseInt(endSplit[0]);
            var endMinute = parseInt(endSplit[1]);

            var start_check = false;
            var end_check = false;

            if (nowhour > startHour) {
                start_check = true;

            } else if (nowhour == startHour) {
                if (nowminute >= startMinute) {
                    start_check = true;
                }
            }

            if (nowhour < endHour) {
                end_check = true;

            } else if (nowhour == endHour) {
                if (nowminute <= endMinute) {
                    end_check = true;
                }
            }

            return (start_check && end_check);
        } else {
            return false;
        }
    }

    distanceCalculator = (lat1, lon1, lat2, lon2) => {
        const dLat = ((parseFloat(lat2) - parseFloat(lat1)) * Math.PI) / 180.0;
        const dLon = ((parseFloat(lon2) - parseFloat(lon1)) * Math.PI) / 180.0;
        // convert to radians
        lat1 = (parseFloat(lat1) * Math.PI) / 180.0;
        lat2 = (parseFloat(lat2) * Math.PI) / 180.0;
        // apply formulae
        const mid_val =
            Math.pow(Math.sin(dLat / 2), 2) +
            Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const rad = 6371;
        const ans_rad = 2 * Math.asin(Math.sqrt(mid_val));
        return rad * ans_rad;
    };

    getNearestCity = function (
        lat,
        long,
        citiesList,
        default_shortest_dist = true
    ) {
        let shortest_dist = null;
        if (!default_shortest_dist) {
            shortest_dist = 800;
        } else {
            shortest_dist = 100;
        }
        let city = null;
        for (const [key, value] of Object.entries(citiesList)) {
            const dist = this.distanceCalculator(
                lat,
                long,
                value.location[0],
                value.location[1]
            );
            if (dist < shortest_dist) {
                city = key;
                shortest_dist = dist;
            }
        }
        return city;
    };

    checkViewPermission = function (component, key) {
        if (component && !key)
            return !!viewPermissions[user_access][component]
        else return viewPermissions[user_access][component] && viewPermissions[user_access][component].permittedKeys.includes(key)
    }
    checkActionPermission = function (action_name) {
        return actionPermissions[user_access].includes(action_name.toLowerCase())
    }
}

export default new Constants(); 