import React from 'react';
import KeyArchiveRow from "./keyarchiverow";
import Loader from "../loader";

class ArchiveMain extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
      // console.log(this.props);
      
    }

    componentDidUpdate() {
      // console.log("chatRef");
      // console.log(this.props.ref.current);
      // console.log("referring");
      // console.log(this.ref);
      // this.scrollToBottom();
      this.props.scrollChat();
    }

    scrollToBottom = () => {
      // this.props.chatRef.current.scrollTop = this.props.chatRef.current.scrollHeight;
    };
  
    render() {
        var that = this;
        // console.log(that.props.tag);
        
        if(!this.props.keyArchives){
          return (
            <React.Fragment><Loader theme={this.props.theme} fullHeight="true"/></React.Fragment>
          );    
        }else{
          


          var arrKeyArchives = this.props.keyArchives;
          var keyArchiveRows = []
          if(arrKeyArchives){
            keyArchiveRows = arrKeyArchives.chats.filter(o => o.chat != null).map((obj, index) =>
              <KeyArchiveRow key={index} theme={this.props.theme} data={obj} count={arrKeyArchives.chats.length} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText} />
            );
          }

          //console.log("arr", arr);
            return (
                <React.Fragment>
                  {keyArchiveRows}
                  
                </React.Fragment>
              );
          
      }
    }
  }

export default ArchiveMain;