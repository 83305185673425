import React,{ useState, useContext } from 'react'
import { countryContext } from '../NewRightPannel/NewRightPanel'
import { getCurrencySymbol } from '../../constants' 
import './subscriptionBox.css'

const SubscriptionBox = ({subscriptionDetail}) => {
    const [isCardOpen,setIsCardOpen] = useState(false)
    const { country } = useContext(countryContext) 

    return (
        <>
            <div className='subscription-header subs-flex' onClick={(e)=>setIsCardOpen((prev)=>!prev)}>
                <div className='header-right'>{subscriptionDetail.header}
                {subscriptionDetail.data.length>1?` (${subscriptionDetail.data.length})`:''}
                </div>
                <div className='header-left'>
                  {/* <div className={subscriptionDetail.isActive?'active-badge':'expired-badge'}>{subscriptionDetail.isActive?'Active':'Expired'}</div>  */}
                  <div><img className={isCardOpen?'collapse-arrow arrow-open':'collapse-arrow'} src="/images/ic_expand_more.svg" alt="expand"></img></div>
               </div>
            </div>
            <div className='subs-card'>
            {isCardOpen && 
            subscriptionDetail.data.map((each)=>
            <div className='subscription-content'>
                <div className={each.isActive?'active-badge':'expired-badge'}>
                {each.isActive?'Active':'Expired'}
                </div>
                <div className='sub-details subs-flex'>
                    <div>
                        <img className='car-image' src={each.carDetails.car_image} alt='car'/>
                    </div>
                    <div className='car-details'>
                        <div>{each.carDetails.car_number}</div>
                        <div>{each.carDetails.car_name}</div>
                    </div>
                </div>
                <div className='purchase-detail'>
                    {subscriptionDetail.header==='Warranty' && 
                    (each.coverage.year ||each.coverage.distance) &&
                    <div className='coverage-table'>
                        <div className='table-header'>Active Coverage</div>
                        <div className='table-content subs-flex'>
                            <div className='bold-text'> Platinium Coverage</div>
                            <div>{each.coverage.year}yr/{each.coverage.distance} kms</div>
                        </div>
                    </div>}
                    <div className='date-labels subs-flex'>
                        <div>Purchase Date:</div>
                        <div>Expired On:</div>
                    </div>
                    <div className='date-values subs-flex'>
                        <div className='date-details'>{each.started_at}</div>
                        <div className='date-details'>{each.expires_at}</div>
                    </div>
                </div>
                <div className='total-amount subs-flex'>
                   <div className='card-text-width'>Total Amount:</div>
                   <div className='card-text-width bold-text'> {getCurrencySymbol(country)}{each.amount}</div>
                </div>    
            </div>)}
            </div>
        </>
)
}

export default SubscriptionBox