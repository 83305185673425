import React, {useEffect, useState } from 'react';
import API from '../../api';
import { NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './ChatTransfer.css';
import MultiSelect from "../../custom/MultiSelect";
import firebase from '../../firebase';
import Constants,{databaseFolder} from '../../constants'

const user_id = localStorage.getItem("user_id")   

const ChatTransfer = (props) => {
    const [executives, setExecutives] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState({});
    const orderId = props && props.orderId ? props.orderId : "";
    const [remarks, setRemarks] = useState();
   
    const onChange = (val) => {
        if(val.id){
            var app = firebase.app();
            var database = app.database(Constants.getDatabaseURL());
            database.ref(databaseFolder()+"/executives/"+val.id+"/chat_count").set(val.chat_count+1)
            database.ref(databaseFolder()+"/executives/"+selectedExecutive.id+"/chat_count").set(selectedExecutive.chat_count-1)
            database.ref(databaseFolder()+"/supportchat/"+props.selectedTab+"/assigned_executive").set(val.id);
            database.ref(databaseFolder()+"/supportchat/"+props.selectedTab+"/executive_name").set(val.name);
            NotificationManager.success(`Chat Transfered`, 'Successfully', 5000);
            props.onTransferChat();
        }
        // var eRef = database.ref('support/executives/'+localStorage.getItem("user_id")+'/is_online');
    }

    const getExecutives = () => {
        var app = firebase.app();
        setExecutives([]);
        var database = app.database(Constants.getDatabaseURL());
        var seRef = database.ref(databaseFolder()+'/executives');
    
        // if(executives.length == 0){
            let listener1 = seRef.once('value').then(function(data) {
                // console.log("quick",data);
                let exec = [];
                data.forEach(function(childNode){
                    if(childNode.key===localStorage.getItem("user_id")){
                        setSelectedExecutive({
                            'id': childNode.key,
                            'name':childNode.val().name,
                            'is_online' : childNode.val().is_online,
                            'chat_count': childNode.val().chat_count||0,
                        })
                    }
                    else if(childNode.val().name && childNode.val().is_online && childNode.key!=localStorage.getItem("user_id")){
                        let temp = {};
                        temp['id'] = childNode.key;
                        temp['name'] = childNode.val().name;
                        temp['is_online'] = childNode.val().is_online;
                        temp['chat_count'] = childNode.val().chat_count||0;
                        exec.push(temp);
                    }
                })
                // console.log("quick",exec);
                setExecutives(exec);
            });
        // }
    }

    // useEffect(() => {
        // if(executives.length == 0){
        //     getExecutives()
        // }
    // });
    return (
        <>
          {/* <div className="add-escalation-es" onClick={() => setOpen(true)}>ADD ESCALATION</div> */}
            <>
            <React.Fragment>
                <MultiSelect
                options={executives}
                labelKey="name"
                checkbox_id="name"
                placeholder="Transfer Chat"
                disabledItems={[]}
                selectedOptions={selectedExecutive}
                onInputClick={() => {getExecutives()}}
                onChange={(value) =>
                    onChange(value)
                }
                />
            </React.Fragment>
            </> 
        </>
    )
}

export default ChatTransfer