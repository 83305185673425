import React from 'react';
import "./topbar/topbar1.css"

function TopbarLogin() {
  return (
  	<div className="topbarouter" id="topbarouter">
    <div className="topbarinner" id="topbarinner">
    <nav className="topbar light persistent border-box">

    <a href="/" className="logo"></a>


<ul>
</ul>

</nav>
  </div>
  </div>
  );
}

export default TopbarLogin;
