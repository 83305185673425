import React from 'react';
import ArchiveMain from './archivemain';
import ChatUtils from './chat_utils';


class PanelArchive extends React.Component {
    constructor(props) {
      super(props);
  //    this.handleTabClick = this.handleTabClick.bind(this);
  //    this.state = {showContent: false};
      this.chatRef = React.createRef();
      this.scrollToBottom = this.scrollToBottom.bind(this);
        
      this.state = {text : ""};
    }
  
    componentDidMount() {
      ////console.log("refreshing");
      // //console.log("chatRef");
      // //console.log(this.chatRef.current);
      // this.scrollToBottom();
    }

    scrollToBottom() {
      //console.log("scrolling");
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }

    render() {
      // //console.log("rendering11", this.props.threadsList);
      var props = this.props;
      
        return (
        //   <div className={this.props.theme == "light" ? "middlemain border-box bgwhite centeralign" : "middlemain border-box bgdark-theme3 centeralign"}>
        //   <div className={this.props.theme == "light" ? "chatArchive shadow-box" : "chatArchive shadow-box dark-theme"} id="messageContainer" ref={this.chatRef}><ArchiveMain {...props} scrollChat={this.scrollToBottom} /></div>
        <div className="chatArchive shadow-box" id="messageContainer" ref={this.chatRef}><ArchiveMain {...props} scrollChat={this.scrollToBottom} /></div>
          
          
          
          
        //   </div>
        );
      

        
    }
  }



  // const WrappedPanelMiddle = React.forwardRef((props, ref)  => {return (<PanelMiddle {...props} forwardedRef = {chatRef}/>) });
  export default PanelArchive;