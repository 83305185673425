import React, {useEffect, useState, useContext } from 'react';
import API from '../../api';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './Voucher.css';
import MultiSelect from "../../custom/MultiSelect";
import firebase from '../../firebase';
import Constants,{databaseFolder} from '../../constants';
import { countryContext } from '../NewRightPannel/NewRightPanel'

const Voucher = (props) => {
    const [vouchers, setVouchers] = useState([]);
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const [remarks, setRemarks] = useState("");
    const { country } = useContext(countryContext)
   
    useEffect(() =>{
        API.getVoucherList(country).then(function (response) {
            if (response.data.status){
                console.log(response,'response');
                setVouchers(response.data.data);
            }else{
                NotificationManager.error('Something went wrong', 'Error', 5000);
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    },[]);

    const onChange = (val) => {
        console.log(val);
        if(val.id){
            setSelectedVoucher(val);
        }
    }
    const handleRemarkChange = (e) => {
        console.log(e.target.value);
        let remark =  e.target.value ? e.target.value : null;
        setRemarks(remark);
    }

    const getExecutives = () => {
        
    }

    const sendVoucher = (e) => {
        let params = {...selectedVoucher,  ...{"mobile" : props.mobile, "remark": remarks}}
        API.sendVoucherCode(params,country).then(function (response) {
            if (response.data.status){
                let message = response.data && response.data.data ? response.data.data.message : 'Voucher Send Successfully';
                let voucherDetails = {...params,  ...{"message" : message}}
                NotificationManager.success(`Voucher Send`, 'Successfully', 5000);
                setSelectedVoucher({});
                setRemarks("");
                props.onSendVoucher(voucherDetails);
                var app = firebase.app();
                var database = app.database(Constants.getDatabaseURL());
                database.ref(databaseFolder()+"/supportchat/"+props.mobile+"/voucher").set(params);
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    }

    return (
        <>
            {props.sentVoucher &&
                <div className="voucher-sent">Voucher has already been sent</div>
            }
            <div className={props.theme == "light" ? "border-box clearfix voucher-heading" : "border-box clearfix prime voucher-heading"}>
                Free Escalation Vouchers
            </div>
            <React.Fragment>
                <MultiSelect
                options={vouchers}
                labelKey="code"
                checkbox_id="code"
                placeholder="Select Coupon Code"
                disabledItems={[]}
                disabled={props.sentVoucher}
                selectedOptions={selectedVoucher}
                onInputClick={() => {getExecutives()}}
                onChange={(value) =>
                    onChange(value)
                }
                />
            </React.Fragment>
            <div className="comments-label"> Comments:</div>

            <div className="border-box centeralign fieldbox double" style={{marginTop: "5px"}}>
                <textarea  disabled={props.sentVoucher} onChange={(e) => handleRemarkChange(e)} value={remarks}   maxLength="50" type="text" name="voucher-remark" placeholder="Enter reason for sending coupon" className="voucher-textarea field border-box small"/>
            </div>
            <div className="messageBox-btn" style={{margin: "10px"}}>
                <button onClick={(e) => sendVoucher(e)} disabled={Object.keys(selectedVoucher).length === 0  || !remarks || props.sentVoucher} >Yes</button>
                <div onClick={() => props.closeBtn()} >No</div>
            </div>
        </>
    )
}

export default Voucher