import React from 'react';
import Constants from "../constants";
import Loader from "../loader";



class Order extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        
    }

    handleClick() {
        console.log("RightXXXXXOrder");
        console.log(this.props.order);
        this.props.onRightOrderClick(this.props.order);
        /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
    }

    render() {
        // console.log("statuses ", this.props.statuses);
        // console.log(this.props.order.order_type, this.props.status_id);

        console.log("statuses22222", this.props.statuses);
        
        var filteredstatuses = this.props.statuses.filter(o => (o.status_type == this.props.order.order_type && o.id == this.props.order.status_id));
                var status = "NA";
                if(filteredstatuses.length > 0){
                    status = filteredstatuses[0].status;
                }
                var update_items = "";
                if(this.props && this.props.order && this.props.order.update_items){
                    for (var i = 0; i < this.props.order.update_items.length; i++){
                        var item = this.props.order.update_items[i];
                        console.log(item);
                        if (i === 0){
                            update_items+=(item.package_name ? item.package_name : item.service_name);
                        }else{
                            update_items+=", "+ (item.package_name ? item.package_name : item.service_name);
                        }
                    }
                }
                if (this.props && this.props.order && this.props.order.update_items && this.props.order.update_items.length == 0){
                    if(this.props.order.initial_problem){
                        var items = this.props.order.initial_problem.split("|");
                        items.forEach(function(item){
                            if(item.startsWith(" Title:")){
                                update_items += item.substring(7, item.length);
                            }
                        });
                    }
                }
        return(
            <div className={this.props.theme == "light" ? "border-box clearfix card bgwhite addboxes commutes leftalign" : "border-box clearfix card bgdark-theme1 addboxes commutes leftalign"} onClick={this.handleClick}>
                                    <div className={this.props.theme == "light" ? "border-box clearfix shadow-box leftalign title weight500 uppercased blackspan7" : "border-box clearfix shadow-box leftalign title weight500 uppercased ddspan"}>
                                        <div className="border-box clearfix left">
                                            <div className="border-box clearfix leftalign weight600">
                                                {this.props.order.order_id}
                                            </div>
                                            <div className="border-box clearfix leftalign weight400 code">
                                            <span style={{textTransform:'none'}}>Placed on</span> {this.props.order.created_at ? (this.props.order.created_at.$date ? Constants.get_DateString2(this.props.order.created_at.$date) : Constants.get_DateString2(this.props.order.created_at)) : ""}
                                            </div>
                                        </div>
                                        <div className="border-box clearfix rightalign right weight700">
                                            <span className="position">
                                                {status}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={this.props.theme == "light" ? "border-box clearfix leftalign details blackspan9" : "border-box clearfix leftalign details bbspan"}>
                                        <div className="border-box clearfix left leftalign">
                                            <table className="stdtable"><tr><td valign="center"><img src={this.props.order.car ? this.props.order.car.car_tbl.model.image_path : ""} width="50" /></td></tr></table>
                                        </div>
                                        <div className="border-box clearfix middle leftalign">
                                            <div>
                                                <table className="stdtable"><tr><td valign="center" className="leftalign"><span style={{textTransform:'none'}}>Reg. No.</span> {this.props.order.car ? this.props.order.car.registration_no : ""}</td></tr></table>
                                            </div>
                                            <div>
                                                <table className="stdtable"><tr><td valign="center" className="leftalign">{this.props.order.car ? this.props.order.car.car_tbl.brand.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.model.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.car_type.name : ""}</td></tr></table>
                                            </div>
                                        </div>
                                        <div className="border-box clearfix right rightalign">
                                            <table className="stdtable" style={{textAlign:"right"}}><tr>
                                                <td valign="center">
                                                    {update_items}
                                                </td>
                                            </tr></table>
                                        </div>
                                    </div>
                                    
                                </div>
        );
    }
}

class PanelRight2 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.showContent2){
            return (
                <React.Fragment />
            );
        }else{
            // console.log(typeof(this.props.content2));
            console.log(typeof(this.props.content2));
            console.log(this.props.content2);
            
            console.log("statuses11111", this.props.statuses);
            
            console.log("content2", this.props.content2);
            // console.log("content2", this.props.content2.length);
            const orders = this.props.content2 ? this.props.content2.map((order) => <Order theme={this.props.theme} order={order} onOrderClick={this.props.onOrderClick} onRightOrderClick={this.props.onRightOrderClick} statuses={this.props.statuses} />) : [];
            if(this.props.showLoader){
                return(
                <React.Fragment>
                <div className={this.props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300" : "border-box clearfix cars ccspan leftalign weight300"}>
                                <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix eespan leftalign weight500"}>Orders</div>
                            </div>
                            <div className={this.props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                                {orders}
                            </div>
                            <Loader fullHeight={false}/>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                <div className={this.props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300" : "border-box clearfix cars ccspan leftalign weight300"}>
                                <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix eespan leftalign weight500"}>Orders</div>
                            </div>
                            <div className={this.props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                                {orders}
                            </div>
                    </React.Fragment>
            );
        }
    }
}


class PanelRight1 extends React.Component {
    constructor(props) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    handleBackClick() {
        this.props.onBackOrderClick();
        /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
    }

    render() {
        // let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);

        if(!this.props.showContent1){
            return (
                <React.Fragment />
            );
        }else if(this.props.showRightOrder){
            var filteredstatuses = this.props.statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
                var status = "NA";
                if(filteredstatuses.length > 0){
                    status = filteredstatuses[0].status;
                }
                // var filteredfleets = this.props.fleets.filter(o => (o.id == this.props.content.fleet_id));
                // var fleet = "NA";
                // if(filteredfleets.length > 0){
                //     fleet = filteredfleets[0].name;
                // }
                var update_items = "";
                for (var i = 0; i < this.props.content.update_items.length; i++){
                    var item = this.props.content.update_items[i];
                    console.log(item);
                    if (i === 0){
                        update_items+=(item.package_name ? item.package_name : item.service_name);
                    }else{
                        update_items+=", "+ (item.package_name ? item.package_name : item.service_name);
                    }
                }
                if (this.props.content.update_items.length == 0){
                    if(this.props.content.initial_problem){
                        var items = this.props.content.initial_problem.split("|");
                        items.forEach(function(item){
                            if(item.startsWith(" Title:")){
                                update_items += item.substring(7, item.length);
                            }
                        });
                    }
                }
            return (
                <React.Fragment>
                    {/*
arrival_mode: 2
delivery_time: "11:00"
fleet_id: 8
initial_problem: null
invoice_number: null
labour_cost: 0
lead_estimate_items: []
lead_id: "L2019109353615"
material_cost: 0
payments: []
update_items: (2) [{…}, {…}]
updated_at: {$date: 1570640488000} */}
                    <div className="border-box clearfix header"><div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}><table className="stdtable"><tr><td valign="center">
                                    <span style={{textTransform:'none'}}>Placed on</span> {this.props.content.created_at ? (this.props.content.created_at.$date ? Constants.get_DateStringX(this.props.content.created_at.$date) : Constants.get_DateStringX(this.props.content.created_at)) : ""}
                                </td></tr></table></div><div className={this.props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}><table className="stdtable"><tr><td valign="center"></td></tr></table></div></div>

                                <div className="border-box clearfix buttons"><div className={this.props.theme == "light" ? "border-box blackspan9 weight500" : "border-box aaspan weight500"}><table className="stdtable"><tr><td valign="center">
                                    <button class={this.props.theme == "light" ? "btn btn_small btncolorprim btncard move btnbackorder" : "btn btn_small btncolorprimdark btncard move btnbackorder"} onClick={this.handleBackClick}>Back</button>
                                </td></tr></table></div>
                                
                                <div className={this.props.theme == "light" ? "border-box blackspan9 weight500 rightalign" : "border-box aaspan weight500 rightalign"}>
                                
                                </div></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix prime blackspan4 leftalign weight700" : "border-box clearfix prime whitespan leftalign weight700"}>{this.props.content.car.car_tbl.brand.name} {this.props.content.car.car_tbl.model.name} {this.props.content.car.car_tbl.car_type.name}</div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name blackspan65 leftalign weight500" : "border-box clearfix name ccspan leftalign weight500"}><span style={{textTransform:'none'}}>Reg. No.: </span><span className="weight700">{this.props.content.car.registration_no}</span></div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Status: </span><span className="weight700">{status}</span></div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Amount: </span><span className="weight700">&#x20B9; {this.props.content.amount}</span></div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Payment status: </span><span className="weight700">{this.props.content.payment_status}</span></div>
                                
                                <div className={this.props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}><div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>Order Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Order ID</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span>
                                {this.props.content.order_id}
                                </span></td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Order Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {Constants.orderTypes()[this.props.content.order_type]}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Discount</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.discount_amount}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>GAM Discount</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.go_app_money_discount}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Tax</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.tax_amount}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Pickup</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.pick_date ? (this.props.content.pick_date.$date ? Constants.get_DateString2(this.props.content.pick_date.$date) : Constants.get_DateString2(this.props.content.pick_date)) : ""}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Delivery</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.delivery_date ? (this.props.content.delivery_date.$date ? Constants.get_DateString2(this.props.content.delivery_date.$date) : Constants.get_DateString2(this.props.content.delivery_date)) : ""}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Estd delivery</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.estimated_delivery ? (this.props.content.estimated_delivery.$date ? Constants.get_DateString2(this.props.content.estimated_delivery.$date) : Constants.get_DateString2(this.props.content.estimated_delivery)) : ""}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Address</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.address}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Location</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content.location}
                                </td></tr></table></div></div>
                                {/* <div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Fleet</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {fleet}
                                </td></tr></table></div></div> */}
                                <div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Order items</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {update_items}
                                {/* </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type} */}
                                </td></tr></table></div></div></div></div></div>
                    </React.Fragment>
            );
        }else{
            return (
                <React.Fragment>
                <div className="border-box clearfix header"><div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}><table className="stdtable"><tr><td valign="center">
                                    <span style={{textTransform:'none'}}>Joined on</span> {this.props.content1.created_at ? (this.props.content1.created_at.$date ? Constants.get_DateStringX(this.props.content1.created_at.$date) : Constants.get_DateStringX(this.props.content1.created_at)) : ""}
                                </td></tr></table></div><div className={this.props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}><table className="stdtable"><tr><td valign="center"></td></tr></table></div></div>

                                <div className="border-box clearfix buttons"><div className={this.props.theme == "light" ? "border-box blackspan9 weight500" : "border-box aaspan weight500"}><table className="stdtable"><tr><td valign="center"></td></tr></table></div>
                                <div className={this.props.theme == "light" ? "border-box blackspan9 weight500 rightalign" : "border-box aaspan weight500 rightalign"}>
                                
                                </div></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix prime blackspan4 leftalign weight700" : "border-box clearfix prime whitspan leftalign weight700"}>{this.props.content1.name}</div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name blackspan65 leftalign weight500" : "border-box clearfix name ccspan leftalign weight500"}><span style={{textTransform:'none'}}>{this.props.content1.mobile}</span></div>
                                <div class={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>{this.props.content1.email}</span></div>
                                
                                <div className={this.props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}><div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>User Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>User ID</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span>
                                {this.props.content1.id}
                                </span></td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Customer Code</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.customer_code}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>GoApp Money</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.go_app_money}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>GoApp Earning</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.go_app_earning}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>OS Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.thread ? this.props.thread.source : ""}
                                </td></tr></table></div></div></div></div></div>
                    </React.Fragment>
            );
        }
    }
}


class PanelRightArchives extends React.Component {
    constructor(props) {
      super(props);
    }

    handleScroll = (e) => {
        console.log("scrolling1");
        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
        console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight)
        if (bottom && this.props.content2 && this.props.content2.length !== 0 && !this.props.showLoader) { console.log("scrolling"); this.props.onRightScroll(this.props.tag, this.props.content2);}
    }

    
  
    render() {
        let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);

        if(!this.props.showContent1 && !this.props.showContent2){
            if(this.props.showLoader){
                return (
                    <Loader theme={this.props.theme} fullHeight={true}/>
                );    
            }else{
                if(this.props.content1 == "no_user"){
                    return(
                        <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"} onScroll={this.handleScroll}>
                            <table className="stdtable"><tr><td valign="center">
                                No existing user.
                            </td></tr></table>
                        </div>
                    );
                }else{
                    return (
                        <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"} onScroll={this.handleScroll}>
                            <table className="stdtable"><tr><td valign="center">
                                Select a thread on the left to view details.
                            </td></tr></table>
                        </div>
                    );
                }
            }
        }else{
            var statuses = JSON.parse(localStorage.getItem("statuses"));
            var fleets = JSON.parse(localStorage.getItem("fleets_summary"));
            // var statuses = JSON.parse(localStorage.getItem("statuses"));
            //             var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
            //             var status = "NA";
            //             if(filteredstatuses.length > 0){
            //                 status = filteredstatuses[0].status;
            //             }
            console.log(this.props.content);

            console.log("RightOrder");
            console.log(this.props.rightOrder);

            if(this.props.rightOrder){
                return (
                    <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"} onScroll={this.handleScroll}>
                        <PanelRight1 theme={this.props.theme} thread = {thread} showContent1 = {true} showRightOrder = {true} content = {this.props.rightOrder} onBackOrderClick={this.props.onBackOrderClick} statuses={statuses} fleets={fleets} />
                        {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                        </td></tr></table></div></div></div></div></div> */}
                    </div>
                );
            }else{
                return (
                    <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"} onScroll={this.handleScroll}>
                        <PanelRight1 theme={this.props.theme} thread = {thread} showContent1 = {this.props.showContent1} content1 = {this.props.content1} showRightOrder = {false} statuses={statuses} fleets={fleets} />
                        <PanelRight2 theme={this.props.theme} showContent2 = {this.props.showContent2} content2 = {this.props.content2} showLoader={this.props.showLoader} onRightOrderClick={this.props.onRightOrderClick} statuses={statuses} fleets={fleets} />
                        {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                        </td></tr></table></div></div></div></div></div> */}
                    </div>
                );
            }
            
            // if (this.props.content2.length > 0){
                
            // }else{
            //     return (
            //         <div id="dashboardchatmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
            //             <PanelRight1 showContent1 = {this.props.showContent1} content1 = {this.props.content1} />
            //             <PanelRight2 showContent2 = {this.props.showContent2} content2 = {this.props.content2} />
            //             {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">                        
            //             </td></tr></table></div></div></div></div></div> */}
            //         </div>
            //     );
            // }
                    
        }
    }
  }

export default PanelRightArchives;