import React from 'react';
import Constants from "../constants";
import Loader from "../loader";
import api from '../api';


class PanelLeftArchives extends React.Component {
    constructor(props) {
      super(props);
    //  this.handleSwitchClick = this.handleSwitchClick.bind(this);
  //    this.state = {showContent: false};
      this.state = {text : ""};
    }
  
    handleScroll = (e) => {
      //console.log("scrolling333");
      // const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
      // //console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight)
      // if(!this.props.live){
      //   if (bottom && !this.props.showLoader) { this.props.onLeftScroll(this.props.live, this.state.mine);}
      // }
    }

    handleTextChange(e){
      // console.log(e.target.value);
      this.setState({text : e.target.value}, () => this.threadSearch());
      // this.setState({text : e.target.value});
      // this.props.onThreadSearch(this.state.text);
      // this.props.onThreadSearch(e.target.value);
      // this.threadSearch();
    }

    _handleKeyDown = (e) => {
      console.log("keyDown");
      if (e.key === 'Enter') {
        e.preventDefault();
        var message = this.state.text;
        if(message.trim() != ""){
          // this.sendieRef.current.value = "";
          // this.setState({text : ""});
          this.props.onThreadSearch(message.trim());
        } 
      }
    }

    threadSearch(){
      this.props.onThreadSearch(this.state.text);
    }
  
    render() {
      console.log("threadsList", this.props.threadsList);
      var that = this;
      var tabs = [];

      //console.log("props", this.props);
      
      tabs = this.props.threadsList.map((tab) =>
              <Tab theme={this.props.theme} keyX={tab.key} tag={tab.id} name={tab.name} last_message = {tab.last_message ? (tab.last_message.length > 30 ? tab.last_message.substring(0,30) + " ...": tab.last_message) : ""} 
                last_messagetime = {tab.last_messagetime ? Constants.get_DateString1(tab.last_messagetime) : ""} last_time={tab.last_time} mobile={tab.mobile} last_updated = {tab.last_updated} source={tab.source}
                first_executive_message_time={tab.first_executive_message_time} first_customer_message_time={tab.first_customer_message_time}
                models={tab.id in this.props.allModels ? this.props.allModels[tab.id] : {}} onTabClick={this.props.onTabClick} selectedTab={this.props.selectedTab} is_typing_customer={tab.is_typing_customer} assignedExecName={tab.assigned_executive_name}/>
            );

      //console.log("tabsLenth", tabs);
  
      // if(tabs.length == 0 && ((!this.props.allModels && this.props.live) || (!this.props.archivedAllModels && !this.props.live))){
      if(false){
        return(
          <div className={this.props.theme=="light" ? "leftmain border-box leftalign relative shadow-box" : "leftmain border-box leftalign relative shadow-box dark-theme"}>
            
            <div className={this.props.theme == "light" ? "second1 border-box leftalign blackspan7 weight400 absolute" : "second1 border-box leftalign ddspan weight400 absolute"}>
              <table className="stdtable"><tr><td valign="center"><Loader fullHeight={false} /></td></tr></table>
            </div>
          </div>
        );
      }else{
      return (
        <div className={this.props.theme == "light" ? "leftmain border-box leftalign relative shadow-box" : "leftmain dark-theme border-box leftalign relative shadow-box"}>
          {this.props.keyArchives ? (<div className="first border-box leftalign clearfix absolute">
              <input id="searchthreads" type="text" name="search" placeholder="Search anything (no, really)" className="field small border-box" value={this.state.text} onChange={(e) => this.handleTextChange(e)} onKeyDown={this._handleKeyDown} />
            </div>) : (<React.Fragment />)}
          
            {/* <div className="second border-box leftalign clearfix absolute">
              <div className="choicethreads border-box centeralign floatleft uppercased weight700">
                <div className={this.state.mine ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "mine")}>Mine</div>
                <div className={this.state.mine ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "all")}>All</div>  
              </div>

              {this.props.live ? (<div className="choicethreads border-box centeralign floatright uppercased weight700">
                <div className={this.state.status == "all" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("status", "all")}>All</div>
                <div className={this.state.status == "unread" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("status", "unread")}>Unread</div>  
              </div>) : (<React.Fragment />)}
            </div> */}
          <div className={this.props.theme == "light" ? "second1 border-box leftalign blackspan7 weight400 absolute" : "second1 border-box leftalign ddspan weight400 absolute"} onScroll={this.handleScroll}>
            {tabs}
            {this.props.showLeftLoader ? (<Loader fullHeight={false}/>) : (<React.Fragment />)}
          </div>
        </div>
        );
      }
    }
  }


  class Tab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {showContent: false};
  
      this.handleClick = this.handleClick.bind(this);
    }
  
      handleClick() {
        console.log("clickKeyX", this.props.tag, this.props.keyX);
          this.props.onTabClick(this.props.tag, this.props.keyX);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
    render() {
      // console.log("props", this.props);
      //console.log("props1", this.props);

      console.log("tag", this.props.tag, this.props.selectedTab, this.props.key);

      var response_time = null;
      if(this.props.first_executive_message_time && this.props.first_customer_message_time)
        response_time = Constants.getTimeDifferenceFromMill(this.props.first_executive_message_time - this.props.first_customer_message_time);

      if (this.props.selectedTab == this.props.tag){
        return (
          // var innerHTML1 = '<div class="code">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center" style="width:80%;">';
  //                 innerHTML1 += derbii_title;
  //                 innerHTML1 += '</td><td valign="center" style="width:20%;" class="rightalign"><span class="unread weight700 colorprim500span"></span></td></tr></table>';
  //                 innerHTML1 += '</div>';
  //                 innerHTML1 += '<div class="people">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center">';
  //                 innerHTML1 += text;
  //                 innerHTML1 += '</td></tr></table>';
  //                 innerHTML1 += '</div>';
  
  //                 var new_d = document.createElement('div');
  
  
          <div id={this.props.id} style={{height : "60px"}}
            className={this.props.unread != 0 ? (this.props.theme == "light" ? "thread selected border-box clearfix colorprim500span" : "thread selected border-box clearfix colorprimdark500span" ) : "thread selected border-box clearfix"} 
            data-id={this.props.id} onClick={this.handleClick}>
            <div className="code">
              <table className="stdtable"><tr><td valign="center" style={{width:'60%', fontSize : '.8rem'}}>
                {Constants.get_DateString3(this.props.last_updated)}
              </td>
              <td valign="center" className="rightalign" style={{width:'40%', fontSize : '.7rem'}}>{this.props.source ? this.props.source : "App"}</td></tr></table>
              </div>
              
              <div className="msg">
              <table className="stdtable"><tr><td valign="center">
              <span className="msg weight300i">{this.props.last_message}</span>
                </td>
                <td valign="center" className="rightalign" style={{width:'40%'}}>
                  {/* <span className="msg weight300i">{this.props.last_messagetime}</span> */}
                  <span className="msg weight300i">{this.props.assignedExecName}</span>
                </td>
                </tr></table>
              </div>
          </div>
  
            // <div className={this.props.selectedTab===this.props.tag ? "tabs bgwhite border-box shadow-text selected" : "tabs bgwhite border-box shadow-text"} 
            //     data-type={this.props.tag} onClick={this.handleClick}>
            //     <table className="stdtable"><tr><td valign="center">
            //         {this.props.title}
            //     </td></tr></table>
            // </div>
        );  
      }else{
        //console.log(this.props, "hello");
        return (
          // var innerHTML1 = '<div class="code">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center" style="width:80%;">';
  //                 innerHTML1 += derbii_title;
  //                 innerHTML1 += '</td><td valign="center" style="width:20%;" class="rightalign"><span class="unread weight700 colorprim500span"></span></td></tr></table>';
  //                 innerHTML1 += '</div>';
  //                 innerHTML1 += '<div class="people">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center">';
  //                 innerHTML1 += text;
  //                 innerHTML1 += '</td></tr></table>';
  //                 innerHTML1 += '</div>';
  
  //                 var new_d = document.createElement('div');
  
  
          <div id={this.props.id} style={{height : "60px"}} className={this.props.unread && this.props.unread != 0 ? (this.props.theme == "light" ? "thread border-box clearfix colorprim500span" : "thread border-box clearfix colorprimdark500span") : "thread border-box clearfix"} 
            data-id={this.props.id} onClick={this.handleClick}>
            <div className="code">
              <table className="stdtable"><tr><td valign="center" style={{width:'60%', fontSize : '.8rem'}}>
                {Constants.get_DateString3(this.props.last_updated)}
                 {/* - {this.props.tag} */}
              </td>
              <td valign="center" className="rightalign" style={{width:'40%', fontSize : '.7rem'}}>{this.props.source ? this.props.source : "App"}</td></tr></table>
              </div>
              
              <div className="msg">
              
                {this.props.is_typing_customer ? (<table className="stdtable"><tr><td valign="center"><span className={this.props.theme == "light" ? "msg weight700i colorprim500span" : "msg weight700i colorprimdark500span"}>Typing ...</span></td></tr></table>) : 
                  (<table className="stdtable"><tr><td valign="center"><span className="msg weight300i">{typeof this.props.last_message === 'string' ? this.props.last_message : ""}</span></td><td valign="center" className="rightalign" style={{width:'40%'}}>
                    {/* <span className="msg weight300i">{this.props.last_messagetime}</span> */}
                    <span className="msg weight300i">{this.props.assignedExecName}</span>
                    </td></tr></table>)}
              
              </div>
          </div>
  
            // <div className={this.props.selectedTab===this.props.tag ? "tabs bgwhite border-box shadow-text selected" : "tabs bgwhite border-box shadow-text"} 
            //     data-type={this.props.tag} onClick={this.handleClick}>
            //     <table className="stdtable"><tr><td valign="center">
            //         {this.props.title}
            //     </td></tr></table>
            // </div>
        );
      }
      
    }
  }

  export default PanelLeftArchives;