import React from 'react';
import './dashboard/dashboard2chat.css'

const Skelton = () => {
return  <>
        <div className="body-line-sk-l skel-linear-background"></div>
        <div className="body-line-sk-l skel-linear-background"></div>
        <div className="body-line-sk-l skel-linear-background"></div>
        <div className="body-line-sk-l skel-linear-background"></div>
        <div className="body-line-sk-l skel-linear-background"></div>
    </>
}
export default Skelton