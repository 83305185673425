const nodeBaseUrl = "https://nodeapi.gomechanic.in/api/"

export const nodeGet = (url) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const options = {
        method: "GET",
        headers: headers,
    }
    return fetch((`${nodeBaseUrl}${url}`), options).then(res => res.json(), err => err);
}

export const nodePut = (url, data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
    }
    return fetch((`${nodeBaseUrl}${url}`), options).then(res => res.json(), err => err);
}

export const loadScript = (src, position, id) => {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    //script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'utf-8');
    script.src = src;
    position.appendChild(script);
}

export const getLatestKeyFromNode = () => {
    nodeGet(`map-keys/get-key`)
        .then((data) => {
            if (data && data.key) {
                mapCheckerOnHTML(data.key, false);
                localStorage.setItem('map_key', JSON.stringify({key: data.key, id: data.id}));
            } else {
                mapCheckerOnHTML('AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY', false);
                localStorage.setItem('map_key', JSON.stringify({
                    key: 'AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY',
                    id: -1
                }));
            }
        }).catch((error) => {
        console.log(error);
        mapCheckerOnHTML('AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY');
        localStorage.setItem('map_key', JSON.stringify({
            key: 'AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY',
            id: -1
        }));
    });
}

export const mapCheckerOnHTML = (key, forceChange) => {
    // let countryCode = localStorage.getItem("country_code") || "IN";
    // if(countryCode === "MY"){
    //     key = "AIzaSyCdQKrqUZrCUPDBOXm4-2qVL0sIsdVzzUQ"
    // }
    if (typeof window !== 'undefined') {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en-US&region=us`,
                document.querySelector('head'),
                'google-maps',
            );
        }
    }
}

export const updateMapKeyStatus = (keyId) => {
    let obj = {id : keyId};
    nodePut(`map-keys/update-key`, obj)
        .then((response) => {
            if (response && response.key) {
                console.log(response);
                let element = document.getElementById("google-maps");
                element.parentNode.removeChild(element);
                mapCheckerOnHTML(response.key, true);
                localStorage.setItem('map_key', JSON.stringify({key: response.key, id: response.id}));
            }
        }).catch((error) => {
        console.log(error);
        mapCheckerOnHTML('AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY', true);
        localStorage.setItem('map_key', JSON.stringify({
            key: 'AIzaSyCmWfNtLWCNqWWsE1LMrU8WQwMz1gpzPMY',
            id: -1
        }));
    });
}
