import React,{useContext} from 'react'
import './Timeline.css'
import Constants,{ getCurrencySymbol  } from '../../constants';
import { countryContext } from '../NewRightPannel/NewRightPanel';

const SparesTimeline = (props) => {

    const { country } = useContext(countryContext)

    return (
        <>
        <div className='leftalign timeline-header'>
            ORDER LINES
        </div>
        {Object.keys(props.content.order_lines).map(function (key,index) {
            return <div key={index}className={props.theme == "light" ? "border-box clearfix card bgwhite addboxes commutes leftalign orderMain blackspan7 product-detail" : "border-box clearfix card bgdark-theme1 addboxes commutes leftalign orderMain ddspan product-detail"}>
                <div className={props.theme == "light" ? "flexBox border-box clearfix leftalign title weight500 uppercased blackspan7 titleBottom" : "flexBox border-box clearfix shadow-box leftalign title weight500 uppercased titleBottom"}>
                    <div className="border-box clearfix left">
                        <div className="border-box clearfix leftalign weight600">
                            {key}
                        </div>
                        <div className="border-box clearfix leftalign weight400 code">
                            <span style={{ textTransform: 'none' }}>Placed on</span> {props.content.created_at ? (Constants.get_DateString2(props.content.created_at.$date)) : ""}
                        </div>
                    </div>
                    <div className="border-box clearfix rightalign right weight700 orderBoxStatus">
                        <span className="position">
                            {props.content.status_id}
                        </span>
                    </div>
                </div>
                <div className={props.theme == "light" ? "border-box clearfix leftalign details blackspan9 orderSecondBox" : "border-box clearfix leftalign details bbspan orderSecondBox"}>
                        <div className="orderMiddle">
                            <img className="car-logo" src={ props.content.order_lines[key].product_image } width="50" />
                            <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                                <div className={props.theme == "light" ? "regNo" : "regNo1"}> {props.content.order_lines[key].sku_code} </div>
                                <div className={props.theme == "light" ? "carName" : "carName1"}>{props.content.order_lines[key].sku_brand} {props.content.order_lines[key].product_title}</div>
                            </div>
                        </div>
                    <div className="orderDates spares-date">
                        <div>
                            <div className="orderDetailItem1">Expected Delivery Date</div>
                        </div>
                        <div className="orderDetailItem3">
                            <div className="orderDetailItem2">{props.content.order_lines[key].expected_delivery_date?
                            Constants.get_DateString2(props.content.order_lines[key].expected_delivery_date.$date):"NA"}</div>
                        </div>
                    </div>
                    <div className="orderDates spares-date">
                        <div>
                            <div className="orderDetailItem1">Dispatched Date</div>
                        </div>
                        <div className="orderDetailItem3 spares-date">
                            <div className="orderDetailItem2">{props.content.order_lines[key].dispatched_datetime?
                            Constants.get_DateString2(props.content.order_lines[key].dispatched_datetime.$date):"NA"}</div>
                        </div>
                    </div>
                    <div className="orderDates spares-date">
                        <div>
                            <div className="orderDetailItem1">Delivered Date</div>
                        </div>
                        <div className="orderDetailItem3">
                            <div className="orderDetailItem2">{props.content.order_lines[key].delivered_datetime?
                            Constants.get_DateString2(props.content.order_lines[key].delivered_datetime.$date):"NA"}</div>
                        </div>
                    </div>                    
                </div>
                <div style={{ borderTop: "1px solid #dddddd" }} className={props.theme == "light" ? "border-box clearfix details orderSecondBox" : "border-box clearfix details orderSecondBox"}>
                  
                    <div className="orderDetailInner">
                        <div className="orderDetailItem1">
                            Amount:
                        </div>
                        <div className="orderDetailItem2">
                        {getCurrencySymbol(country)} {props.content.order_lines[key].price ? props.content.order_lines[key].price : "NA"}
                        </div>
                    </div>
                    <div className="orderDetailInner">
                        <div className="orderDetailItem1">
                            Quantity:
                        </div>
                        <div className="orderDetailItem2">
                            {props.content.order_lines[key].quantity ? props.content.order_lines[key].quantity : "NA"}
                        </div>
                    </div>
                    <div className="orderDetailInner">
                        <div className="orderDetailItem1">
                        Gst amount/Quantity:
                        </div>
                        <div className="orderDetailItem2">
                        {getCurrencySymbol(country)} {props.content.order_lines[key].gst_amount_per_quantity ? props.content.order_lines[key].gst_amount_per_quantity.toFixed(2) : "NA"}
                        </div>
                    </div>
                    <div className="orderDetailInner">
                        <div className="orderDetailItem1">
                         Category Name:
                        </div>
                        <div className="orderDetailItem2">
                            {props.content.order_lines[key].category_name ? props.content.order_lines[key].category_name : "NA"}
                        </div>
                    </div>
                </div>
            </div>
        })}
        </>
    )
}
export default SparesTimeline