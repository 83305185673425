import React from 'react';
import "../dashboard/dashboard.css"
import  "../dashboard/dashboard2chat.css"
import $ from 'jquery';

import PanelArchive from './archivepanel';
import Loader from '../loader';
import Topbar from '../topbar';

import API from '../api';
import Constants from '../constants';
import {Redirect} from 'react-router-dom';

import firebase from '../firebase';
import ChatUtils from './chat_utils';
import ChatUtilsArchive from './chat_utils_archive';
import LogoutMain from '../LogoutMain';
import '../notifications';
import SupportChatArchives from './support_chat_archives';

// var firebase = require('firebase/app');
// require('firebase/auth');
// require('firebase/database');
// require('firebase/messaging');

const axios = require('axios');



const tabList = [{tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];





class SupportChatArchivesByKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		status : "logged in",
      middleContent: NaN,
      showMiddleContent: false,
      showLoader : false,
      showLeftLoader : false,
      searchText : NaN,
		threads_list : [],
		divK : [],
		divM : [],
		divH : [],
    all_models : {},
    archived_threads_list : [],
		archived_divK : [],
		archived_divM : [],
		archived_divH : [],
    archived_all_models : {},
    live: true,
    is_online : true,
    keyArchives : NaN,
    archivesFound : 0,
    currentView : "live"
    };

  }

  

  componentWillReceiveProps(){
    
  }

  componentWillMount() {
    var that = this;
      
        API.getSupportChatArchivesByMobile(that.props.mobile, that.props.userAccess, that.props.country).then(function (response) {
          console.log(response.data);
          if (response.data.data.chats == null || response.data.data.chats.length == 0){
            // that.setState({keyArchives: response.data.data, showLoader : false, archivesFound : 2});
            ChatUtils.loadChatArchieveMain(that, that.props.mobile, response.data.data, false); 
          }else{
            // that.setState({keyArchives: response.data.data, showLoader : false, archivesFound : 1});
            ChatUtils.loadChatArchieveMain(that, that.props.mobile, response.data.data, false); 
            
            // that.setState({keyArchives: arr, showLoader : false, archivesFound : 1});
          }
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
          .finally(function () {
          // always executed
          });
      
    
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    console.log("UnMOunting");
  }

  render() {
    console.log("key", this.props.mobile);
      // if((localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1) || localStorage.getItem("phone") == "9990903321"){
        if(this.state.archivesFound != 2){
        return(
          <div id="containermain1" className="bgwhite">
            <div id="containermainouter1archives">
              <PanelArchive theme={this.props.theme} 
                keyArchives = {this.state.keyArchives} />
              {/* <PanelMiddle theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedThreadsList = {this.state.archived_threads_list}
                 archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models}
                archivedDivK={this.state.archived_divK} archivedDivM={this.state.archived_divM} archivedDivH={this.state.archived_divH} keyArchives = {this.state.keyArchives}
                divK={this.state.divK} divM={this.state.divM} divH={this.state.divH}/> */}

              {/* <div className={this.props.theme == "light" ? "chat shadow-box" : "chat shadow-box dark-theme"} id="messageContainer" ref={this.chatRef}><MiddleMain {...props} scrollChat={this.scrollToBottom} /></div> */}

            </div>
          </div>
        );
      }else{
        return (
          <div id="containermain1" className="bgwhite">
            <div id="containermainouter1archives">
              <table className="stdtable"><tr><td valign="true" className="weight700" style={{fontSize : "1.5rem"}}>No chats found.</td></tr></table>
            </div>
          </div>
        );
      }
	  
  }
}


export default SupportChatArchivesByKey;
