import React from 'react';
import Constants,{databaseFolder} from "../constants";
import Loader from "../loader";
import api from '../api';
import MultiSelect from "../custom/MultiSelect";
import firebase from '../firebase';

class PanelTopBarArchives extends React.Component {
    constructor(props) {
      super(props);
     this.handleSwitchClick = this.handleSwitchClick.bind(this);
     this.handleExecutiveChange = this.handleExecutiveChange.bind(this);  
     this.handleArchieveSearch = this.handleArchieveSearch.bind(this)
     this.handleKeyDownSearch = this.handleKeyDownSearch.bind(this)
  //    this.state = {showContent: false};
      this.state = {text : "",executives:[],selectedSearchby:{}};
    }

    componentWillMount() {
      var that =this
      var app = firebase.app();
      var database = app.database(Constants.getDatabaseURL());
      var seRef = database.ref(databaseFolder()+'/executives');
  
      // if(executives.length == 0){
          let listener1 = seRef.once('value').then(function(data) {
              // console.log("quick",data);
              let exec = [];
              data.forEach(function(childNode){
                  if(childNode.val().name){
                      let temp = {};
                      temp['id'] = childNode.key;
                      temp['name'] = childNode.val().name;
                      temp['is_online'] = childNode.val().is_online;
                      exec.push(temp);
                  }
              })
              that.setState({executives:exec})
          });
    }

    handleSwitchClick(type, mSwitch){
      //console.log("hahahahahaha");
      //console.log(this.props.live);
      //console.log(this.state);
      //console.log(type, mSwitch);
      this.props.onTopbarSwitchClick(type, mSwitch);
    }
  
    handleScroll = (e) => {
      //console.log("scrolling333");
      // const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
      // //console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight)
      // if(!this.props.live){
      //   if (bottom && !this.props.showLoader) { this.props.onLeftScroll(this.props.live, this.state.mine);}
      // }
    }

    handleTextChange(e){
      // console.log(e.target.value);
      // this.setState({text : e.target.value}, () => this.threadSearch());
      this.setState({text : e.target.value});
      // this.props.onThreadSearch(this.state.text);
      // this.props.onThreadSearch(e.target.value);
      // this.threadSearch();
    }

    handleExecutiveChange(value){
      this.setState({selectedExecutive:value})
    }

    handleArchieveSearch(){
      this.state.selectedExecutive && this.state.selectedExecutive.id && this.props.onExecutiveArchiveSearch(this.state.selectedExecutive.id,this.props.country)
    }

    handleKeyDownSearch (){
        var message = this.state.text;
        if(message.trim() != ""){
          this.props.onArchiveSearch(message.trim(),this.props.country);
        } 
    }

    // threadSearch(){
    //   this.props.onThreadSearch(this.state.text);
    // }
  
    render() {
      console.log("threadsList", this.props.threadsList);
      const countryOptions = [{id:'1',name:'India'},{id:'2',name:'Malaysia'}]
      const searchOptions = [{id:'1',name:'Customer Number'},{id:'2',name:'Executive Name'}]
      var that = this;
      return (
        <div className={this.props.theme == "light" ? "chatarchivetopbar border-box leftalign relative shadow-box" : "chatarchivetopbar dark-theme border-box leftalign relative shadow-box"}>
          <div className="first border-box leftalign floatleft archiveTop">
             <div className='multiselect-country'>
             <MultiSelect
                  options={countryOptions}
                  labelKey="name"
                  checkbox_id="name"
                  placeholder="Select the Country"
                  disabledItems={[]}
                  selectedOptions={this.props.country}
                  onInputClick={() => {}}
                  onChange={(value) =>this.props.updateCountry(value)}
             />
             </div>
             <div className='multiselect-country'>
             <MultiSelect
                options={searchOptions}
                labelKey="name"
                checkbox_id="name"
                placeholder="Select search type"
                disabledItems={[]}
                selectedOptions={this.state.selectedSearchby}
                onInputClick={() => {}}
                onChange={(value) =>{
                    this.setState({selectedSearchby:value, selectedExecutive:{}})
                }}
                />
             </div>
              {this.state.selectedSearchby && this.state.selectedSearchby.name==='Customer Number' && 
              <>
              <input id="searcharchives" type="text" name="search" placeholder="Search by phone number" className="field small border-box" value={this.state.text} onChange={(e) => this.handleTextChange(e)}  />
              <button className={this.props.theme == "light" ? "btn btn_small btncolorprim btncard move btnbackorder" : "btn btn_small btncolorprimdark btncard move btnbackorder"} onClick={()=>this.handleKeyDownSearch()}>Search</button>
              </>
              }
              {this.state.selectedSearchby && this.state.selectedSearchby.name==='Executive Name' && 
              <>
              <div className='executive-dropdown'>
              <MultiSelect
                options={this.state.executives}
                labelKey="name"
                checkbox_id="name"
                placeholder="Search Executive"
                disabledItems={[]}
                selectedOptions={this.state.selectedExecutive}
                onInputClick={() => {}}
                onChange={(value) =>
                    this.handleExecutiveChange(value)
                }
                />
              </div>
              <button className={this.props.theme == "light" ? "btn btn_small btncolorprim btncard move btnbackorder" : "btn btn_small btncolorprimdark btncard move btnbackorder"} onClick={()=>this.handleArchieveSearch()}>Search</button>
              </>
              }
            </div>

            {this.props.keyArchives ? (
              <React.Fragment>
              <div className={this.props.theme == "light" ? "second border-box leftalign floatleft weight700 blackspan7" : "second border-box leftalign floatleft dark-theme weight700 whitespan"}>
                {this.props.keyArchives.chats ? this.props.keyArchives.chats.length+" threads" : "No threads"}
              </div>
              <div className={this.props.theme == "light" ? "third border-box leftalign floatleft" : "third border-box leftalign floatleft dark-theme"}>
              <div className="choicethreads border-box centeralign floatleft uppercased weight700">
                <div className={this.props.threadsStatus == "mine" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "mine")}>Mine</div>
                <div className={this.props.threadsStatus == "mine" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "all")}>All</div>  
              </div>

              <div className="choicethreads border-box centeralign floatleft uppercased weight700" style={{marginLeft : "30px"}}>
                <div className={this.props.timeline == "all" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("timeline", "all")}>All</div>
                <div className={this.props.timeline != "today" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                  onClick = {(e) => this.handleSwitchClick("timeline", "today")}>Today</div>
                <div className={this.props.timeline != "yesterday" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                onClick = {(e) => this.handleSwitchClick("timeline", "yesterday")}>Yesterday</div>
                <div className={this.props.timeline != "lastweek" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                onClick = {(e) => this.handleSwitchClick("timeline", "lastweek")}>Last week</div>
                <div className={this.props.timeline != "lastmonth" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                onClick = {(e) => this.handleSwitchClick("timeline", "lastmonth")}>Last month</div>
                <div className={this.props.timeline != "last6months" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                onClick = {(e) => this.handleSwitchClick("timeline", "last6months")}>Last 6 months</div>
                <div className={this.props.timeline != "lastyear" ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                onClick = {(e) => this.handleSwitchClick("timeline", "lastyear")}>Last year</div>
              </div>

              {/* <div className="choicethreads border-box centeralign floatright uppercased weight700">
                <div className={this.state.status == "all" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("status", "all")}>All</div>
                <div className={this.state.status == "unread" ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("status", "unread")}>Unread</div>  
              </div> */}
            </div>
            </React.Fragment>
            ) : (<React.Fragment />)}
            
            
          {/* <div className={this.props.theme == "light" ? "second border-box leftalign blackspan7 weight400 floatleft" : "second border-box leftalign ddspan weight400 floatleft"}>
            
          </div> */}
        </div>
        );
    }
  }

  export default PanelTopBarArchives;