import React from 'react';
import Constants,{ URLS, getToken } from './constants';
import ChatUtilsArchive from './support_chat/chat_utils_archive';
import { ENGINE_METHOD_NONE } from 'constants';

const axios = require('axios');
const user_access = (sessionStorage.getItem("user_access")!="undefined" &&  sessionStorage.getItem("user_access")!=undefined)? sessionStorage.getItem("user_access") : 'retail'

function getConfig(token){
    return {headers: {'Authorization': "Bearer " + token}};   
}

function getConfigWithParams(token, params){
    return {headers: {'Authorization': "Bearer " + token}, params : params};   
}

class API{

    tabClickCall(tag, that){
        switch(tag){
            case 'orders':
                    axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_orders, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                    console.log(response.data);
                    //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                    for (var i = 0; i < response.data.data.length; i++) {
                        // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                        if(response.data.data[i].pick_date)
                            response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        if(response.data.data[i].delivery_date)
                            response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        if(response.data.data[i].estimated_delivery)
                            response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                      }
                    that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                    })
                    .catch(function (error) {
                    // handle error
                    console.log(error);
                    })
                    .finally(function () {
                    // always executed
                    });
                    break;
             case 'fleets':
                            axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_fleets, getConfig(localStorage.getItem("access_token")))
                            .then(function (response) {
                            console.log(response.data);
                            that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                            })
                            .catch(function (error) {
                            // handle error
                            console.log(error);
                            })
                            .finally(function () {
                            // always executed
                            });
                            break;
            case 'garages':
                                    axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_garages, getConfig(localStorage.getItem("access_token")))
                                    .then(function (response) {
                                    console.log(response.data);
                                    that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                                    })
                                    .catch(function (error) {
                                    // handle error
                                    console.log(error);
                                    })
                                    .finally(function () {
                                    // always executed
                                    });
                                    break;
            default:
                break;
        }
    }



    tabSearchCall(tag, that, args){
        switch(tag){
            case 'orders':
                    //delete args['fleets'];delete args['garages'];
                    axios.post(Constants.getBaseURL()+URLS.endpoints[user_access].search_orders,
                        args, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                    console.log(response.data);
                    //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                    for (var i = 0; i < response.data.data.length; i++) {
                        console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                        if(response.data.data[i].pick_date)
                            response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        if(response.data.data[i].delivery_date)
                            response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        if(response.data.data[i].estimated_delivery)
                            response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                      }
                    that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                    })
                    .catch(function (error) {
                    // handle error
                    console.log(error);
                    })
                    .finally(function () {
                    // always executed
                    });
                    break;
             case 'fleets':
                            axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_fleets, getConfig(localStorage.getItem("access_token")))
                            .then(function (response) {
                            console.log(response.data);
                            that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                            })
                            .catch(function (error) {
                            // handle error
                            console.log(error);
                            })
                            .finally(function () {
                            // always executed
                            });
                            break;
            case 'garages':
                                    axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_garages, getConfig(localStorage.getItem("access_token")))
                                    .then(function (response) {
                                    console.log(response.data);
                                    that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
                                    })
                                    .catch(function (error) {
                                    // handle error
                                    console.log(error);
                                    })
                                    .finally(function () {
                                    // always executed
                                    });
                                    break;
            default:
                break;
        }
    }


    getStatuses(config1 = null){
        if (config1 != null){
            return axios.get(Constants.getBaseURLOther()+URLS.endpoints[user_access].get_order_statuses, config1)
        }else{
            return axios.get(Constants.getBaseURLOther()+URLS.endpoints[user_access].get_order_statuses, getConfig(localStorage.getItem("access_token")))
        }
        
    }
    get_fleets_summary1(config1 = null){
        if (config1 != null){
            return axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_fleets_summary, config1)
        }else{
            return axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_fleets_summary, getConfig(localStorage.getItem("access_token")))
        }
        
    }
    get_garages_summary1(config1 = null){
        if (config1 != null){
            return axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_garages_summary, config1)
        }else{
            return axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_garages_summary, getConfig(localStorage.getItem("access_token")))
        }
    }

    sendOTP(phone){
        return axios.post(Constants.getBaseURL()+URLS.endpoints[user_access].send_otp, {
            number: phone,
            source: "support"
          })
    }
    verifyLogin(phone, otp){
        return axios.post(Constants.getBaseURL()+URLS.endpoints[user_access].verify_login, {
            number: phone,
            otp: otp,
            session_key : sessionStorage.getItem("session_key"),
            source : "support"
          })
    }
    logout(phone, otp){
        console.log("access_token");
        console.log(getConfig(localStorage.getItem("access_token")));
        return axios.post(Constants.getBaseURL()+URLS.endpoints[user_access].logout, {
            
          }, getConfig(localStorage.getItem("access_token")))
    }

    get_fleets_summary(){
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_fleets_summary, getConfig(localStorage.getItem("access_token")))
            .then(function (response) {
                console.log("fleeting");
            console.log(response.data);
            Constants.setFleetsSummary(response.data.data);
            })
            .catch(function (error) {
            // handle error
            console.log(error);
            })
            .finally(function () {
            // always executed
            });
    }

    get_garages_summary(){
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_garages_summary, getConfig(localStorage.getItem("access_token")))
            .then(function (response) {
            console.log(response.data);
            Constants.setGaragesSummary(response.data.data);
            })
            .catch(function (error) {
            // handle error
            console.log(error);
            })
            .finally(function () {
            // always executed
            });
    }

    getChatUserDetailsRight(tag,country){
       return  axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_user_profile_chat, 
       getConfigWithParams(localStorage.getItem("access_token"), {user_id : tag, country: country}))
    }    

    getChatUserDetails(tag, that, country){
        console.log("chatuser", tag);
        that.setState({showLoader : true, showRightContent1 : false, showRightContent2 : false, rightContent1 : NaN, rightContent2 : NaN, showRefundPopUp: false, paidAmount: 0});
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_user_profile_chat, getConfigWithParams(localStorage.getItem("access_token"), {user_id : tag, country}))
                    .then(function (response) {
                    console.log(response.data);
                    //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                    // for (var i = 0; i < response.data.data.length; i++) {
                    //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                    //     if(response.data.data[i].pick_date)
                    //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].delivery_date)
                    //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].estimated_delivery)
                    //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //   }
                    if(response.data.status)
                        that.setState({showRightContent1 : true, rightContent1: response.data.data, showLoader : false});
                    else
                        that.setState({showRightContent1 : false, rightContent1: "no_user", showLoader : false});
                    })
                    .catch(function (error) {
                    // handle error
                    console.log(error);
                    that.setState({showLoader : false});
                    })
                    .finally(function () {
                    // always executed
                    });
    }

    getOrderDetailsPaginateRight(tag,data,country){
        return  axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].order_paginate, 
        getConfigWithParams(localStorage.getItem("access_token"), {user_id : tag, limit : 5, offset : data.length, country:country}))
    }

    getOrderDetailsPaginate(tag, data, that, country='india'){
        if(data.length != 0){
            that.setState({showLoader : true});
            //that.setState({showLoader : true, showRightContent1 : false, showRightContent2 : false, rightContent1 : NaN, rightContent2 : NaN});
        }
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].order_paginate, getConfigWithParams(localStorage.getItem("access_token"), {user_id : tag, limit : 5, offset : data.length, country :country}))
                    .then(function (response) {
                    console.log(response.data);
                    //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                    // for (var i = 0; i < response.data.data.length; i++) {
                    //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                    //     if(response.data.data[i].pick_date)
                    //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].delivery_date)
                    //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].estimated_delivery)
                    //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //   }
                    if (response.data.status){
                        if(data.length == 0){
                            console.log('rightContent2',response.data.data);
                            let rightContent2 = response.data.data;
                            let paid_amount = 0;
                            if(rightContent2.length > 0){
                                let order = rightContent2[0].payments;
                                paid_amount = order && order.length > 0 && order.reduce((acc, val) => acc + (parseInt(val['transaction_amount'] )|| 0),0);
                            }
                            that.setState({showRightContent2 : true, rightContent2: rightContent2, showLoader : false, paidAmount: paid_amount});
                        }else{
                            if(response.data.data.length == 0)
                                that.setState({showLoader : false});
                            else
                                that.setState({showRightContent2 : true, rightContent2: data.concat(response.data.data), showLoader : false});
                        }
                    }else{
                        that.setState({showLoader : false, showRightContent2 : false, rightContent2: NaN});
                    }
                    })
                    .catch(function (error) {
                    // handle error
                    console.log(error);
                    // that.setState({showLoader : false});
                    })
                    .finally(function () {
                    // always executed
                    });
    }

    getSupportChatArchives(supportchat, offset, assigned_executive, that){
        if(offset != 0){
            that.setState({showLeftLoader : true});
        }
        var params = {};
        if(assigned_executive){
            params = {assigned_executive: localStorage.getItem("user_id"), limit : 15, offset : offset}
        }else{
            params = {limit : 15, offset : offset}
        }
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_chat_archive, getConfigWithParams(localStorage.getItem("access_token"), params))
                    .then(function (response) {
                    console.log(response.data);
                    //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                    // for (var i = 0; i < response.data.data.length; i++) {
                    //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                    //     if(response.data.data[i].pick_date)
                    //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].delivery_date)
                    //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //     if(response.data.data[i].estimated_delivery)
                    //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                    //   }
                    that.setState({showLoader : false, showLeftLoader: false});
                    if (response.data.status){
                        if(offset == 0){
                            ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                        }else{
                            if(response.data.data.length == 0)
                                // that.setState({showLoader : false});
                                ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                            else
                                // that.setState({showRightContent2 : true, rightContent2: data.concat(response.data.data), showLoader : false});
                                ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                        }
                    }else{
                        // that.setState({showLoader : false});
                    }
                    })
                    .catch(function (error) {
                    // handle error
                    console.log(error);
                    })
                    .finally(function () {
                    // always executed
                    });
    }


    getSupportChatArchivesByKeySearch(tag, that,country){
        var that1 = this;
        var params = {};
        that.setState({showSearchLoader:true})
        // params = {key : tag}
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_chat_archive_search+tag+`?country=${country.name.toLowerCase()}`, getConfigWithParams(localStorage.getItem("access_token"), params))
                    .then(function (response) {
                    // console.log("chat_details_by_key_Archives", response.data);

                    const se_users = JSON.parse(sessionStorage.getItem("se_users"));
                    
                    if (response.data.status){
                        var threads_list = [];
                        response.data.data.chats.forEach(element => {
                            var messages = null;
                            if(element.chat){
                                messages = Object.keys(element.chat).map((k) => element.chat[k]);
                            }
                            
                            // console.log("message", messages);

                            console.log("element", element, element.key);

                            var assignedExecName = null;
                            if(element.assigned_executive == "bot"){
                                assignedExecName = "bot";
                            }else{
                                var assignedExec = parseInt(element.assigned_executive);
                                if (assignedExec in se_users){
                                    assignedExecName = se_users[assignedExec]['name'];
                                }
                            }

                            let obj = {key: element.key, source : element.source, name : element.name, mobile : element.mobile, assigned_executive : element.assigned_executive, assigned_executive_name: assignedExecName, last_updated : element.last_updated, created_at : element.created_at,
                            first_customer_message_time : element.first_customer_message_time, first_executive_message_time : element.first_executive_message_time, last_message : messages && messages.length > 0 ? messages[messages.length -1].text : "", 
                            last_messagetime : messages && messages.length > 0 ? messages[messages.length -1].time : "", id : element.oid};
                            threads_list.push(obj);
                        });
                        that.setState({keyArchives: response.data.data, showLoader : false, threads_list_constant : threads_list, threads_list : threads_list, keyX : response.data.data.key,showSearchLoader:false});

                        that1.getChatUserDetails(response.data.data.key, that,country.name.toLowerCase());
                        that1.getOrderDetailsPaginate(response.data.data.key, [], that, country.name.toLowerCase());
                    }else{
                        // that.setState({showRightContent3 : false, rightContent3: "no_chats", showLoader : false});
                    }
                    })
                    .catch(function (error) {
                    // handle error
                    that.setState({showSearchLoader:false})
                    console.log(error);
                    })
                    .finally(function () {
                    // always executed
                    });
    }
    getSupportChatArchivesByExecutive(tag, that,country){
        var that1 = this;
        var params = {};
        that.setState({showSearchLoader:true})
        // params = {key : tag}
        axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].get_chat_archive_search+tag+`?country=${country.name.toLowerCase()}&assigned_executive=${tag}`, getConfigWithParams(localStorage.getItem("access_token"), params))
                    .then(function (response) {
                    // console.log("chat_details_by_key_Archives", response.data);

                    const se_users = JSON.parse(sessionStorage.getItem("se_users"));
                    
                    if (response.data.status){
                        var threads_list = [];
                        response.data.data.chats.forEach(element => {
                            var messages = null;
                            if(element.chat){
                                messages = Object.keys(element.chat).map((k) => element.chat[k]);
                            }
                            
                            // console.log("message", messages);

                            console.log("element", element, element.key);

                            var assignedExecName = null;
                            if(element.assigned_executive == "bot"){
                                assignedExecName = "bot";
                            }else{
                                var assignedExec = parseInt(element.assigned_executive);
                                if (assignedExec in se_users){
                                    assignedExecName = se_users[assignedExec]['name'];
                                }
                            }

                            let obj = {key: element.key, source : element.source, name : element.name, mobile : element.mobile, assigned_executive : element.assigned_executive, assigned_executive_name: assignedExecName, last_updated : element.last_updated, created_at : element.created_at,
                            first_customer_message_time : element.first_customer_message_time, first_executive_message_time : element.first_executive_message_time, last_message : messages && messages.length > 0 ? messages[messages.length -1].text : "", 
                            last_messagetime : messages && messages.length > 0 ? messages[messages.length -1].time : "", id : element.oid};
                            threads_list.push(obj);
                        });
                        that.setState({keyArchives: response.data.data, showLoader : false, threads_list_constant : threads_list, threads_list : threads_list, keyX : response.data.data.key,showSearchLoader:false});

                        //that1.getChatUserDetails(response.data.data.key, that,country.name.toLowerCase());
                        //that1.getOrderDetailsPaginate(response.data.data.key, [], that, country.name.toLowerCase());
                    }else{
                        // that.setState({showRightContent3 : false, rightContent3: "no_chats", showLoader : false});
                    }
                    })
                    .catch(function (error) {
                    // handle error
                    that.setState({showSearchLoader:false})
                    console.log(error);
                    })
                    .finally(function () {
                    // always executed
                    });
    }
    getSupportChatArchivesByMobile(key, userAccess, country){
        let access = userAccess ? userAccess.toLowerCase() : "retail";
        return axios.get(Constants.getBaseURL()+URLS.endpoints[access].get_chat_archive_search_mobile+key+`?country=${country}`)
    }
    getCustomerOverview(userId,country){
        return axios.get(Constants.getBaseURL()+URLS.endpoints[user_access].customer_overview+`?user_id=${userId}&country=${country}`,getConfig(localStorage.getItem("access_token")));
    }
    postEscalation(data, orderId, country) {
        return axios.post(Constants.getBaseURLOther(country)+`${URLS.endpoints[user_access].escalation}${orderId}/`,
        data, getConfig(localStorage.getItem("access_token")));
    }
    getEscalationDetails(orderId,country){
        return axios.get(Constants.getBaseURLOther(country)+`${URLS.endpoints[user_access].escalation}${orderId}/`,getConfigWithParams(localStorage.getItem("access_token")));
    }
    getSparesEscalationDetails(orderId){
        return axios.get(Constants.getBaseURLCentral()+`${URLS.endpoints[user_access].get_escalation}${orderId}?channel=chat`,getConfigWithParams(localStorage.getItem("access_token")));
    }
    putSpareEscalationDetails(data,orderId){
        return axios.put(Constants.getBaseURLCentral()+`${URLS.endpoints[user_access].get_escalation}${orderId}`,data,getConfigWithParams(localStorage.getItem("access_token")));
    }
    postSparesEscalation(data) {
        return axios.post(Constants.getBaseURLCentral()+`${URLS.endpoints[user_access].escalation}`,
        data, getConfig(localStorage.getItem("access_token")));
    }
    postRetailEscalation(data,orderId,country){
        return axios.post(Constants.getBaseURLCentral(country)+`${URLS.endpoints[user_access].postEscalation}${orderId}/`,
        data, getConfig(localStorage.getItem("access_token")));
    }
    getRetailEscalation(orderId,country){
        return axios.get(Constants.getBaseURLCentral(country)+`${URLS.endpoints[user_access].getEscalation}${orderId}`
        , getConfigWithParams(localStorage.getItem("access_token")));
    }
    putRetailEscalation(data,orderId,country){
        return axios.put(Constants.getBaseURLCentral(country)+`${URLS.endpoints[user_access].putEscalation}${orderId}/`,
        data, getConfigWithParams(localStorage.getItem("access_token")));
    }
    postSessionDetails(online){
        let type = online ? "login" : "logout";
        let data = {"type" : type};
        return axios.post(Constants.getBaseURL()+`api/session/${localStorage.getItem("user_id")}`, data);
    }
    intitateRefund(orderId, refundAmount, remarks, country){
        return axios.post(Constants.getBaseURLWF()+URLS.endpoints[user_access].razorpay_refund
        , {"order_id":orderId, "amount":refundAmount, "remark": remarks, country}, getConfigWithParams(localStorage.getItem("access_token")));
    }
    addGoAppMoney(data){
        return axios.post(Constants.getBaseURL()+URLS.endpoints[user_access].credit
        , data, getConfigWithParams(localStorage.getItem("access_token")));
    }
    downloadBill(orderId,country){
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer "+ localStorage.getItem("access_token")
        };
        const options = {
        method: "GET",
        headers: headers
        };
        return fetch(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].gst_bill}?order_id=${orderId}`
        , options).then(
            res => res,
            err => err
          );
    }
    downloadAccBill(orderId,userId){
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer "+ localStorage.getItem("access_token")
        };
        const options = {
        method: "GET",
        headers: headers
        };
        return fetch(`${Constants.getBaseURLOther()}${URLS.endpoints[user_access].acc_invoice}?order_id=${orderId}&user_id=${userId}`
        , options).then(
            res => res,
            err => err
          );
    }
    cancelOrder(data,country){
        return axios.post(Constants.getBaseURLOther(country)+URLS.endpoints[user_access].order_cancel
        , data, getConfigWithParams(localStorage.getItem("access_token")));
    }
    cancelAccessOrder(data,country){
        return axios.post(Constants.getBaseURLOther(country)+URLS.endpoints[user_access].cancel_accessories_order
        , data, getConfigWithParams(localStorage.getItem("access_token")));
    }
    getVoucherList(country){
        return axios.get(Constants.getBaseURLOther(country)+URLS.endpoints[user_access].post_coupons, getConfigWithParams(localStorage.getItem("access_token")));
    }
    sendVoucherCode(data, country){
        return axios.post(Constants.getBaseURLOther(country)+URLS.endpoints[user_access].post_coupons 
        , data, getConfigWithParams(localStorage.getItem("access_token")));
    }
    getIPV6Address(){
        return axios.get('https://api6.ipify.org?format=json', {}, {});
    }

    getIPLocation(){
        return axios.get('https://ipapi.co/json', {}, {});
    }
    getIPV4Address(){
        return axios.get('https://api.ipify.org?format=json', {}, {});
    }
    getTimelineData(url, country){
        let headers = {headers: {'Authorization': `Bearer ${getToken('ml')}`, "Accept":"application/json; version=1.09"}};
        return axios.get(`${Constants.getBaseURLML(country)}${url}`, headers);
    }
    getInfoFromWorkForceApp(url, orderId = "newOrder", type = "") {
        const workforceAppToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwibmJmIjoxNTkzMTU1MTQ0LjAsImlhdCI6MTU5MzE1NTE0NC4wLCJzdWIiOiI1MzUyMDUiLCJzY29wZXMiOltdLCJqdGkiOiJiNGJjM2NhZjVkMWVhOTlkYzk2YjQzM2NjYzQzMDI0ZTAyM2I0MGM2YjQ5ZjExN2JjMDk5OGY2MWU3ZDI1ZjM2MTU1YWU5ZDIxNjE2ZTc5NSIsImV4cCI6MTcyMDU5NTE0NC4wfQ.mzBFMnTgX2KgYIkE_rZEMpsRkFW9eGbI8uAIGZvcS20YKFpsYFaFswc-w5rxvoc9wSKck_yfKNQ36y5ACSsOvcC1u9TdE__TyMltwpi1HL_LYB5LGecD8IFpqHCm903SGoVQ7qUxzcFyswbshXprKX6tdwRJ3h3pchi5w-X-UdgKCFyI359q_UpIatv8G3x0-BHHZc8wlTH6VOjMr5QLp2LFzj1858LYOC_P8UzTrXfl3-fmIGK6hw4ZiaeU7lMb2WZCbRaspccG9xmhm89_hm9srJE9JSLgwRTDKD32HJfMbN8e5RvclLQcz4o4OQ2fFPpMoEvjFNawjqIoGcO9ZrvmcrcEtbDLuN9KeKVwgzWRJgNuxRPGvfEt6nyeWXJ-blHllYUWf-U0tHJL9ZSqaYea_V7gzIqELNuJnk0FUsCvPPZ_RGWr5ShdUz9BHC7c3VPGkYJFG5Fbncwf6ruW-kArVoolzF8pZqg1degKxGNuZupx6xS25IRkm2UhlkqofRTEcUMuxu381CmNOA3dPA9jJ8C0h7gRsoW9yKANFiCuDPD8nYuWAoZeV__scZxyDnBW-Qzm0FAdZ1kRLRYukuU-YySe2SdPW7HdqY3f1KjobT26ojvDrMj3O6uO2k6W3U3h1FcSOAR3p1_7qMbkRR2u4HVUNyRHjM0v1uShseE";
        const options = {
            method: "GET",
            headers: {
                'Accept': 'application/json; version=1.09',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + workforceAppToken
            }
        };
        if (workforceAppToken) {
            return fetch((url), options).then(async (response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    const new_file = new Blob([await response.text()], { type: "application/pdf'" });
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const url = window.URL.createObjectURL(new_file);
                    a.href = url;
                    a.download = `${type}_${orderId}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    return true;
                }
            }, err => {
                return err;
            });
        }
    }
    getAccOrderDetails(service_id){
        return axios.get(`${Constants.getBaseURLOther()}${URLS.endpoints[user_access].acc_details}?service_id=${service_id}`,getConfigWithParams(localStorage.getItem("access_token")));
    }
    getCityList(lat, long, country){
        return axios.get(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].get_cities}/?lat=${lat}&long=${long}`,getConfigWithParams(localStorage.getItem("access_token")));
    }
    getComaplintTypes(orderId,type,version,country){
        return axios.get(Constants.getBaseURLCentral(country)+`${URLS.endpoints[user_access].getComplaintTypes}?type=${type}&version=${version}&order_id=${orderId}`,getConfigWithParams(localStorage.getItem("access_token")));
    }
    createLead(params,country, type){
        if(type == "SOS"){
            return axios.post(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].chat_checkout}`,
            params, getConfigWithParams(localStorage.getItem("access_token")));
        }else{
            let headers = {headers: {'Authorization': `Bearer ${getToken('ml')}`, "Accept":"application/json; version=1.09"}};
            return axios.post(`${Constants.getBaseURLML(country)}${URLS.endpoints[user_access].chat_lead}`,
            params, headers);
        }
    }
    getCars(country){
        let headers = {headers: {'Authorization': `Bearer ${getToken('ml')}`, "Accept":"application/json; version=1.09"}};
        return axios.get(`${Constants.getBaseURLML(country)}cars/?`, headers);
    }
    getOrderServiceList(country){
        return axios.get(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].chat_order_services}`, getConfigWithParams(localStorage.getItem("access_token")));
    }
    getSOSServiceList(country,carId,cityId){
        return axios.get(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].sos_services}?car_id=${carId}&city_id=${cityId}`, getConfigWithParams(localStorage.getItem("access_token")));
    }
    saveChatRemarks(phone, remark, country){
        axios.post(`${Constants.getBaseURLOther(country)}${URLS.endpoints[user_access].save_remarks}`, {
            "mobile"                : phone,
            "chat_agent_comment"  : remark
          })
        .then(function (response) {
            // if (response.data.status){
            //     sessionStorage.setItem("chatRemarks", true);
            // }else{
            //     // that.setState({keyArchives: [], showLoader : false, firstLoading : false});
            // }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    sendMessageToGmb(data){
        return axios.post('https://comms.gomechanic.app/api/v1/support/gmb/messages/message/send', 
            data
          )
    }
}

export default new API();