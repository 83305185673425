import React, { useEffect ,useState} from 'react';
import API from '../../api';
import './accOrderDetails.css'

const AccOrderDetails = (props) => {

    useEffect(() => {
        API.getAccOrderDetails(props.service_id).then((res)=>{
            setAccDetails(()=>({...res.data.data,description:res.data.data.description.split('<br/>')}))
            setIsAccDetailLoaded(false)
        })
        .catch(()=>{
            setIsAccDetailLoaded(false)
        })
    },[])

    const features = ['800 GSM Microfiber Cloth', 'Synthetic Cloth', 'Quick Cleaning']
    const specification = [{ size: 'Large' }, { pattern: 'Tribal' }, { material: 'Genuine Leather' }, { Warranty: '1 Year' }, { manufacturer: 'GoMechanic' }]
    const [accDetails,setAccDetails] = useState({})
    const [isAccDetailLoaded,setIsAccDetailLoaded] = useState(true)

    return (<div className="modalComponent-acc" >
        <div className="modalBackground-asc">
            
            <div className={props.theme == "light"?"acc-modal-header" :"acc-modal-header-dark "}>
                <div className='ml-auto'>View Details</div>
                <img onClick={()=>props.closeClick(false)} className='ml-auto' src='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/cross.png' height='16px' width='16px' />
            </div>
            {isAccDetailLoaded?<div><div className='acc-loader'></div></div>:
            <div className='acc-modal-body'>
            <div className='acc-body-heading'>Features</div>
            <div className={props.theme == "light"?`red-line`:'violet-line'}></div>
            <div className='product-features'>
                {accDetails.description && accDetails.description.map((data) => {
                    return ( 
                        <div className='li-feature-item'>{data}</div>
                    )
                })} 
            </div>
            <div className='acc-body-heading'>Specifications</div>
            <div className={props.theme == "light"?`red-line`:'violet-line'}></div>
            <table className='spec-table'>
                <tbody>
                    {accDetails && accDetails.product_specifications && accDetails.product_specifications.map((data)=>{
                            return <tr>
                                <td  className='spec-table-left'>{data.key}</td>
                        <td className='spec-table-right'>{data.value}</td>
                            </tr>     
                    })}
                </tbody>
            </table>
            </div>}
        </div>
    </div>
    )
}

export default AccOrderDetails