import React, { useState, Fragment } from "react";
import "./MultiSelect.css";
const MultipleSelection = (props) => {
  const [searchString, updateSearchString] = useState("");
  const [hideOptions, setHideOptions] = useState(false);
  const updateSelectionList = (event, option) => {
    // console.log('+++++'+JSON.stringify(option));
    // console.log('-----'+JSON.stringify(props));

    let new_selection = null;
    if (event.target.checked) {
      if (option.id == "select-all") {
        new_selection = [...props.options];
      } else {
        new_selection = [...props.selectedOptions, option];
      }
    } else {
      if (option.id == "select-all") new_selection = [];
      else {
        new_selection = props.selectedOptions.filter(function (
          _selected_option
        ) {
          var selected = true;
          if (_selected_option[props.checkbox_id] == "select-all") {
            selected = false;
          }
          if (
            selected &&
            _selected_option[props.checkbox_id] !== option[props.checkbox_id]
          ) {
            selected = true;
          } else {
            selected = false;
          }

          return selected;
        });
      }
    }
    console.log("*****", new_selection);
    if (new_selection) {
      props.onChange(new_selection);
    }
  };

  const handleSingleSelection = (option) => {
    updateSearchString("");
    if (option.id == "select") props.onChange({});
    else props.onChange(option);
    setHideOptions(true);
  };
  const handleSearchString = (event) => {
    updateSearchString(event.target.value);
    if (event.target.id == "select-page-path") props.onType(event.target.value);
  };

  const checkSelect = () => {
    return props.multiple
      ? null
      : props.selectedOptions !== null &&
        props.selectedOptions !== undefined &&
        props.options
      ? props.options.reduce(
          (a, v) =>
            v[props.checkbox_id] === props.selectedOptions[props.checkbox_id]
              ? v[props.labelKey]
              : a,
          null
        )
      : null;
  };

  return (
    <>
      <div
        style={{ height: "48px"}}
        className={`dropdown-check-list ${
          props.disabled ? "disableDarkGrayBackground inputDisabled" : ""
        }`}
      >
        <span
          className={`selected-label ${
            props.multiple ? "color-black-label" : ""
          }`}
        >
          {props.multiple
            ? props.selectedOptions && props.selectedOptions.length > 0
              ? props.selectedOptions[0].name &&
                props.selectedOptions[0].id == "select-all"
                ? props.selectedOptions[0].name
                : props.selectedOptions
                    .map((selected) => selected[props.labelKey])
                    .join(" | ")
              : ""
            : checkSelect()
            ? props.placeholder
            : ""}
        </span>
        <input
          // style={{ paddingTop: "10px" }}
          id={props.placeholder.replace(/ /g, "-").toLowerCase()}
          className={`anchor ${props.notValid ? "input-box-error" : ""} ${
            checkSelect() ? "color-black-placeholder" : ""
          } ${props.disabled ? "inputDisabled" : ""}
          `}
          placeholder={
            props.multiple
              ? props.placeholder
              : checkSelect()
              ? checkSelect()
              : props.placeholder
          }
          value={searchString}
          disabled={props.disabled}
          onFocus={() => setHideOptions(false)}
          onClick={() => {props.onInputClick && props.onInputClick()}}
          onChange={(event) => handleSearchString(event)}
        />

        <ul
          className={`options ${hideOptions ? "options-display-none" : ""}`}
          // style={{
          //   maxHeight: "298px",
          //   borderRadius: "5px",
          //   top: "40px",
          //   width: "96%",
          //   marginTop:"5px",
          // }}
        >
          {props.options && props.options.length > 0
            ? props.options
                .filter(
                  (filter_option) =>
                    filter_option[props.labelKey]
                      .toLowerCase()
                      .indexOf(searchString.toLowerCase()) !== -1
                )
                .map((option) => (
                  <Fragment
                    key={`${option[props.labelKey]}-${
                      option[props.checkbox_id]
                    }-${Math.random().toFixed(4)}`}
                  >
                    {" "}
                    {props.multiple ? (
                      <li>
                        <label
                          htmlFor={`checkbox${props.labelKey}${
                            option[props.checkbox_id]
                          }${props.placeholder}`}
                        >
                          {props.disabledItems &&
                            props.disabledItems.reduce(
                              (a, v) =>
                                v[props.checkbox_id] ===
                                option[props.checkbox_id]
                                  ? null
                                  : a,
                              <input
                                type="checkbox"
                                id={`checkbox${props.labelKey}${
                                  option[props.checkbox_id]
                                }${props.placeholder}`}
                                onChange={(event) =>
                                  updateSelectionList(event, option)
                                }
                                checked={
                                  props.selectedOptions &&
                                  props.selectedOptions.length > 0 &&
                                  props.selectedOptions.reduce(
                                    (a, v) =>
                                      v[props.checkbox_id] ===
                                      option[props.checkbox_id]
                                        ? true
                                        : a,
                                    false
                                  )
                                }
                              />
                            )}
                          <span
                            className="margin_l_8"
                            style={{ fontSize: "0.75rem" }}
                          >
                            {option[props.labelKey]}
                          </span>
                        </label>
                      </li>
                    ) : (
                      <li onClick={() => handleSingleSelection(option)}>
                        <label
                          className={
                            props.selectedOptions && props.selectedOptions.name && option[props.checkbox_id] ==
                            props.selectedOptions.name
                              ? "single-selected-item"
                              : ""
                          }
                        >
                          <span
                            className="margin_l_8"
                            style={{ fontSize: "0.75rem" }}
                          >
                            {option[props.labelKey]}
                          </span>
                        </label>
                      </li>
                    )}{" "}
                  </Fragment>
                ))
            : null}
        </ul>
        <img loading="lazy" src="/images/ic_expand_more.svg" alt="expand"></img>
      </div>
      {props.notValid && (
        <label className="multi-select-error">{props.error}</label>
      )}
    </>
  );
};

export default MultipleSelection;
