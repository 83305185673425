import React, {useState } from 'react';

const PopupWrapper = (props) => {
    return (
        <>
            <>
            <React.Fragment>
            
            <div className="border-box  header">
                    <div className="messageBox-mp">
                        <div className="messageBox-Background bgdark-theme2" style={{width: "60%"}}>
                            <div className="messageBox-text">{props.headerText}</div>
                            {props.children}
                        </div>
                    </div>
                </div>
                </React.Fragment>
            </> 
        </>
    )
}

export default PopupWrapper