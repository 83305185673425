import React from 'react';
import "./topbar/topbar1.css"
import {Redirect, Link} from 'react-router-dom';
import API from './api';
import ManageUserPopUp from '../src/manageUserPopUp/ManageUserPopUp';
import MultiSelect from "./custom/MultiSelect";

const axios = require('axios');
const chatOptions = [{id:'1',name:'Retail'},{id:'2',name:'Spares'}]
const user_access = sessionStorage.getItem("user_access") || 'retail'
const role = localStorage.getItem("role")

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popUp: false,
      category:chatOptions.find((each)=> 
      user_access === each.name.toLowerCase() 
        )
    }
    this.onChange = this.onChange.bind(this);
    this.handleSwitchClick = this.handleSwitchClick.bind(this);
    this.handlePanelButtonClick = this.handlePanelButtonClick.bind(this);
  }

  handleSwitchClick(type, mSwitch){
    //console.log("hahahahahaha");
    //console.log(this.props.live);
    //console.log(this.state);
    //console.log(type, mSwitch);
    if(type == "online_status"){
      console.log("onlineClicked1", type, mSwitch);
      this.props.onOnlineStatusSwitchClick(type, mSwitch);
    }else if(type == "theme"){
      this.props.onThemeSwitchClick(type, mSwitch);
    }
  }

  onChange(value){
    sessionStorage.setItem("user_access",value.name.toLowerCase())
    window.location.reload(true);
    this.setState({category:value})
  } 
   
  handlePanelButtonClick(type){
    this.props.onPanelButtonClick(type);
  }

  render() {
    return (
      <div className={this.props.theme == "light" ? "topbarouter" : "topbarouter dark-theme"} id="topbarouter">
        <div className="topbarinner" id="topbarinner">
        <nav className="topbar light persistent border-box dashboard" id="topbar">
          <a href="/" className="logo"></a>

          <div className={this.props.theme == "light" ? "panelbuttons border-box floatleft" : "panelbuttons border-box floatleft dark-theme"}>

            <div className={this.props.currentView == "live" ? "panelbutton selected border-box centeralign floatleft" : "panelbutton border-box centeralign floatleft"} onClick = {(e) => this.handlePanelButtonClick("live")}>
              <div className="inner border-box centeralign clearfix">
                <div className="imagebox border-box centeralign clearfix">
                  <img src={this.props.theme == "light" ? "images/chat/live/dark.png" : "images/chat/live/light.png"} height="21" width="21" />
                </div>
                <div className="textbox border-box centeralign clearfix weight700 uppercased">
                  Live
                </div>
              </div>
            </div>

            <div className={this.props.currentView == "archives" ? "panelbutton selected border-box centeralign floatleft" : "panelbutton border-box centeralign floatleft"} onClick = {(e) => this.handlePanelButtonClick("archives")}>
              <div className="inner border-box centeralign clearfix">
                <div className="imagebox border-box centeralign clearfix">
                  <img src={this.props.theme == "light" ? "images/chat/archive/dark.png" : "images/chat/archive/light.png"} height="21" width="21" />
                </div>
                <div className="textbox border-box centeralign clearfix weight500 uppercased">
                  Archives
                </div>
              </div>
            </div>

            {localStorage.getItem("role") == "manager" ? (
              <div className={this.props.currentView == "stats" ? "panelbutton selected border-box centeralign floatleft" : "panelbutton border-box centeralign floatleft"} onClick = {(e) => this.handlePanelButtonClick("stats")}>
              <div className="inner border-box centeralign clearfix">
                <div className="imagebox border-box centeralign clearfix">
                  <img src={this.props.theme == "light" ? "images/chat/stats/dark.png" : "images/chat/stats/light.png"} height="21" width="21" />
                </div>
                <div className="textbox border-box centeralign clearfix weight500 uppercased">
                  Stats
                </div>
              </div>
            </div>
            ) : (<React.Fragment />)}
            

          </div>
          <ul>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
            <li className="mobile_btn mobile_menu_btn">
              <a href="#" className="btn_topbar">Menu</a>
            </li>
          </ul>


          <div className="topbarchoiceboxes border-box centeralign floatright uppercased weight700">
            <div className={this.props.theme == "light" ? "topbarchoiceboxesinner border-box centeralign floatleft selected" : "topbarchoiceboxesinner border-box centeralign floatleft"} 
              onClick = {(e) => this.handleSwitchClick("theme", "light")}>Light</div>
            <div className={this.props.theme == "dark" ? "topbarchoiceboxesinner border-box centeralign floatleft selected" : "topbarchoiceboxesinner border-box centeralign floatleft"} 
              onClick = {(e) => this.handleSwitchClick("theme", "dark")}>Dark</div>  
          </div> 
          <div className="topbarchoiceboxes border-box centeralign floatright uppercased weight700">
            <div className={this.props.isOnline ? "topbarchoiceboxesinner border-box centeralign floatleft selected" : "topbarchoiceboxesinner border-box centeralign floatleft"} 
              onClick = {(e) => this.handleSwitchClick("online_status", true)}>Online</div>
            <div className={this.props.isOnline ? "topbarchoiceboxesinner border-box centeralign floatleft" : "topbarchoiceboxesinner border-box centeralign floatleft selected"} 
              onClick = {(e) => this.handleSwitchClick("online_status", false)}>Offline</div>  
          </div>
          {localStorage.getItem("role") == "manager" && 
            <button onClick={() => this.setState({popUp: !this.state.popUp})} type="button" className={this.props.theme == "light" ? "manage-users1" : "manage-users"}>Manage User</button>
          }
          {this.state.popUp  ?
            <ManageUserPopUp modal={() => this.setState({popUp: false})} />
          : null }
                    {role==='manager' && <div className="floatright dropdown-category">
           <MultiSelect                
                options={chatOptions}
                labelKey="name"
                checkbox_id="name"
                placeholder="Select the Category"
                disabledItems={[]}
                selectedOptions={this.state.category}
                onInputClick={() => {}}
                onChange={(value) =>
                  this.onChange(value)
                }
            /> 
          </div>} 
          </nav><nav className="mobile_menu border-box"><div className="mobile_menu_wrapper border-box"><div className="mobile_menu_header border-box"><a href="/" className="logo"></a><a href="#" className="close btn_topbar">Close</a></div><ul><li><a href="/accounts/logout/?next=/accounts/login/">Logout</a></li></ul><ul className="mobile_menu_footer"><li className="colorprimA400span">Available in New Delhi, Gurgaon, Faridabad, NOIDA, Greater NOIDA & Ghaziabad.</li></ul>
      </div>
      </nav>
      </div>
      </div>
    );
  }
}


export default Topbar;
