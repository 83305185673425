import firebase from './firebase';
// import FBDB from './firebaseappdb';
import ChatUtils from './support_chat/chat_utils';

if(firebase.messaging.isSupported()){
    const messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      console.log("fbmess11");
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        const options = {
          body: payload.notification.body,
          icon: payload.notification.icon
        }
        registration.showNotification(
            payload.notification.title,
            options
        )
      });
      // ...
    });

    messaging.onTokenRefresh(function() {
      getToken();
      console.log("fbmess12");
    });

    function deleteToken() {
      console.log("fbmess13");
      // Delete Instance ID token.
      // [START delete_token]
      messaging.getToken()
      .then(function(currentToken) {
        messaging.deleteToken(currentToken)
        .then(function() {
          console.log("fbmess21");
          console.log('Token deleted.');
        })
        .catch(function(err) {
          console.log("fbmess20");
          console.log('Unable to delete token. ', err);
        });
        // [END delete_token]
      })
      .catch(function(err) {
        console.log("fbmess19");
        console.log('Error retrieving Instance ID token. ', err);
    //      showToken('Error retrieving Instance ID token. ', err);
      });
    }

    function getToken(){
      console.log("fbmess14");
      messaging.getToken()
      .then(function(currentToken) {
        console.log("fbmess18");
        if (currentToken) {
          console.log("currentToken",currentToken);
          localStorage.setItem("fcmToken",currentToken);
          ChatUtils.saveFCMToken(currentToken);
    //      sendTokenToServer(currentToken);
    //      updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
    //      updateUIForPushPermissionRequired();
    //      setTokenSentToServer(false);
        }
      })
      .catch(function(err) {
        console.log("fbmess17");
        console.log('An error occurred while retrieving token. ', err);
    //    showToken('Error retrieving Instance ID token. ', err);
    //    setTokenSentToServer(false);
      });
    }

    messaging.requestPermission()
        .then(function() {
          console.log("fbmess15");
          console.log('Notification permission granted.');
          getToken();
    })
    .catch(function(err) {
      // console.log("fbmess16");
      console.log('Unable to get permission to notify.', err);
    });
}