import React from 'react';
// import "../dashboard/dashboard.css"
import MiddleMain from './middlemain';
import ChatUtils from './chat_utils';
import API from '../api';
import ImageUploader from './imageUploader/ImageUploader';
import MultiSelect from "../custom/MultiSelect";
import { NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Constants from '../constants';
import firebase from "firebase/app";
import "firebase/storage";

class PanelMiddle extends React.Component {
    constructor(props) {
      super(props);
  //    this.handleTabClick = this.handleTabClick.bind(this);
  //    this.state = {showContent: false};
      this.sendieRef = React.createRef();
      this.chatRef = React.createRef();
      this.customerPopRef = React.createRef();
      this.scrollToBottom = this.scrollToBottom.bind(this);

      this.handleAIClick = this.handleAIClick.bind(this);
      this.handleSendClick = this.handleSendClick.bind(this);
      this.handleTextChange = this.handleTextChange.bind(this);
      this.handleAIMessageClick = this.handleAIMessageClick.bind(this);
      this.handleImageUpload = this.handleImageUpload.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
      this.handleUnreadMessages = this.handleUnreadMessages.bind(this);
      this.addAgentComment = this.addAgentComment.bind(this);
      this.getCustomerDetails = this.getCustomerDetails.bind(this);  
      this.handleClickOutside = this.handleClickOutside.bind(this)
      this.handleCancel = this.handleCancel.bind(this)  
      this.handleYes = this.handleYes.bind(this)
      this.uploadToFirebase = this.uploadToFirebase.bind(this)
      this.handleSendInoice = this.handleSendInoice.bind(this)
      this.shouldShowInvoiceButton = this.shouldShowInvoiceButton.bind(this)
      this.state = {showAI : false, text : "", nokeyArchive : true, selectedTab:null, showScrollButton: false, showAgentCommentPopup: false,agentComment: "",customerDetailLoader:true,customerDetails:{},isOver:'',showConfirmationBox:{value:false,message:{}},showConfirmationLoader:false};
    }

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  
    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
      if (this.customerPopRef.current && !this.customerPopRef.current.contains(event.target)) {
      this.props.handleCustomerOverview(false)
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.selectedTab && nextProps.selectedTab !== this.props.selectedTab){
        this.setState({showAI:false})
      }
      if(nextProps.isOnline !== this.props.isOnline){
        this.setState({showAI:false})
      }  
    }

    shouldComponentUpdate(nextProps, nextState){
      if(nextProps.selectedTab)
        return true;
      else
        return false;
    }

    scrollToBottom(prevProps, isScroll = false) {
      // if(this.props.keyArchives && this.state.nokeyArchive){
      //   console.log('chatRefnokeyArchive');
      //   this.setState({nokeyArchive:false , selectedTab: this.props.selectedTab});
      //   this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      // }
      // if(this.state.selectedTab != this.props.selectedTab){
      //   console.log('chatRefselectedTab');
      //   this.setState({nokeyArchive:true , selectedTab: this.props.selectedTab});
      // }
      // let height = this.chatRef.current.scrollHeight - Math.floor(this.chatRef.current.scrollTop);
      // if(height > 1000){
      //   console.log('chatRefheight');
      //   if(this.props.selectedTab && this.props.allModels){
      //     let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
          
      //     if(this.props.selectedTabLenght == this.props.allModels[this.props.selectedTab].length){
      //       if(this.props.firstLoading)
      //         this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      //       if(!thread.is_typing_customer)
      //         this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      //     }
      //   }
      // }
      // else{
      //   this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      // }
      // if(height < 1000){
      // if(this.props.keyArchives != prevProps.keyArchives){
      //   console.log("chatRef");
      //   this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      // }
      // }
      let height = this.chatRef.current.scrollHeight - Math.floor(this.chatRef.current.scrollTop);
      // // console.log(height, "chatRefheight");
      // if(height<1200){
      //   console.log(this.props.selectedTabLenght,"chatRefselectedTabLenght",prevProps.selectedTabLenght);
      //   if(this.props.selectedTabLenght != prevProps.selectedTabLenght){
      //     // console.log("chatRef3panel");
      //     this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      //   }
      // }else{
      //   if(prevProps.selectedTab != this.props.selectedTab){
      //     // console.log("chatRef4panel");
      //     this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      //   }
      // }
      if(isScroll){
        this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
        return;
      }
      if(prevProps.selectedTab != this.props.selectedTab){
        this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
        return;
      }
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);      
      if(thread!=null && thread['unread'] > 0 && Math.floor(this.chatRef.current.scrollHeight - this.chatRef.current.scrollTop) -  parseInt(this.chatRef.current.clientHeight) > 5 && !this.state.showScrollButton){
        this.setState({showScrollButton: true});
        setTimeout(() => {
          this.handleUnreadMessages(true);  
        }, 500);
        return;
      }
    }

    handleAIClick() {

      this.setState({showAI : !this.state.showAI});
    }

    showAgentPopup(){
      this.setState({showAgentCommentPopup: true});
    }
    addAgentComment(country){
      let remark = this.state.agentComment;
      if(remark){
        ChatUtils.addAgentComment(this.props.selectedTab, remark);
        this.setState({showAgentCommentPopup: false})
        API.saveChatRemarks(this.props.selectedTab, remark, country);
      }
    }
    handleSendClick(){

      // var message = this.state.text;
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      var message = this.sendieRef.current.value;
      ChatUtils.updateLastSeen(this.props.selectedTab)
      if(thread && thread.conversation_id && thread.source==='gmb'){
        API.sendMessageToGmb({"conversationId" : thread.conversation_id, "message" : {"text" : message}})
      }
      if(message.trim() != ""){
        // this.sendieRef.current.value = "";
        this.sendieRef.current.value = "";
        // this.setState({text : ""});
        this.props.onGlobalSend(message, this.props.selectedTab);
      }
      setTimeout(() => {
        this.scrollToBottom(null, true);  
      }, 200);
      
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        ChatUtils.updateLastSeen(this.props.selectedTab)
        e.preventDefault();
        // var message = this.state.text;
        var message =  e.target.value;
        let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
        if(thread && thread.conversation_id && thread.source==='gmb'){
          API.sendMessageToGmb({"conversationId" : thread.conversation_id, "message" : {"text" : message}})
        }
        if(message.trim() != ""){
          // this.sendieRef.current.value = "";
          // this.setState({text : ""});
          this.sendieRef.current.value = "";
          this.props.onGlobalSend(message, this.props.selectedTab);
          
        } 
        setTimeout(() => {
          this.scrollToBottom(null, true);  
        }, 200);
      }
    }
    handleImageUpload = (image, imagesFirebase, tab) => {
      ChatUtils.updateLastSeen(this.props.selectedTab)
      this.props.imageUploadButton(image, imagesFirebase, tab);
      setTimeout(() => {
        this.scrollToBottom(null, true);  
      }, 500);
    }

    getCustomerDetails(){
      this.setState({customerDetailLoader:true})
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      thread.orderDetails && thread.orderDetails.user_id &&  API.getCustomerOverview(thread.orderDetails.user_id,thread.country).then((res) => {
            if(res.data.status){
                this.setState({customerDetails:res.data.data})
            }
            this.setState({customerDetailLoader:false})
        }).catch(()=>{
          this.setState({customerDetailLoader:false})
        })
    }

    handleAIMessageClick(message, type){
      // this.setState({text : ""});
      ChatUtils.isTypingAlert(this.props.selectedTab, true)
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      if(thread && thread.conversation_id && thread.source==='gmb'){
        let text = type == "notification" ? message.message : message;
        API.sendMessageToGmb({"conversationId" : thread.conversation_id, "message" : {"text" : text}})
      }
      if(type == "notification"){
        this.props.onGlobalAIMessageClick(message, type, this.props.selectedTab);
      }else if(type == "orderDetails"){
        this.props.onGlobalAIMessageClick(message, type, this.props.selectedTab);
      }
      else if(type=='sendInvoice'){
        this.setState({showConfirmationBox :{value:true,message:message}})
        return  
      }
      else if(type == 'extraDetails'){
        this.setState({showConfirmationBox :{value:true,message:message} })
        return  
      }
      else{
        this.sendieRef.current.value = message.message;
      }
      // this.props.onGlobalAIMessageClick(message, type, this.props.selectedTab);
      // ChatUtils.sendAIMessage(message, type, this.props.selectedTab);
      this.setState({showAI : !this.state.showAI});
    }

    handleTextChange(e){
      //console.log(e.target.value);
      //this.setState({text : e.target.value});
      if(e.target.value.trim() != ""){
        ChatUtils.isTypingAlert(this.props.selectedTab, true);
      }else{
        ChatUtils.isTypingAlert(this.props.selectedTab, false);
      }
    }

    handleScroll = (e) => {
      // const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
      if(Math.floor(e.target.scrollHeight - e.target.scrollTop) -  parseInt(e.target.clientHeight) > 5 && !this.state.showScrollButton){
        this.setState({showScrollButton: true});
        this.handleUnreadMessages(true);
      }else if(Math.floor(e.target.scrollHeight - e.target.scrollTop) -  parseInt(e.target.clientHeight) < 5  && this.state.showScrollButton){
        this.setState({showScrollButton: false});
        this.handleUnreadMessages(false);
      }
    }
    handleUnreadMessages = (scrolled) => {
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);

      if(thread != null){
        var indexObj = this.props.threadsList.indexOf(thread);
        if(!scrolled)
          thread['unread'] = 0;
        thread['scrolling'] = scrolled;
        this.props.threadsList[indexObj] = thread;

        if(!scrolled){
          this.props.updateThreadList(this.props.threadsList);
        }
      }
    }

    handleCancel = () => {
      this.setState({showConfirmationBox : {value:false,message:{}}})
      setTimeout(() => {
        this.scrollToBottom(null, true);  
      }, 200);
      ChatUtils.isTypingAlert(this.props.selectedTab, false)
    }

    handleYes = () => {
      if(this.state.showConfirmationBox.message.type==='sendInvoice'){
        this.setState({showConfirmationLoader:true})
        this.handleSendInoice()
      }
      else{
      this.props.onGlobalAIMessageClick(this.state.showConfirmationBox.message, this.state.showConfirmationBox.message.type, this.props.selectedTab);
        setTimeout(() => {
          this.scrollToBottom(null, true);  
        }, 200);
       this.setState({showConfirmationBox : {value:false,message:{}}, showAI : !this.state.showAI }) 
      }
    }

    shouldShowInvoiceButton = () => {
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
        if(thread.orderDetails.order_type===110){
           return thread.orderDetails.status===10  
        }
        else {
           return thread.orderDetails.status>=50
        }
    }

    uploadToFirebase = (res,orderId) =>{  
        const firebaseConfig = Constants.getFirebaseConfig();
        if (firebase.apps.length) {
            var otherProject = firebase.apps[0];
        } else {
            var otherProject = firebase.initializeApp(firebaseConfig);
        }

        const storage = otherProject === undefined ? firebase.storage() : otherProject.storage();
        var imagesFirebase = []; 
            let name = 'Bill_Invoice.pdf';
            let file = res;
            let path = `/chat_assets/images/executive/${this.props.selectedTab}/${name}`;
            let ref  = storage.ref().child(path);
            ref.put(file).then((snapshot) => snapshot)
            .then((snapshot) => snapshot.ref.getDownloadURL())
            .then((downloadURL) =>  {
                console.log('download url',downloadURL);
                    imagesFirebase.push(downloadURL);
                    this.props.imageUploadButton([], imagesFirebase, this.props.selectedTab);
                    this.props.onGlobalSend(`Invoice for Order Id: ${orderId}`, this.props.selectedTab);
                    this.setState({showConfirmationLoader:false})
                    setTimeout(() => {
                      this.scrollToBottom(null, true);  
                    }, 200);
                    this.setState({showConfirmationBox : {value:false,message:{}}, showAI : !this.state.showAI }) 
                }).catch(()=>{
                  this.setState({showConfirmationBox : {value:false,message:{}}, showAI : !this.state.showAI }) 
                })
        
        return;
    }

    handleSendInoice = () => {
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
     
       thread.orderDetails.order_type===110?
       API.downloadAccBill(thread.orderDetails.order_id,thread.orderDetails.user_id)
              .then((res) => res.blob())
              .then((res) => this.uploadToFirebase(res,thread.orderDetails.order_id))
              .catch((err) =>{ 
                this.setState({showConfirmationLoader:false})
                this.setState({showConfirmationBox : {value:false,message:{}}})
                NotificationManager.error(`Something Went Wrong`, 'Error', 5000)
            })
       :
       API.downloadBill(thread.orderDetails.order_id,thread.country)
              .then((res) => res.blob())
              .then((res) => this.uploadToFirebase(res,thread.orderDetails.order_id))
              .catch((err) => {
                this.setState({showConfirmationLoader:false})
                this.setState({showConfirmationBox : {value:false,message:{}}})
                console.log(err,'eerr')
                NotificationManager.error(`Something Went Wrong`, 'Error', 5000)
              })
    }
  
    render() {
      // //console.log("rendering11", this.props.threadsList);
        
      var props = this.props;
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      // if(thread != null && thread.assigned_executive == localStorage.getItem("user_id")){
      //   let closeMessage = quickM.find(o => (o.type === "notification" && o.notif_type.toLowerCase() == "conversation closed"));
      //   if(closeMessage != null){
      //     quickM.splice(quickM.indexOf(closeMessage),1);
      //   }
      // }
      if(sessionStorage.getItem("quick_messages_internal") !== null && this.state.showAI){
        var quick_messages = [];
        var quickM = JSON.parse(sessionStorage.getItem("quick_messages_internal"));
        // quickM.push({"type":"orderDetails","action":"garage","message":"Workshop Location"});
        // quickM.push({"type":"orderDetails","action":"cr","message":"Service Buddy Details"});
        // quickM.push({"type":"orderDetails","action":"order","message":"Order Details"});
        quick_messages = quickM && quickM.filter((each)=>each.type!='extraDetails').map((qm, index) => {
          if(thread && thread.conversation_id && thread.source==='gmb' && !qm.action){
            return <QuickMessage key={index} message={qm} onClick={this.handleAIMessageClick} />
          }
          else if(thread && thread.source!='gmb'){
            return <QuickMessage key={index} message={qm} onClick={this.handleAIMessageClick} />
          }
         });
      }

      if(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)){
        return (
          <div className={this.props.theme == "light" ? "middlemain border-box bgwhite centeralign" : "middlemain border-box bgdark-theme3 centeralign"}>
          <div className={this.props.theme == "light" ? "chat shadow-box" : "chat shadow-box dark-theme"} id="messageContainer" ref={this.chatRef}><MiddleMain {...props} thread={thread}scrollChat={this.scrollToBottom} /></div>
          
          {/* <div className={this.state.showAI ? "chatai showing shadow-box bgwhite" : "chatai shadow-box bgwhite"} id="messageContainerAI">
            <div className="chataitop shadow-box bgwhite colorprim500span leftalign clearfix">Click to send a quick message</div>
          
            {quick_messages}

          </div> */}
          
          
          <div className={this.props.theme == "light" ? "chatinput border-box bgwhite leftalign clearfix" : "chatinput border-box bgdark-theme3 leftalign clearfix"}>
  
            
          </div>
          </div>
        );
      }else if(!this.props.isOnline){
        return (
          <div className={this.props.theme == "light" ? "middlemain border-box bgwhite centeralign" : "middlemain border-box bgdark-theme3 centeralign"}>
          <div className={this.props.theme == "light" ? "chat shadow-box ": "chat shadow-box dark-theme"} id="messageContainer" ref={this.chatRef}><MiddleMain {...props} thread={thread} scrollChat={this.scrollToBottom} /></div>
          
          {(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)) ? 
            (<React.Fragment />) : (thread && thread.is_typing_customer ? (<div className="chattyping shadow-box bgwhite colorprim500span leftalign clearfix">Customer is typing ...</div>) : (<React.Fragment />))
            }

          <div className={this.state.showAI ? "chatai showing shadow-box bgwhite" : "chatai shadow-box bgwhite"} id="messageContainerAI">
            <div className="chataitop shadow-box bgwhite colorprim500span leftalign clearfix">Click to send a quick message</div>
            
            {quick_messages}

          </div>
          {this.state.showScrollButton &&
            <div className="scroll-container" onClick={() => this.scrollToBottom(null, true)}>
              <div className="scroll-button">
                  {thread && thread.unread ?
                    <span className="unread-message">
                      <span className="unread-number">
                        {thread.unread!="0" ? thread.unread : null}
                      </span>
                    </span>
                    : null
                  }
              </div>
            </div>
          }
          <div className={this.props.theme == "light" ? "chatinput border-box bgwhite leftalign clearfix" : "chatinput border-box bgdark-theme3 leftalign clearfix"}>
            <div className="border-box clearfix centeralign" style={{cursor:'pointer'}} onClick={this.handleChatRestartClick}>
              <div className={this.props.theme == "light" ? "closed upper border-boxclearfix weight700 blackspan1" : "closed upper border-boxclearfix weight700 whitespan"}>
                You are marked offline.
              </div>
              <div className={this.props.theme == "light" ? "closed lower border-boxclearfix weight300 colorprim500span" : "closed lower border-boxclearfix weight300 blackspan9"}>
                Mark yourself online on the TopBar above to respond to messages.
              </div>
            </div>
          </div>
          </div>
        );
      }else{
        return (
          <>
          {this.state.showConfirmationBox.value && <ConfirmationBox showConfirmationLoader={this.state.showConfirmationLoader} handleCancel={this.handleCancel} handleYes={this.handleYes}/>}
          <div className={this.props.theme == "light" ? "middlemain border-box bgwhite centeralign" : "middlemain border-box bgdark-theme3 centeralign"}>
          <div className={this.props.theme == "light" ? "chat shadow-box" : "chat shadow-box dark-theme"} id="messageContainer" ref={this.chatRef} onScroll={this.handleScroll}><MiddleMain {...props} thread={thread} scrollChat={this.scrollToBottom} /></div>
          
          {(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)) ? 
            (<React.Fragment />) : (thread && thread.is_typing_customer ? (<div className={this.props.theme == "light" ? "chattyping shadow-box bgwhite colorprim500span leftalign clearfix" : "chattyping shadow-box bgdark-theme3 dark-theme colorprimdark500span leftalign clearfix"}>Customer is typing ...</div>) : (<React.Fragment />))
            }

          <div className={this.state.showAI ? (this.props.theme == "light" ? "chatai showing shadow-box bgwhite" : "chatai showing shadow-box bgdark-theme3 dark-theme") : (this.props.theme == "light" ? "chatai shadow-box bgwhite" : "chatai shadow-box bgdark-theme3 dark-theme")} id="messageContainerAI">
            <div className={this.props.theme == "light" ? "chataitop shadow-box bgwhite colorprim500span leftalign clearfix" : "chataitop shadow-box bgdark-theme3 colorprimdark500span dark-theme leftalign clearfix"}>Click to send a quick message</div>
            
            {quick_messages}

          <div className={this.props.theme == "light"?'bgwhite quick-buttons':'quick-buttons bgdark-theme3'}>
              {quickM && quickM.filter((each)=>each.type==='extraDetails').map((each)=>{
                  return <button key={each.title}     
                  onMouseOver={() => this.setState({isOver:each.title})}
                  onMouseOut={() => this.setState({isOver:''})}
                  className='quick-button' onClick={(message)=>this.handleAIMessageClick(each,each.type)}>{each.title}
                  <img width='16px' height='16px' src={ this.state.isOver === each.title ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/share-white.png' : 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/share-blue.png'}
       />
                  </button>
              })}
              { this.shouldShowInvoiceButton() && <button key='Send Invoice'
                  onMouseOver={() => this.setState({isOver:'Send Invoice'})}
                  onMouseOut={() => this.setState({isOver:''})}
                  className='quick-button' 
                  onClick={()=>
                    this.handleAIMessageClick({title:'Send Invoice',type:'sendInvoice'},'sendInvoice')}
                    >
                      Send Invoice
                  <img width='16px' height='16px' src={ this.state.isOver === 'Send Invoice' ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/share-white.png' : 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/share-blue.png'}
       />
              </button>}
          </div>  
          </div>
          {this.state.showScrollButton &&
            <div className="scroll-container" onClick={() => this.scrollToBottom(null, true)}>
              <div className="scroll-button">
                  {thread && thread.unread ?
                    <span className="unread-message">
                      <span className="unread-number">
                        {thread.unread!="0" ? thread.unread : null}
                      </span>
                    </span>
                    : null
                  }
              </div>
            </div>
          }
          { this.state.showAgentCommentPopup &&
            <div className="messageBox-mp">
            <div className="messageBox-Background">
              <div className="messageBox-text">Agent Comment:</div>
              <div style={{margin: "20px"}}>
                <div className="border-box centeralign fieldbox double">
                    <textarea onChange={(e) => this.setState({agentComment: e.target.value})} maxLength="50" type="text" name="refund-remark" placeholder="Add Comment Here" className="field border-box small"/>
                </div>
                {/* {this.state.showErrorBox &&
                  <div className="refund-error">{this.state.error}</div>
                } */}
              </div>
              <div className="messageBox-btn">
                <div onClick = {(e) => {
                    if(this.state.agentComment){
                        this.addAgentComment(thread.country)
                    }else{
                      NotificationManager.error(`Please select remarks`, 'Error', 5000);
                    }
                  }
                }>Yes</div>
                <div onClick={() => {
                  this.setState({showAgentCommentPopup:false}
                  )}}>No</div>
              </div>
          </div>
        </div>
          }
          
          {this.props.showCustomerDetailPopup && <div  ref={this.customerPopRef}className='hover-box' style={{height: this.state.customerDetailLoader?'340px':'auto'}}>
            <div className='flex-box-row'>
            <div className='header-customer-box'>Customer Overview:</div>
              <div style={{cursor: "pointer"}}>
                <img onClick={()=>this.props.handleCustomerOverview(!this.props.showCustomerDetailPopup )}src='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/cross.png' width='20px' height='20px'/>
              </div>
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Total No. of Cars till date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>{this.state.customerDetails && this.state.customerDetails.noOfCars}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Total No. of Orders till date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>{this.state.customerDetails && this.state.customerDetails.noOfOrders}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Total order value till date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>₹ {this.state.customerDetails && this.state.customerDetails.totalAmount}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Last order date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>{this.state.customerDetails && 
                this.state.customerDetails.lastorders && Constants.get_DateString2(
              this.state.customerDetails.lastorders.created_at)}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Last NPS value:</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>{this.state.customerDetails && this.state.customerDetails.feedback ?  this.state.customerDetails.feedback : "Not given"}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Last Service Buddy name and mobile :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>
                <div>{this.state.customerDetails && this.state.customerDetails.cr && this.state.customerDetails.cr.name}</div>
                <div>{this.state.customerDetails && this.state.customerDetails.cr && this.state.customerDetails.cr.mobile}</div>
              </div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Total Escalation raised till date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>{this.state.customerDetails && this.state.customerDetails.totalEscalaions}</div>}
            </div>
            <div className='flex-box-row'>
              <div className='custom-box-label'>Total Refund till date :</div>
              {this.state.customerDetailLoader?<div className='s-loader skel-linear-background'></div>:
              <div className='customer-box-value'>₹ {this.state.customerDetails && this.state.customerDetails.totalRefund}</div>}
            </div>
          </div>}
  
           {this.props.live && 
              <div className="agent-comment border-box clearfix">
                <div className={this.props.theme == "light" ? "border-box clearfix agentCommentButtonContainer" : "border-box clearfix details agentCommentButtonContainer"}>
                    {Constants.checkActionPermission('agent_comment') &&
                    <button onClick = {() =>{this.setState({showAgentCommentPopup: true})}}>
                      Add Agent Comment
                    </button>
                    }
                    {Constants.checkActionPermission('customer_overview') && thread && thread.orderDetails && thread.orderDetails.user_id &&
                    <button onClick = {() =>{
                      this.props.handleCustomerOverview(!this.props.showCustomerDetailPopup)
                      this.getCustomerDetails()
                      }} style={{marginLeft: "20px"}}>
                      View Customer Overview
                    </button>
                    }
                </div>
              </div>
           }
          <div className={this.props.theme == "light" ? "chatinput border-box bgwhite leftalign clearfix" : "chatinput border-box bgdark-theme3 leftalign clearfix"}>
            {this.props.live ? (<div className="border-box clearfix">
                <form method="post" action="" className="chatform" autoComplete="off" id="chatform" data-type='chat'>
                    {/* <div className="backbuttonbox border-box centeralign clearfix">
                      <table className="stdtable"><tr><td valign="center">
                        <button type="button" id="back" name="chatback_btn" className="btn btn_small btnwhite btncard move" data-type="">Back</button>
                      </td></tr></table>
                    </div> */}
                  <div className="aibuttonbox border-box leftalign clearfix">
                    <table className="stdtable">
                      <tbody>
                        <tr>
                          <td valign="center">
                            <button type="button" id="ai" name="ai_btn" className={this.props.theme == "light" ? "btn btn_small btnwhite btncard move" : "btn btn_small btndark-theme1 btncard move"} data-type="" style={{fontSize:'1.3rem'}} onClick={this.handleAIClick}>{this.state.showAI ? "-" : "+"}</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="aibuttonbox border-box leftalign clearfix" style={{ left: '44px' }}>
                    <table className="stdtable">
                      <tbody>
                          <tr>
                            <td valign="center">
                              <ImageUploader imageUploadButton={this.handleImageUpload} theme={this.props.theme} selectedTab={this.props.selectedTab}/>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="inputbox border-box leftalign clearfix" style={{ left: '100px' }}>
                      <table className="stdtable">
                        <tbody>
                            <tr>
                              <td valign="center">
                                  {/* <input type="text" name="sendie" id="sendie" placeholder="Type a message" className="field border-box small"/> */}
                                  <textarea name="sendie" id="sendie" placeholder="Type a message" className="field border-box small"  ref={this.sendieRef} onKeyDown={this._handleKeyDown} onChange={(e) => this.handleTextChange(e)}></textarea>
                              </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="inputbuttonbox border-box centeralign clearfix">
                      <table className="stdtable">
                        <tbody>
                            <tr>
                              <td valign="center">
                                <button type="button" id="send" name="chatsend_btn" className={this.props.theme == "light" ? "btn btn_small btnwhite btncard move" : "btn btn_small btndark-theme1 btncard move"} data-type="" onClick={this.handleSendClick}>Send</button>
                              </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                </form>
            </div>) : (<React.Fragment />)}
  
            
          </div>          
          </div>
          </>
        );
      }

        
    }
  }

class ConfirmationBox extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
        return (<div className="border-box  header">
        <div className="messageBox-mp">
          {this.props.showConfirmationLoader && <div className='escalation-loader'></div>}
            <div className="messageBox-Background bgdark-theme2" style={{width: "60%"}}>
                <div className="messageBox-text" style={{marginBottom: "20px"}}>Are you sure you want to send Details ?</div>
                <div className="messageBox-btn">
                <div onClick={() => !this.props.showConfirmationLoader && this.props.handleYes()}>Yes</div>
                <div onClick={() => !this.props.showConfirmationLoader && this.props.handleCancel()}>No</div>
                </div>                
            </div>
        </div>
    </div>
        )}
  }

  class QuickMessage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showConfirmationPopup: false,
        closingRemarks : sessionStorage.getItem("closing_remarks") ? JSON.parse(sessionStorage.getItem("closing_remarks")) : [],
        selectedRemarks : "",
        agentRemarks : "", 
        showErrorBox : false,
        error : ""
      }
      this.handleClick = this.handleClick.bind(this);
      this.handleCloseChat = this.handleCloseChat.bind(this);
      this.handleRemarkChange = this.handleRemarkChange.bind(this);
    }
  
      handleCloseChat(){
        this.setState({showConfirmationPopup : true});
      }
      handleClick(message, type) {
          if(!this.state.agentRemarks && type == "notification"){
              this.setState({showErrorBox : true, error : "Please fill remarks."});
              return;
          }
          this.props.onClick(message, type);
          this.setState({showConfirmationPopup:false})
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
      handleRemarkChange = (e) => {
        let remark =  e.target.value ? e.target.value.trim() : null;
        this.setState({showErrorBox : false, agentRemarks : remark});
    }
  
    render() {
      var that = this;
      if (this.props.message.type == "notification"){
          return(
            <React.Fragment>
              {this.state.showConfirmationPopup ? (
                  <div className="messageBox-mp">
                      <div className="messageBox-Background">
                        <div className="messageBox-text">Do you want to close ?</div>
                        <div className="" style={{margin: "20px"}}>
                          <React.Fragment>
                            <MultiSelect
                            options={sessionStorage.getItem("closing_remarks") ? JSON.parse(sessionStorage.getItem("closing_remarks")) : []}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Closing Remarks"
                            disabledItems={[]}
                            selectedOptions={this.state.selectedRemarks}
                            onInputClick={() => {}}
                            onChange={(value) =>
                              this.setState(
                                {
                                  selectedRemarks: value
                                }
                              )
                            }
                            />
                          </React.Fragment>
                        </div>
                        <div style={{margin: "20px"}}>
                          <div className="border-box centeralign fieldbox double">
                              <textarea onChange={(e) => this.handleRemarkChange(e)} maxLength="50" type="text" name="refund-remark" placeholder="Enter Customer Queries" className="field border-box small"/>
                          </div>
                          {this.state.showErrorBox &&
                            <div className="refund-error">{this.state.error}</div>
                          }
                        </div>
                        <div className="messageBox-btn">
                          <div onClick = {(e) => {
                              if(this.state.selectedRemarks && this.state.selectedRemarks.name){
                                  this.handleClick({
                                    message : that.props.message.message, 
                                    title : that.props.message.title, 
                                    notif_type:that.props.message.notif_type,
                                    remarks: this.state.selectedRemarks && this.state.selectedRemarks.name ? this.state.selectedRemarks.name : "",
                                    agentRemarks: this.state.agentRemarks && this.state.agentRemarks ? this.state.agentRemarks : "",
                                  }, that.props.message.type)
                              }else{
                                NotificationManager.error(`Please select remarks`, 'Error', 5000);
                              }
                            }
                          }>Yes</div>
                          <div onClick={() => {
                            this.setState({showConfirmationPopup:false}
                            )}}>No</div>
                        </div>
                    </div>
                  </div>
              ): null }
            <div className="aimessage clearfix border-box">
              
              <div className="leftalign border-box">
                <div className="text notification border-box clearfix leftalign" 
                  onClick = {(e) =>
                    this.handleCloseChat()
                  }
                  >
                  {that.props.message.title}
                </div>
              </div>
            </div>
            </React.Fragment>
          );
      }else{
        return(
          <div className="aimessage clearfix border-box"><div className="leftalign border-box"><div className="text border-box clearfix leftalign" onClick = {(e) => { 
            this.handleClick({
              message: that.props.message.message,
              action: that.props.message.action,
              remarks:"",
            }, that.props.message.type);
             }} >{that.props.message.message}</div></div></div>
        );
      }
      
      
    }
  }


  // const WrappedPanelMiddle = React.forwardRef((props, ref)  => {return (<PanelMiddle {...props} forwardedRef = {chatRef}/>) });
  export default PanelMiddle;