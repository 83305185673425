import React from 'react';
import Row from "./row";
import KeyArchiveRow from "./keyarchiverow";

class MiddleMainArchives extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
      // console.log(this.props);
      
    }

    componentDidUpdate() {
      // console.log("chatRef");
      // console.log(this.props.ref.current);
      // console.log("referring");
      // console.log(this.ref);
      // this.scrollToBottom();
      this.props.scrollChat();
    }

    scrollToBottom = () => {
      // this.props.chatRef.current.scrollTop = this.props.chatRef.current.scrollHeight;
    };
  
    render() {
        var that = this;
        // console.log(that.props.tag);
        
        if(this.props.selectedTab == 0){
          return (
            <React.Fragment />
          );    
        }else{
          var arr = [];
          //console.log("arr1", arr);
          // arr = this.props.allModels[this.props.selectedTab];
          arr = this.props.allModels;
          

          //console.log("arr", arr);
          if(arr){
            const rows = arr.map((obj) =>
              <Row theme={this.props.theme} data={obj} tag={that.props.tag} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText}
                prevModel={arr.indexOf(obj) == 0 ? null : arr[arr.indexOf(obj)-1]} nextModel={arr.indexOf(obj) == (arr.length-1) ? null : arr[arr.indexOf(obj)+1]} />
            );
            return (
              <React.Fragment>
                {rows}
              </React.Fragment>
            );
          }else{    
            return (
              <React.Fragment>
                
              </React.Fragment>
            );
          }
          
      }
    }
  }

export default MiddleMainArchives;