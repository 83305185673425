import React, {useEffect, useState, useContext } from 'react';
import API from '../../api';
import './Timeline.css';
import Constants,{getCurrencySymbol} from '../../constants';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { countryContext } from '../NewRightPannel/NewRightPanel';

const Timeline = (props) => {
    const orderId = props.content.order_id ? props.content.order_id : "";
    const phone = props.content.phone ? props.content.phone : "";
    const statusIdList = [5, 20, 30, 31, 40, 50];
    const { country } = useContext(countryContext)
    const [expand, setExpand] = useState(1);
    const [loader, setLoader] = useState(false);

    function expandDiv() {
        setExpand(!expand);
    }

   
    useEffect(() =>{
        if (orderId) {
            setLoader(true);
            API.getTimelineData(`leads/${orderId}/?mobile=${phone}`, country)
                .then(res => {
                    let response = res.data ? res.data : {};
                    const data = getOrderTimeLineStageStructure();
                    if (response.data && response.data.timeline && response.data.timeline.length) {
                        let lastState = response.data.timeline[response.data.timeline.length - 1];
                        const indexOfLastState = statusIdList.indexOf(lastState.status_id);
                        response.data.timeline.forEach((timelineState) => {
                            if (data[timelineState.status_id] && !data[timelineState.status_id].created_at) {
                                data[timelineState.status_id] = {
                                    ...timelineState,
                                    status: timelineState.status.toLowerCase(),
                                    is_complete: returnClass(timelineState.status_id)
                                }
                            }
                        })
                        statusIdList.forEach((status_id, index) => {
                            if (index < indexOfLastState) {
                                if (!data[status_id].created_at) {
                                    data[status_id] = {
                                        ...data[status_id],
                                        is_complete: returnClass(status_id)
                                    }
                                }
                            }
                        });
                        setOrderTimeLineStages({...data});
                        if (response.data.links) {
                            setLinks({...response.data.links})
                        } else {
                            setLinks({
                                "job_card": null,
                                "send_estimate": null,
                                "download_estimate": null,
                                "download_bill": null,
                                "send_bill": null
                            });
                        }
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log(err);
                    setLoader(false);
                });
        }

    },[orderId]);

    const getOrderTimeLineStageStructure = () => {
        return ({
            5: {
                "status_id": 5,
                "status": "new order",
                "amount": 0,
                "created_at": null
            },
            20: {
                "status_id": 20,
                "status": "at workshop",
                "amount": 0,
                "created_at": null
            },
            30: {
                "status_id": 30,
                "status": "diagnostic complete",
                "amount": 0,
                "created_at": null
            },
            31: {
                "status_id": 31,
                "status": "estimate sent",
                "amount": 0,
                "created_at": null
            },
            40: {
                "status_id": 40,
                "status": "work in progress",
                "amount": 0,
                "created_at": null
            },
            50: {
                "status_id": 50,
                "status": "ready for delivery",
                "amount": 0,
                "created_at": null
            }
        })
    }

    const [links, setLinks] = useState({
        "job_card": null,
        "send_estimate": null,
        "download_estimate": null,
        "download_bill": null,
        "send_bill": null
    });

    const [orderTimeLineStages, setOrderTimeLineStages] = useState({
        5: {
            "status_id": 5,
            "status": "new order",
            "amount": 0,
            "created_at": null
        },
        20: {
            "status_id": 20,
            "status": "at workshop",
            "amount": 0,
            "created_at": null
        },
        30: {
            "status_id": 30,
            "status": "diagnostic complete",
            "amount": 0,
            "created_at": null
        },
        31: {
            "status_id": 31,
            "status": "estimate sent",
            "amount": 0,
            "created_at": null
        },
        40: {
            "status_id": 40,
            "status": "work in progress",
            "amount": 0,
            "created_at": null
        },
        50: {
            "status_id": 50,
            "status": "ready for delivery",
            "amount": 0,
            "created_at": null
        }
    });

    const userAction = (params, type = '', message) => {
        setLoader(true);
        API.getInfoFromWorkForceApp({...params,country}, orderId, type).then((response) => {
            if(!response.status && response.errorMessage){
                setLoader(false);
                NotificationManager.error(`${response.errorMessage ? response.errorMessage : "Something went wrong"}`, 'Error', 5000);
                return;
            }
            if (response) {
                setLoader(false);
                NotificationManager.success(`${message}`, 'Successfully', 5000);
            }
        }).catch((error) => {
            if (error.message) {
                setLoader(false);
                NotificationManager.error(`Something went wrong`, 'Error', 5000);
            }
        })
    }


    const returnClass = (status) => {
        if (status < props.content.status_id) {
            return "done";
        } else if (status == props.content.status_id) {
            return "current";
        } else {
            return " ";
        }
    };
    

    return (
        <>
            <div className={props.theme == "light" ? "border-box blackspan65 leftalign " : "border-box ccspan leftalign "} style={{marginTop: "20px"}}>
            <button className="accordion" onClick={() => expandDiv()}>
              <div className="faq1">
                <div className="faq-ques-1">Order Timeline</div>
                <div className="heading-1 img5">
                  <img
                    className={expand ? "arrow" : "arrow rotated"}
                    id="togg"
                    src="/images/ic_expand_more_24px.png"
                    alt="img"
                  ></img>
                </div>
              </div>
            </button>
            {expand ? (
              <div className="panel" style={{ display: "block" }}>
                  <div className={props.theme == "light" ? "border-box  details blackspan65 leftalign " : "border-box  details ccspan leftalign "} style={{borderBottom : "none"}}>
                                    {/* <div className={props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>Order Timeline</div> */}
                                    <div className="orderTimelineContainerBox" style={{position:"relative"}}>
                                    { loader ? 
                                        <React.Fragment>
                                            <div className="modal-sb"></div>
                                            <div className="sub-modal-sb"></div>
                                        </React.Fragment>
                                    :
                                    ""
                                    }
                                        {
                                            // status.map((obj, index) => {
                                            //     return(
                                            //         <div className={`contentContainer ${returnClass(obj.id)}`}>
                                            //             <div className="content">
                                            //                 <div className="order-status-name font_14">{obj.name}</div>
                                            //                 <div className="margin_tb_6 grayFont font_10 TimelineDate">26-Aug-2021 06:08 PM</div>
                                            //             </div>
                                            //         </div>
                                            //     )
                                            // })
                                            Object.keys(orderTimeLineStages).map((orderStatus, index) => (
                                                <div key={index}
                                                     className={`contentContainer ${orderTimeLineStages[orderStatus].is_complete}`}>
                                                    <div className="content">
                                                        <div
                                                            className="order-status-name font_14">{orderTimeLineStages[orderStatus].status}</div>
                                                        <div className="margin_tb_6 grayFont font_10 TimelineDate">
                                                            {
                                                                orderTimeLineStages[orderStatus].created_at &&
                                                                Constants.get_DateString2(orderTimeLineStages[orderStatus].created_at) ? Constants.get_DateString2(orderTimeLineStages[orderStatus].created_at) : ""
                                                                // (moment(orderTimeLineStages[orderStatus].created_at).format("DD-MMM-YYYY hh:mm A"))
                                                            }
                                                        </div>
                                                        {
                                                            orderTimeLineStages[orderStatus].amount ?
                                                                <>
                                                                    <div className="grayFont font_12">Total</div>
                                                                    <div className="font_14">
                                                                        <b>{getCurrencySymbol(country)} {orderTimeLineStages[orderStatus].amount} /-</b>
                                                                    </div>
                                                                </> : ''
                                                        }
                                                        {
                                                            orderTimeLineStages[orderStatus].is_complete && orderTimeLineStages[orderStatus].status_id === 31 ?
                                                                <>
                                                                    {
                                                                        links.download_estimate ?
                                                                            <div className={'action-btn'}
                                                                                 onClick={() => userAction(links.download_estimate, 'estimate', 'Estimate Downloaded')}>
                                                                                View &amp; Download
                                                                            </div> : null
                                                                    }
                                                                    {
                                                                        links.send_estimate ?
                                                                            <div className={'action-btn'}
                                                                                 onClick={() => userAction(links.send_estimate, 'Estimate Sent')}>
                                                                                Send to Customer
                                                                            </div> : null
                                                                    }
                                                                </> : ''
                                                        }
                                                        {
                                                            orderTimeLineStages[orderStatus].status_id === 50 ?
                                                                <>
                                                                    {
                                                                        links.download_bill ?
                                                                            <div className={'action-btn'}
                                                                                 onClick={() => userAction(links.download_bill, 'bill' , 'Bill Downloaded')}>
                                                                                View Bill
                                                                            </div> : null}
                                                                    {
                                                                        links.send_bill ?
                                                                            <div className={'action-btn'}
                                                                                 onClick={() => userAction(links.send_bill, '', 'Bill Sent')}>
                                                                                Send Bill to Customer
                                                                            </div> : null
                                                                    }
                                                                </> : ''
                                                        }
                                                        {
                                                            orderTimeLineStages[orderStatus].is_complete && orderTimeLineStages[orderStatus].status_id === 30 &&
                                                            links.job_card ?
                                                                <>
                                                                    <div className={'action-btn'}
                                                                         onClick={() => userAction(links.job_card, 'job_Card', 'Job Card Downloaded')}>
                                                                        View Job Card
                                                                    </div>
                                                                </> : ''
                                                        }
                                                    </div>
                                                </div>))
                        
                                        }
                                    </div>
                                </div>

                  
              </div>
            ) : null}
          </div>
            
        </>
    )
}

export default Timeline