import React, { useState ,useEffect, useContext} from 'react'
import MultiSelect from "../../custom/MultiSelect";
import { NotificationManager} from 'react-notifications';
import API from '../../api';
import { countryContext } from '../NewRightPannel/NewRightPanel';
import Constants from "../../constants";
import './Escalation.css'

const currentUserId = localStorage.getItem("user_id")

const NewEscalation = ({orderId,selectedOrderStatus={},closeClick,orderType,skuCode=''}) => {

    const {country} = useContext(countryContext)
    const [getLoaded, setGetLoaded ] = useState(false)
    const [mainLoader,setMainLoader ] = useState({
        type1:true,
        type2: true,
        type3: true,
        getEscalationLoader:true,
        editLoader:false
    })
    const [isNewEscalation,setIsNewEscalation] = useState(false)
    const [escalationDetails, setEscalationDetails] = useState({
        remarks: '',
        priority: 'low',
        complaintForm1: {},
        complaintForm2: {},
        complaintForm3: {},
        previousRemarks:''
    })

    const [ complaintOptions, setComplaintOptions ] = useState({
        type1:[],
        type2:[],
        type3:[],
        filteredType2:[],
        filteredType3:[],
    })

    const [showButtonLoader, setShowButtonLoader] = useState(false)

    useEffect(() => {
        if(orderId && selectedOrderStatus){
        API.getComaplintTypes(orderId,'1',orderType===110?3:2,country).then((res)=>{
            if(res.data.status){
                setComplaintOptions((prev)=>({...prev,type1:res.data.data.filter((each)=>each.order_status.includes(selectedOrderStatus.value))}))
                setMainLoader((prev)=>({...prev,type1:false}))
            }
        })
        API.getComaplintTypes(orderId,'2',orderType===110?3:2,country).then((res)=>{
            if(res.data.status){
                setComplaintOptions((prev)=>({...prev,type2:res.data.data}))
                setMainLoader((prev)=>({...prev,type2:false}))
            }
        })
        API.getComaplintTypes(orderId,'3',orderType===110?3:2,country).then((res)=>{
            if(res.data.status){
                setComplaintOptions((prev)=>({...prev,type3:res.data.data}))
                setMainLoader((prev)=>({...prev,type3:false}))
            }
        })
    }
    },[orderId])

    useEffect(()=>{
        setShowButtonLoader(true)
        if(orderId){
            API.getRetailEscalation(orderId,country).then((each)=>{
                    if(each.data.status){
                            const lastRemarks = each.data.data && each.data.data.history && each.data.data.history.length>0 ? each.data.data.history[each.data.data.history.length-1]:{}
                            const orderDetail = each.data.data && each.data.data.order && each.data.data.order.length>0 ? each.data.data.order[0]:{}
                            let previousRemarks = each.data.data && each.data.data.history && each.data.data.history.length>0 ? each.data.data.history.reduce((str, val) => 
                            `${str} ${val.as_of ? `${Constants.get_DateString2(val.as_of)}: ` : ""} ${val.updated_by_obj ? `${val.updated_by_obj.name}: ` : ""} ${val.remark ? `${val.remark.trim()}` : ""} \n`
                        , ""):''
                            setIsNewEscalation(!!orderDetail.escalation_obj)
                            const type1Selected = orderDetail.escalation_obj && orderDetail.escalation_obj.type1_obj?orderDetail.escalation_obj.type1_obj:{}
                            const type2Selected = orderDetail.escalation_obj && orderDetail.escalation_obj.type2_obj?orderDetail.escalation_obj.type2_obj:{}
                            const type3Selected = orderDetail.escalation_obj && orderDetail.escalation_obj.type3_obj?orderDetail.escalation_obj.type3_obj:{}
                            setEscalationDetails((prev)=>({...prev,
                                remarks:lastRemarks.remark,
                                priority:orderDetail.escalation_obj && orderDetail.escalation_obj.priority_text ? orderDetail.escalation_obj.priority_text.toLowerCase() :'low',
                                complaintForm1:type1Selected,
                                complaintForm2:type2Selected,
                                complaintForm3:type3Selected,
                                escalation_obj:orderDetail.escalation_obj,
                                previousRemarks
                            }))
                            setGetLoaded(true)
                    }
                    setMainLoader((prev)=>({...prev,getEscalationLoader:false}))
                    setShowButtonLoader(false)
            })
            .catch(function (error) {
                setShowButtonLoader(false)
                console.log(error);
                NotificationManager.error('Something went wrong', 'Error', 5000);
            })
        }
    },[orderId])

    useEffect(() => {
        if(getLoaded && complaintOptions.type2 && complaintOptions.type3 && complaintOptions.type2.length>0  && complaintOptions.type3.length>0 ){
        const filteredData2 = complaintOptions.type2.filter((each)=>{
            return each.t2_to_t1_map.includes(escalationDetails.complaintForm1.id) 
            && each.order_status.includes(selectedOrderStatus.value)
        })
        setComplaintOptions((prev)=>({...prev,filteredType2:filteredData2}))
        let filteredData3 = complaintOptions.type3.filter((each)=>{
            return each.t3_to_t1_map.includes(escalationDetails.complaintForm1.id) &&
            each.t3_to_t2_map.includes(escalationDetails.complaintForm2.id) 
            && each.order_status.includes(selectedOrderStatus.value)
        })
        setComplaintOptions((prev)=>({...prev,filteredType3:filteredData3}))
        setGetLoaded(false)
        }
    },[complaintOptions.type2,complaintOptions.type3,getLoaded])
 
    const onChange = (value,key) => {
        setEscalationDetails((prevValue)=>({...prevValue,[key]:value}))
    }

    const getPriority = (priority) => {
        if(priority==='high'){
            return 1
        }
        else if(priority==='medium'){
            return 2
        }
        else if(priority==='low'){
            return 3
        }
    }

    const handleChange = (value,key,dropdownType) => {
        if(dropdownType==='type2'){
        const filteredData =  complaintOptions[dropdownType].filter((each)=>{
            return each.t2_to_t1_map.includes(value.id) 
            && each.order_status.includes(selectedOrderStatus.value)
        })
        setComplaintOptions((prev)=>({...prev,filteredType2:filteredData}))
        }
        else if(dropdownType==='type3'){
            const filteredData =  complaintOptions[dropdownType].filter((each)=>{
                return each && each.t3_to_t2_map && each.t3_to_t2_map.includes(value.id) 
                && each.t3_to_t1_map.includes(escalationDetails.complaintForm1.id) 
                && each.order_status.includes(selectedOrderStatus.value)
            })
            setComplaintOptions((prev)=>({...prev,filteredType3:filteredData}))
        }
        setEscalationDetails((prevValue)=>({...prevValue,[key]:value}))
    }

    const handleAddClick = () => {
        setShowButtonLoader(true)
        setMainLoader((prev)=>({...prev,editLoader:true}))
        let params = {
            order_id: orderId,
            status_id: 1,
            source_id: 10,
            remark: escalationDetails.remarks,
            escalation_type_1:escalationDetails.complaintForm1.id,
            escalation_type_2:escalationDetails.complaintForm2.id,
            escalation_type_3:escalationDetails.complaintForm3.id,
            priority:getPriority(escalationDetails.priority)
        };
        if(skuCode)
        params={...params,sku_code:skuCode}
        API.postRetailEscalation(params,orderId,country).then(function (response) {
            setShowButtonLoader(false)
            if (response.data.status){
                NotificationManager.success('Escalation saved', 'Successfully', 5000);
                closeClick();
            }
        })
        .catch(function (error) {
            setShowButtonLoader(false)
            setMainLoader((prev)=>({...prev,editLoader:false}))
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    }

    const handleUpdate = () => {
        setShowButtonLoader(true)
        setMainLoader((prev)=>({...prev,editLoader:true}))
        let params = {
            order_id: orderId,
            status_id: 8,
            source_id: 10,
            remark: escalationDetails.remarks,
            escalation_type_1:escalationDetails.complaintForm1.id,
            escalation_type_2:escalationDetails.complaintForm2.id,
            escalation_type_3:escalationDetails.complaintForm3.id,
            priority:getPriority(escalationDetails.priority),
            escalation_manager_id:parseInt(currentUserId)
        };
        if(skuCode)
        params={...params,sku_code:skuCode}
        if(escalationDetails.escalation_obj.status_obj.id===5)
        {
        API.putRetailEscalation(params,orderId,country).then(function (response) {
            setShowButtonLoader(false)
            if (response.data.status){
                NotificationManager.success('Escalation Updated', 'Successfully', 5000);
                closeClick();
            }
        })
        .catch(function (error) {
            setMainLoader((prev)=>({...prev,editLoader:false}))
            setShowButtonLoader(false)
            console.log(error);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
        }
        else{
            NotificationManager.error('Escalation Already Exist', 'Error', 5000);
            setShowButtonLoader(false)
            closeClick();
        }    
    }

    return (
        <div className="modalComponent" >
            <div className="modalBackground-nesc">
                {(mainLoader.type1 || mainLoader.type3 || mainLoader.type2 || mainLoader.getEscalationLoader || mainLoader.editLoader)&& <div className='escalation-loader'></div>}
                <div className='es-header es-flex-row'>
                    <div className='ml-auto'>Add Escalation</div>
                    <img onClick={()=>closeClick()} className='es-cross ml-auto' src='https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/cross.png' width='16px' height='16px' />
                </div>
                <div className='es-flex-row es-justify-between'>
                    <div className='es-details-box es-justify-between es-flex-col'>
                        <div className='es-flex-row es-justify-between es-font-16'>
                            <div>Order ID:</div>
                            <div className='es-font-black'>{orderId}</div>
                        </div>
                        <div className='es-flex-row es-justify-between es-font-16'>
                            <div>Escalation Source:</div>
                            <div className='es-font-black'>Chat Team</div>
                        </div>
                        <div className='es-flex-row es-justify-between es-font-16'>
                            <div>Order Status:</div>
                            <div className='es-font-black'>{selectedOrderStatus.name}</div>
                        </div>
                    </div>
                    <div className='es-complaints-box'>
                        <div className='es-complaints-box-header'>Complaint Details</div>
                        <div className='es-complaints-forms'>
                        <div className='w-full'>
                    
                    <MultiSelect
                    options={complaintOptions.type1}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select Type"
                    disabledItems={[]}
                    selectedOptions={escalationDetails.complaintForm1}
                    onChange={(value) =>{
                        setEscalationDetails((prev)=>({...prev,complaintForm2:{},complaintForm3:{}}))
                        handleChange(value, "complaintForm1",'type2')
                    }}
                    />
                        </div>
                        <div className='w-full'>
                        <MultiSelect
                    options={escalationDetails.complaintForm1.id?complaintOptions.filteredType2:[]}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select Type"
                    disabledItems={[]}
                    selectedOptions={escalationDetails.complaintForm2}
                    onChange={(value) =>{
                        setEscalationDetails((prev)=>({...prev,complaintForm3:{}}))
                        handleChange(value, "complaintForm2","type3")
                    }}
                    />
                        </div>
                        <div className='w-full'>
                        <MultiSelect
                    options={escalationDetails.complaintForm2.id?complaintOptions.filteredType3:[]}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select Type"
                    disabledItems={[]}
                    selectedOptions={escalationDetails.complaintForm3}
                    onChange={(value) =>
                        handleChange(value, "complaintForm3")
                    }
                    />
                        </div>
                        </div>
                    </div>
                </div>
                <div className='es-flex-row es-justify-between'>
                    <div className='es-remarks-box'>
                        <div className='es-remarks-box-header'>Remarks</div>
                        <textarea     
                                value={escalationDetails.remarks}    
                                onChange={(e) =>
                                        onChange(e.target.value, "remarks")
                             }
                    className='es-remarks-box-text-area' rows='2' placeholder='Enter Remarks'></textarea>
                    </div>
                    <div className='es-priority-box es-flex-col'>
                        <div className='es-priority-box-header'>Priority:</div>
                        <div className='es-flex-row prioity-box-content'>
                            <div className='priority-box-radio'>
                                <input className='priority-radio'type="radio" id="high" name="priority" 
                                value={escalationDetails.priority}  
                                checked={escalationDetails.priority === 'high'}
                                onChange={()=>onChange('high','priority')}
                                />
                                <label className={`priority-label ${escalationDetails.priority === 'high'&&'selected-priority-label'}`} for="high">High</label>
                            </div>
                            <div className='priority-box-radio'>
                                <input className='priority-radio'type="radio" id="medium" name="priority" value={escalationDetails.priority} 
                                    checked={escalationDetails.priority === 'medium'}
                                    onChange={()=>onChange("medium",'priority')}
                                />
                                <label className={`priority-label ${escalationDetails.priority === 'medium'&&'selected-priority-label'}`} for="medium">Medium</label>
                            </div>
                            <div className='priority-box-radio'>
                                <input className='priority-radio' type="radio" id="low" name="priority" value={escalationDetails.priority} 
                                   checked={escalationDetails.priority === 'low'}
                                   onChange={()=>onChange('low','priority')} 
                                />
                                <label className={`priority-label ${escalationDetails.priority === 'low'&&'selected-priority-label'}`} for="low">Low</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='es-flex-row es-justify-between'>
                    <div className='es-prev-remarks-box'>
                     <div>Previous Remarks</div>   
                    <textarea value={escalationDetails.previousRemarks} placeholder='Previous Remarks' className='es-prev-remarks-box-text-area' disabled={true} rows='4'/>
                    </div>
                </div>
                <div className='es-flex-row es-lower'>
                    <button onClick={()=>closeClick()} className='es-cancel-button'>CANCEL</button>
                    <button onClick={()=>isNewEscalation?handleUpdate(): handleAddClick()} disabled={showButtonLoader} className='es-add-button'>ADD</button>
                </div>
            </div>
        </div>
    )
}

export default NewEscalation