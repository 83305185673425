import React from 'react';
// import "../dashboard/dashboard.css"
import Constants from "../constants";
import Loader from "../loader";
import Escalation from './escalation/Escalation';
import ChatTransfer from './chatTransfer/ChatTransfer';
import RefundPopup from './refundPopup/RefundPopup';
import SubscriptionBox from './subscriptionBox/SubscriptionBox';
import Voucher from './voucher/Voucher';
import Timeline from './timeline/Timeline';
import NewOrder from './newOrder/NewOrder';
import API from '../api';
import Copy from './copy.svg';
import Copy2 from './copy1.png';

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand : false,
            downloadBill: false,
            isManager: (   localStorage.getItem("role") == "manager" || 
                        localStorage.getItem("user_id") == "204358" ||
                        localStorage.getItem("user_id") == "204359" ||
                        localStorage.getItem("user_id") == "174721"
                        ) ? true :false
        }
        this.handleClick = this.handleClick.bind(this);
        this.clickEscalationBtn = this.clickEscalationBtn.bind(this);
        this.billDownload = this.billDownload.bind(this);
        this.handleActions = this.handleActions.bind(this);
    }
    clickEscalationBtn(event, orderId, amount, action){
        this.props.addEscalation(orderId, amount, action);
        event.stopPropagation();
    }

    handleClick() {
        console.log("RightXXXXXOrder");
        // console.log(this.props.order);
        this.props.onRightOrderClick({...this.props.order, ...{goAppEarning : this.props.goAppEarning}});
        /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
    }
    showFile(pdfData, name){
        var anhor = document.createElement("a");
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        anhor.href = url;
        anhor.download = name;
        anhor.click();
        this.setState({downloadBill: false});
    };
    billDownload(event, orderId){
        if(!this.state.downloadBill){
              event.stopPropagation();
            API.downloadBill(orderId)
              .then((res) => res.blob())
              .then((res) => this.showFile(res, "Bill_" + orderId))
              .catch((err) => this.setState({downloadBill: false}));
              this.setState({downloadBill: true});
          }
        //   event.stopPropagation();
    }
    handleActions(e, source, text){
        let obj = {};
        obj['source'] = source;
        obj['text']   = text;
        obj['orderId'] = this.props.order.order_id;
        obj['refundId'] = this.props.order && this.props.order.refund ? this.props.order.refund['refund_id'] : "NA";
        obj['refundAmount'] = this.props.order && this.props.order.refund ? this.props.order.refund['amount_refunded'] : "0";
        obj['orderType'] = this.props.order && this.props.order.refund ? this.props.order.order_type : "101";
        obj['amount'] = this.props.order && this.props.order.amount ? this.props.order.amount : "";
        
        this.props.handleAccountAction(obj);
        e.stopPropagation();
    }

    render() {
        // console.log("statuses ", this.props.statuses);
        // console.log(this.props.order.order_type, this.props.status_id);

        // console.log("statuses22222", this.props.statuses);
        
                var status = Constants.getOrderStatus(this.props.order.order_type, this.props.order.status_id);
                var update_items = ""; var update_services =  "";
                if(this.props.order.update_items && this.props.order.update_items.length > 0){
                for (var i = 0; i < this.props.order.update_items.length; i++){
                    var item = this.props.order.update_items[i];
                    // console.log(item);
                    if (i === 0){
                        update_items+=(item.package_name ? item.package_name : "");
                        update_services += (item.service_name ? item.service_name : "")
                    }else{
                        if(item.package_name!="")
                            update_items+= (item.package_name ? update_items ?  ", "+item.package_name : item.package_name : "");
                        if(item.service_name)
                            update_services+= (item.service_name ? update_services ? ", "+ item.service_name : item.service_name : item.service_name);
                    }
                }
                }
                if (this.props.order.update_items && this.props.order.update_items.length == 0){
                    if(this.props.order.initial_problem){
                        var items = this.props.order.initial_problem.split("|");
                        items.forEach(function(item){
                            if(item.startsWith(" Title:")){
                                update_items += item.substring(7, item.length);
                            }
                        });
                    }
                }
                let orderType = this.props.order.order_type ? this.props.order.order_type : "0";
                let status_id = this.props.order.status_id ? this.props.order.status_id : "0";
                let complete_status = status_id == 21 ? 'Cancelled' : status_id < 90 ? 'Ongoing' : 'Completed';
                let pickup_status = status_id == 21 ? 'Cancelled' : status_id < 20 ? 'Ongoing' : 'Completed';
                let pickup_date = this.props.order.pick_date ? (this.props.order.pick_date.$date ? Constants.get_DateString1Date(this.props.order.pick_date.$date) : Constants.get_DateString1Date(this.props.order.pick_date)) : "NA";
                let pickup_time = status_id != 21 ? pickup_date && this.props.order.time_slot ? `${pickup_date} ${this.props.order.time_slot}` : pickup_date : "NA";
                let showDownloadBtn = ((orderType == 101 || orderType == 107) && status_id >= 50) || (orderType == 103 && status_id >= 1200) ? true : false;
        return(
            <>
            <div className={this.props.theme == "light" ? "border-box clearfix card bgwhite addboxes commutes leftalign orderMain blackspan7" : "border-box clearfix card bgdark-theme1 addboxes commutes leftalign orderMain ddspan"} onClick={() => {this.handleClick()}}>
                <div className={this.props.theme == "light" ? "flexBox border-box clearfix leftalign title weight500 uppercased blackspan7" : "flexBox border-box clearfix shadow-box leftalign title weight500 uppercased"}>
                    <div className="border-box clearfix left">
                        <div className="border-box clearfix leftalign weight600">
                            {this.props.order.order_id}
                        </div>
                        <div className="border-box clearfix leftalign weight400 code">
                        <span style={{textTransform:'none'}}>Placed on</span> {this.props.order.created_at ? (this.props.order.created_at.$date ? Constants.get_DateString2(this.props.order.created_at.$date) : Constants.get_DateString2(this.props.order.created_at)) : ""}
                        </div>
                    </div>
                    <div className="border-box clearfix rightalign right weight700 orderBoxStatus">
                        <span className="position">
                            {status}
                        </span>
                    </div>
                </div>
                <div className={this.props.theme == "light" ? "border-box clearfix leftalign details blackspan9 orderSecondBox" : "border-box clearfix leftalign details bbspan orderSecondBox"}>
                    {this.props.order.car && 
                    <div className="orderMiddle">
                        <img className="car-logo" src={this.props.order.car ? this.props.order.car.car_tbl.model.image_path : ""} width="50" />
                        <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                            <div className={this.props.theme == "light" ? "regNo" : "regNo1"}> {this.props.order.car ? this.props.order.car.registration_no : ""} </div>
                            <div className={this.props.theme == "light" ? "carName" : "carName1"}>{this.props.order.car ? this.props.order.car.car_tbl.brand.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.model.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.car_type.name : ""}</div>
                        </div>
                    </div>
                    }
                    <div className="orderDates">
                        <div>
                            <div className="orderDetailItem1">Pick up Time</div>
                            <div className="orderDetailItem2">{pickup_time}</div>
                        </div>
                        <div className="orderDetailItem3">
                            <div className={`orderDetailItem2 orderStatus  ${pickup_status == "Completed" ? "orderStatusComplete" : "orderStatusOngoing"}`}>{pickup_status}</div>
                        </div>
                    </div>
                    <div className="orderDates">
                        <div>
                            <div className="orderDetailItem1">Order Completed</div>
                            <div className="orderDetailItem2">{this.props.order.delivery_date ? (this.props.order.delivery_date.$date ? Constants.get_DateString2(this.props.order.delivery_date.$date) : Constants.get_DateString2(this.props.order.delivery_date)) : "NA"}</div>
                        </div>
                        <div className="orderDetailItem3">
                            <div className={`orderDetailItem2 orderStatus ${complete_status == "Completed" ? "orderStatusComplete" : "orderStatusOngoing"}`}>{complete_status}</div>
                        </div>
                    </div>
                    <div className="orderPackages">
                        <div>
                            <div className="orderDetailItem1">Package</div>
                            <div className="orderDetailItem2">{update_items ? update_items : "NA"}</div>
                        </div>
                        <div>
                            <div className="orderDetailItem1">Parts & Services</div>
                            <div className="orderDetailItem2">{update_services}</div>
                        </div>
                    </div>
                </div>
                <div style={{borderTop: "1px solid #dddddd"}} className={this.props.theme == "light" ? "border-box clearfix details orderSecondBox" : "border-box clearfix details orderSecondBox"}>
                        {/* <div className="border-box clearfix middle leftalign" style={{ marginLeft: '0.5rem' }}>
                            <div className={this.props.theme == "light" ? "regNo" : "regNo1"}> {this.props.order.car ? this.props.order.car.registration_no : ""} </div>
                            <div className={this.props.theme == "light" ? "carName" : "carName1"}>{this.props.order.car ? this.props.order.car.car_tbl.brand.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.model.name : ""} {this.props.order.car ? this.props.order.car.car_tbl.car_type.name : ""}</div>
                        </div> */}
                        <div className="orderDetailInner">
                            <div className="orderDetailItem1">
                                Amount:
                            </div>
                            <div className="orderDetailItem2">
                                &#x20B9; {this.props.order.amount ? this.props.order.amount : "NA"} 
                            </div>
                        </div>
                        <div className="orderDetailInner">
                            <div className="orderDetailItem1">
                                Payment Status:
                            </div>
                            <div className="orderDetailItem2">
                                {this.props.order.payment_status ? this.props.order.payment_status : "NA"} 
                            </div>
                        </div>
                        {Constants.checkActionPermission('order_actions') && <div className="orderDetailInner"onClick={(e) => e.stopPropagation()} style={{marginTop: "10px", justifyContent:"space-between"}}>
                            <a className={this.props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"} target="_blank" href={`https://central.dashboard.gomechanic.app/#/orders/show/${this.props.order.order_id}?orderOf=gomechanic`}>
                                Order Details
                            </a>
                            <a className={this.props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"} target="_blank" href={`https://lead.gomechanic.app/master-lead/${this.props.order.phone}?lead_id=${this.props.order.lead_id}&lead_type=general&lead_type_id=undefined`}>
                                Lead Details
                            </a>
                            {showDownloadBtn &&
                                <div onClick={(e) => this.billDownload(e, this.props.order.order_id)} className={this.props.theme == "light" ? "userDetailInner downloadBill" : "userDetailInner downloadBill2"}>
                                    {this.state.downloadBill ? "Downloading..." : "Download Bill"}
                                </div>
                            }
                        </div>}
                </div>
                
                    <React.Fragment>
                        <div style={{borderTop: "1px solid #dddddd"}} className={this.props.theme == "light" ? "border-box clearfix details orderSecondBox" : "border-box clearfix details orderSecondBox"}>
                                <div className="orderDetailInner" style={{justifyContent: "space-between"}} onClick={(e) => {this.setState({expand : !this.state.expand}); e.stopPropagation();}}>
                                    <div className="actionDetailItem1">
                                        Actions:
                                    </div>
                                    <div className="actionDetailItem2" >
                                        View More
                                        <img className={this.state.expand ? "view-more" : "view-more expand"} loading="lazy" src="/images/expand_more.svg" alt="expand"></img>
                                    </div>
                                </div>
                        </div>
                        { this.state.expand ?
                        <div className={this.props.theme == "light" ? "border-box clearfix actionsButtonContainer" : "border-box clearfix details actionsButtonContainer"}>
                            <button onClick={(e) => {this.handleActions(e, "voucher","Do you want to send Voucher?")}}>
                                Free Escalation Vouchers
                            </button>
                            <button onClick={(e) => {this.clickEscalationBtn(e, this.props.order.order_id, 0, 'escalation')}}>
                                Add Escalation
                            </button>
                            {this.state.isManager &&
                            <React.Fragment>
                                <button onClick={(e) => {this.handleActions(e, "cancellation","Do you want to cancel the order?")}}>
                                    Cancel Order
                                </button>
                                <button onClick={(e) => {this.handleActions(e, "gomoney","Do you want to add go app money?")}}>
                                    Add Refund / GoApp Money
                                </button>
                            </React.Fragment>
                            }
                        </div>
                        :""
                        }  
                    </React.Fragment>
            </div>
            </>
        );
    }
}

class PanelRight2 extends React.Component {
    constructor(props) {
        super(props);
        this.clickOrderBtn = this.clickOrderBtn.bind(this);
    }

    clickOrderBtn(orderId, amount, action){
        this.props.handleEscalationOnclick(orderId, amount, action);
    }

    render() {
        if(!this.props.showContent2){
            return (
                <React.Fragment />
            );
        }else{
            // console.log(typeof(this.props.content2));
            // console.log(typeof(this.props.content2));
            // console.log(this.props.content2);
            
            // console.log("statuses11111", this.props.statuses);
            
            // console.log("content2", this.props.content2);
            // console.log("content2", this.props.content2.length);
            const orders = this.props.content2 ? 
            this.props.content2.map(
            (order,key) => 
                <Order 
                index={key}
                key={order.order_id}
                isOnline = {this.props.isOnline} 
                theme={this.props.theme} 
                order={order} goAppEarning={this.props.goAppEarning} 
                onOrderClick={this.props.onOrderClick} 
                onRightOrderClick={this.props.onRightOrderClick} 
                statuses={this.props.statuses} 
                addEscalation={this.clickOrderBtn}
                handleAccountAction={this.props.handleAddAmount}
                />
            ) : [];
            if(this.props.showLoader){
                return(
                <React.Fragment>
                <div className={this.props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300" : "border-box clearfix cars ccspan leftalign weight300"}>
                                <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix eespan leftalign weight500"}>Orders</div>
                            </div>
                            <div className={this.props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                                {orders}
                            </div>
                            <Loader fullHeight={false}/>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    <div style={{ border: 'none' }} className={this.props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300 flexBox" : "border-box clearfix cars ccspan leftalign weight300 flexBox"}>
                        <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500 orderHead" : "border-box clearfix eespan leftalign weight500"}>Orders</div>
                    </div>
                    {/* <NewOrder 
                        theme={this.props.theme}
                        orderContent={this.props.content1}
                        mobile={this.props.selectedTab}
                        leadCreated={this.props.leadCreated}
                        leadDetails={this.props.thread && this.props.thread.leadDetails ?  this.props.thread.leadDetails : {}}
                    />  */}
                    <div className={this.props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                            {orders}
                    </div>
                    </React.Fragment>
            );
        }
    }
}


class PanelRight1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            escalation : false,
            orderId: '',
            copyText : 'Copy to clipboard',
        };
        this.handleBackClick = this.handleBackClick.bind(this);
        this.closeEscalation = this.handleCloseEscalation(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handleSubscriptionData = this.handleSubscriptionData.bind(this);
    }
    handleBackClick() {
        this.props.onBackOrderClick();
        /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
    }
    handleSubscriptionData(details,header){
        return {header,
            data:details.map((detail)=>({...detail, 
            expires_at:Constants.get_DateString2(new Date(detail.expires_at),true),
            started_at:Constants.get_DateString2(new Date(detail.started_at),true),
            amount:detail.cars && detail.cars.deal_car_price_mapping && detail.cars.deal_car_price_mapping.price,
            carDetails:{
                car_image:detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model && detail.cars.car_tbl.model.image_path,
                car_number:detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model&& detail.cars.car_tbl.modelregistration_no,
                car_name:(detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.brand)+' '+
                   (detail.cars && detail.cars.car_tbl && detail.cars.car_tbl.model && detail.cars.car_tbl.model.name)
            },
            coverage:{
                year:detail.coverage && detail.coverage.year,
                distance:detail.coverage && detail.coverage.year,
            },
            isActive:(new Date()).getTime()<=(new Date(detail.expires_at)).getTime()
        }))}
    }
    handleCloseEscalation() {
        this.setState({escalation:!this.state.escalation});
    }
    handleCopy(text, id){
        if(text){
            var r = document.createRange();
            r.selectNode(document.getElementById(id));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            this.setState({copyText : `Copied: ${text}`});
        }
    }
    render() {
        // let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);

        if(!this.props.showContent1){
            return (
                <React.Fragment />
            );
        }else if(this.props.showRightOrder){
                var status =  Constants.getOrderStatus(this.props.content.order_type, this.props.content.status_id);
                var filteredfleets = this.props.fleets && this.props.fleets.length > 0 ?  this.props.fleets.filter(o => (o.id == this.props.content.fleet_id)) : "NA";
                var fleet = "NA";
                if(filteredfleets.length > 0){
                    fleet = filteredfleets[0].name;
                }
                var update_items = "";
                if(this.props.content.update_items && this.props.content.update_items.length > 0){
                for (var i = 0; i < this.props.content.update_items.length; i++){
                    var item = this.props.content.update_items[i];
                    // console.log(item);
                    if (i === 0){
                        update_items+=(item.package_name ? item.package_name : item.service_name);
                    }else{
                        update_items+=", "+ (item.package_name ? item.package_name : item.service_name);
                    }
                }
                }
                    if(this.props.content.initial_problem){
                        var items = this.props.content.initial_problem.split("|");
                        items.forEach(function(item){
                            if(item.startsWith(" Title:")){
                                update_items += item.substring(7, item.length);
                            }
                        });
                    }
                
            return (
                <React.Fragment>
                    {/*
arrival_mode: 2
delivery_time: "11:00"
fleet_id: 8
initial_problem: null
invoice_number: null
labour_cost: 0
lead_estimate_items: []
lead_id: "L2019109353615"
material_cost: 0
payments: []
update_items: (2) [{…}, {…}]
updated_at: {$date: 1570640488000} */}
                    <div className="border-box clearfix header"><div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}><table className="stdtable"><tbody><tr><td valign="center">
                                    <span style={{textTransform:'none'}}>Placed on</span> {this.props.content.created_at ? (this.props.content.created_at.$date ? Constants.get_DateStringX(this.props.content.created_at.$date) : Constants.get_DateStringX(this.props.content.created_at)) : ""}
                                </td></tr></tbody></table></div><div className={this.props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}><table className="stdtable"><tbody><tr><td valign="center"></td></tr></tbody></table></div></div>

                                <div className="border-box clearfix buttons"><div className={this.props.theme == "light" ? "border-box blackspan9 weight500" : "border-box aaspan weight500"}><table className="stdtable"><tbody><tr><td valign="center">
                                    <button className={this.props.theme == "light" ? "btn btn_small btncolorprim btncard move btnbackorder" : "btn btn_small btncolorprimdark btncard move btnbackorder"} onClick={this.handleBackClick}>Back</button>
                                </td></tr></tbody></table></div>
                                
                                <div className={this.props.theme == "light" ? "border-box blackspan9 weight500 rightalign" : "border-box aaspan weight500 rightalign"}>
                                
                                </div></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix prime blackspan4 leftalign weight700" : "border-box clearfix prime whitespan leftalign weight700"}>{this.props.content.car && this.props.content.car.car_tbl.brand.name} {this.props.content.car && this.props.content.car.car_tbl.model.name} {this.props.content.car && this.props.content.car.car_tbl.car_type.name}</div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name blackspan65 leftalign weight500" : "border-box clearfix name ccspan leftalign weight500"}><span style={{textTransform:'none'}}>Reg. No.: </span><span className="weight700">{this.props.content.car && this.props.content.car.registration_no}</span></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Location: </span><span className="weight700">{this.props.content.location}</span></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Status: </span><span className="weight700">{status}</span></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Amount: </span><span className="weight700">&#x20B9; {this.props.content.amount}</span></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>CR Assigned: </span><span className="weight700">{this.props.content.cr && this.props.content.cr.name? this.props.content.cr.name : "Not assigned"}</span></div>
                                <div className={this.props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Payment status: </span><span className="weight700">{this.props.content.payment_status}</span></div>
                                
                                <Timeline 
                                    theme={this.props.theme}
                                    content={this.props.content}
                                />
                                
                                <div className={this.props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}>
                                    <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>Order Details</div>
                                    <div className="border-box clearfix">
                                        <div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order ID</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {this.props.content.order_id}
                                                </div>
                                            </div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order Type</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {Constants.orderTypes()[this.props.content.order_type]}
                                                </div>
                                            </div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Go App Earning</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {this.props.content.goAppEarning}
                                                </div>
                                            </div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Pickup</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {this.props.content.pick_date ? (this.props.content.pick_date.$date ? Constants.get_DateString2(this.props.content.pick_date.$date) : Constants.get_DateString2(this.props.content.pick_date)) : ""}
                                                </div>
                                            </div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Address</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {this.props.content.address}
                                                </div>
                                            </div>
                                            <div className="border-box clearfix orderIdMain">
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdText" : "border-box inner orderIdText1"}>Order items</div>
                                                <div className={this.props.theme == "light" ? "border-box inner orderIdSubText" : "border-box inner orderIdSubText1"}>
                                                    {update_items}
                                {/* </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tbody><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type}
                                </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Login Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                {this.props.content1.login_type} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </React.Fragment>
            );
        }else{
            let status = Constants.getOrderStatus(this.props.lastOrder.order_type, this.props.lastOrder.status_id);;
            let cr = this.props.lastOrder.cr ? this.props.lastOrder.cr.name : ""
            let car  = this.props.lastOrder.car ? `${this.props.lastOrder.car.car_tbl.brand.name} ${this.props.lastOrder.car.car_tbl.model.name} ${this.props.lastOrder.car.car_tbl.car_type.name}`: "";
            let orderType = this.props.lastOrder.order_type ? this.props.lastOrder.order_type : "0";
            let status_id = this.props.lastOrder.status_id ? this.props.lastOrder.status_id : "0";
            let latestOrderId = this.props.lastOrder.order_id ? this.props.lastOrder.order_id : "";
            return (
                <React.Fragment>
                    <div className="border-box clearfix header">
                        <div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}>
                            <table className="stdtable">
                                <tbody><tr>
                                    <td valign="center">
                                        <span style={{textTransform:'none'}}>Joined on</span> {this.props.content1.created_at ? (this.props.content1.created_at.$date ? Constants.get_DateStringX(this.props.content1.created_at.$date) : Constants.get_DateStringX(this.props.content1.created_at)) : ""}
                                    </td>
                                </tr></tbody>
                            </table>
                        </div>
                        <div className={this.props.theme == "light" ? "blackspan9 weight500" : "aaspan weight500"}>
                            <table className="stdtable">
                                <tbody><tr>
                                    <td valign="center"></td>
                                </tr></tbody>
                            </table>
                        </div>
                    </div>
                    {this.props.content1 && this.props.content1.amc_details && this.props.content1.amc_details.length > 0 && 
                    <div className="border-box header" style={{marginBottom: "10px", marginTop:"0px"}}>
                        <div className={this.props.theme == "light" ? "amc-strip" : "amc-strip-dark"}>
                            <span className="amc-label">Miles Member</span>
                        </div>
                    </div>
                    }
                    <div className="lastOrderDetailContainer">
                            <ChatTransfer  onTransferChat={this.props.onTransferChat} selectedTab={this.props.selectedTab} />
                            
                            <div className="border-box fieldbox double">
                                <a style={{width:"95%", height:"25px"}} className={this.props.theme == "light" ? "send-voucher-btn" : "send-voucher-btn send-voucher-btn-dark"} href={`/chat-archives/${this.props.selectedTab}?executive=true&user_access=${sessionStorage.getItem("user_access") || 'retail'}`} target="_blank">Archive Chat Link</a>
                            </div>
                    </div>
                    {/* <div className="border-box" style={{display: "flex", margin: "1rem 0px", textAlign: "left"}}>
                        <div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}>
                            <a className={this.props.theme == "light"? "blackspan4" : "ccspan" } href={`/chat-archives/${this.props.selectedTab}?executive=true`} target="_blank">Archive Chat Link</a>
                        </div>
                        {
                            (   localStorage.getItem("role") == "manager" || 
                                localStorage.getItem("user_id") == "204358" ||
                                localStorage.getItem("user_id") == "204359" || 
                                localStorage.getItem("user_id") == "174721"
                            )
                            &&
                        <div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}>
                            <div className={this.props.theme == "light"? "blackspan4 cancel-btn" : "ccspan cancel-btn" } onClick={()=>this.props.handleAddAmount("cancellation","Do you want to cancel the order?")}>Cancel Order</div>
                        </div>
                        }
                    </div> */}
                    {/* <div className="border-box" style={{margin: "1rem 0px", textAlign: "left"}}>
                    <button style={{marginTop:"5px",width:"50px"}}  className="add-escalation-es">Refund Amount</button> 
                    </div> */}
                    {
                        this.props.isEscalation && 
                            <div className="border-box clearfix header">
                                <div className={this.props.theme == "light" ? "blackspan9" : "aaspan"}>
                                    <Escalation 
                                        orderId={this.props.orderId}
                                        closeClick={() => {this.props.handleEscalationOnclick() }}    
                                    />
                                </div>
                            </div> 

                    }
                                 {this.props.content1 && this.props.content1.amc_details && this.props.content1.amc_details.length > 0 && <div className='lastOrderDetailContainer'style={{marginTop : "15px"}}>
                                 <SubscriptionBox subscriptionDetail={this.handleSubscriptionData(this.props.content1.amc_details,'Miles')} /> 
                                 </div>}

                                {this.props.content1 && this.props.content1.obd_details && this.props.content1.obd_details.length > 0 && <div className='lastOrderDetailContainer'style={{marginTop : "15px"}}>
                                 <SubscriptionBox subscriptionDetail={this.handleSubscriptionData(this.props.content1.obd_details,'OBD')} /> 
                                 </div>}   

                                 {/* {this.props.content1 && this.props.content1.warranty_details && <div className='subs-card lastOrderDetailContainer'style={{marginTop : "15px"}}>
                                 <SubscriptionBox subscriptionDetail={this.handleSubscriptionData(this.props.content1.warranty_details,'Warranty')} /> 
                                 </div>} */}

                                <div className="lastOrderDetailContainer" style={{marginTop : "15px"}}>
                                    <div className="flexBox">
                                        <div className={this.props.theme == "light" ? "border-box clearfix customer-name" : "border-box clearfix prime customer-name"}>{this.props.content1.name}</div>
                                        {this.props.totalOrder == 1 &&
                                            <span className="orderNoLabel">1st Order</span>
                                        }
                                    </div>
                                    <div className={this.props.theme == "light" ? "border-box userDetailItem2 orderStatus1" : "border-box userDetailItem2 orderStatus1"}>
                                        <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/phone.png" alt="phoneIcon" className="contactIcon"/>
                                        <span id="customer-mobile" style={{textTransform:'none'}}>{this.props.content1.mobile}</span>
                                        <div className="tooltip">
                                            <div className="copy-btn" onClick = {() => {this.handleCopy(this.props.content1.mobile, "customer-mobile")}}>
                                                <span className="tooltiptext" id="myTooltip">{this.state.copyText}</span>
                                                <img src={this.props.theme == "light" ? Copy : Copy2} alt="Copy" className="copyImg" />
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.content1.email &&
                                        <div className={this.props.theme == "light" ? "border-box userDetailItem2 orderStatus1" : "border-box userDetailItem2 orderStatus1"}>
                                            <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/chat/mail.png" alt="phoneIcon" className="contactIcon"/>
                                            <span id="customer-email" style={{textTransform:'none'}}>
                                                {this.props.content1.email}
                                            </span>
                                            <div className="tooltip">
                                                <div className="copy-btn" onClick = {() => {this.handleCopy(this.props.content1.email, "customer-email")}}>
                                                    <span className="tooltiptext" id="myTooltip">{this.state.copyText}</span>
                                                    <img src={this.props.theme == "light" ? Copy : Copy2} alt="Copy" className="copyImg" />
                                                </div>
                                            </div>  
                                        </div>
                                    }
                                    <div className="userDetailInner">
                                                <div className="border-box userDetailItem1 orderStatus1">
                                                    Order Status :
                                                </div>
                                                <div className="userDetailItem2 orderStatus1">
                                                {status}
                                                </div>
                                            </div>
                                            {cr &&
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1 orderStatus1">
                                                    CR Assigned :
                                                </div>
                                                <div className="userDetailItem2 orderStatus1">
                                                    {cr}  
                                                </div>
                                            </div>
                                            }
                                            {car &&
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1 orderStatus1">
                                                    Last Car :
                                                </div>
                                                <div className="userDetailItem2 orderStatus1">
                                                    {car}
                                                </div>
                                            </div>
                                            }
                                </div>
                                <NewOrder 
                                    theme={this.props.theme}
                                    orderContent={this.props.content1}
                                    mobile={this.props.selectedTab}
                                    leadCreated={this.props.leadCreated}
                                    leadDetails={this.props.thread && this.props.thread.leadDetails ?  this.props.thread.leadDetails : {}}
                                /> 
                                {/* <div className={this.props.theme == "light" ? "lastOrderDetailContainer bgwhite" : "lastOrderDetailContainer bgdark-theme1" } style={{margin: "20px"}}>
                                    <Voucher 
                                        onSendVoucher={this.props.onSendVoucher} 
                                        selectedTab={this.props.selectedTab} 
                                        theme={this.props.theme}
                                        mobile={this.props.selectedTab}
                                        sentVoucher={this.props.sentVoucher}
                                    />
                                </div> */}
                                {/* <div style={{ borderTop: 'none' }} className={this.props.theme == "light" ? "border-box clearfix details blackspan65 leftalign weight300" : "border-box clearfix details ccspan leftalign weight300"}>
                                        <div className={this.props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix ddspan leftalign weight500"}>
                                        User Details
                                        </div>
                                        <div className="border-box clearfix">
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1">
                                                    GoApp Money :
                                                </div>
                                                <div className="userDetailItem2">
                                                    {this.props.content1.go_app_money}  
                                                </div>
                                            </div>
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1">
                                                    GoApp Earning :
                                                </div>
                                                <div className="userDetailItem2">
                                                    {this.props.content1.go_app_earning}  
                                                </div>
                                            </div>
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1">
                                                    Login Type :
                                                </div>
                                                <div className="userDetailItem2">
                                                    {this.props.content1.login_type} 
                                                </div>
                                            </div>
                                            <div className="userDetailInner">
                                                <div className="userDetailItem1">
                                                    OS Type :
                                                </div>
                                                <div className="userDetailItem2">
                                                    {this.props.thread ? this.props.thread.source : ""}
                                                </div>
                                            </div>
                                        </div>
                                </div> */}
                    </React.Fragment>
            );
        }
    }
}


class PanelRight extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            escalation : false,
            orderId: '',
            showRightContent1: false,
            showRightContent2: false,
            rightContent1: NaN,
            rightContent2: NaN,
            showLoader:false,
            rightOrder: NaN,
            showRefundPopUp: false,
            paidAmount:0,
            source: "",
            popupHeader:"",
            totalRefund : "",
            orderType : "",
            refundId : "",
            
        };
      this.handleEcalation = this.handleEcalation.bind(this);
      this.handleRightScroll = this.handleRightScroll.bind(this);
      this.handleRightOrderClick = this.handleRightOrderClick.bind(this);
      this.handleBackOrderClick = this.handleBackOrderClick.bind(this);
      this.handleTransferChat = this.handleTransferChat.bind(this);
      this.handleSendVoucher = this.handleSendVoucher.bind(this);
      this.handleOrderActions = this.handleOrderActions.bind(this);
    }

    handleRightScroll(tag, orders) {
        API.getOrderDetailsPaginate(this.props.selectedTab, this.state.rightContent2, this);
        // API.tabClickCall(tag, that);
    }
    handleRightOrderClick(order) {
        this.setState({rightOrder : order});
        // API.tabClickCall(tag, that);
    }
    handleBackOrderClick() {
        var that = this;
        console.log("RightRightOrder");
        this.setState({rightOrder : NaN});
    }

    handleScroll = (e) => {
        // console.log("scrolling1");
        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
        // console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight)
        if (bottom && this.state.rightContent2 && this.state.rightContent2.length !== 0 && !this.state.showLoader) { console.log("scrolling"); this.handleRightScroll();}
    }
    handleEcalation(orderId, amount, action){
        if(action == "escalation")
            this.setState({escalation: !this.state.escalation, orderId: orderId});
        else if(action == "refund")
            this.setState({showRefundPopUp: !this.state.showRefundPopUp, paidAmount: amount, orderId: orderId});   
        else
            this.setState({showRefundPopUp: false, escalation: false});           
    }
    handleTransferChat(){
        this.setState({rightContent1:NaN, rightContent2: NaN, showRightContent1: false, showRightContent2: false});
        this.props.transferChat();
    }
    handleSendVoucher(text){
        this.props.sendVouchers(text);
    }
    handleOrderActions(obj){
        console.log('handleOrderActions',obj);
        this.setState({
            showRefundPopUp: true,
            source: obj.source,
            popupHeader : obj.text,
            orderId : obj.orderId,
            totalRefund : obj.refundAmount,
            orderType : obj.orderType,
            refundId : obj.refundId,
            paidAmount: obj.amount ? obj.amount : 0,
        })
    }
    componentDidUpdate(prevProps){
        if (prevProps.selectedTab !== this.props.selectedTab && this.props.selectedTab!=0) {
            API.getChatUserDetails(this.props.selectedTab, this);
            API.getOrderDetailsPaginate(this.props.selectedTab, [], this);
        }
    }
  
    render() {
        let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);

        if(!this.state.showRightContent1 && !this.state.showRightContent2){
            if(this.state.showLoader){
                return (
                    <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"} onScroll={this.handleScroll}>
                        <Loader theme={this.props.theme} fullHeight={true}/>
                    </div>
                );    
            }else{
                if(this.state.rightContent1 == "no_user"){
                    return(
                        <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"} onScroll={this.handleScroll}>
                            <table className="stdtable"><tbody><tr><td valign="center">
                                No existing user.
                            </td></tr></tbody></table>
                        </div>
                    );
                }else{
                    return (
                        <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box dark-theme"} onScroll={this.handleScroll}>
                            <table className="stdtable"><tbody><tr><td valign="center">
                                Select a thread on the left to view details.
                            </td></tr></tbody></table>
                        </div>
                    );
                }
            }
        }else{
            var statuses = JSON.parse(localStorage.getItem("statuses"));
            var fleets = JSON.parse(localStorage.getItem("fleets_summary"));
            // var statuses = JSON.parse(localStorage.getItem("statuses"));
            //             var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
            //             var status = "NA";
            //             if(filteredstatuses.length > 0){
            //                 status = filteredstatuses[0].status;
            //             }
            // console.log(this.props.content);

            // console.log("RightOrder");
            // console.log(this.props.rightOrder);

            if(this.state.rightOrder){
                return (
                    <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"} onScroll={this.handleScroll}>
                        <PanelRight1 selectedTab={this.props.selectedTab} theme={this.props.theme} thread = {thread} showContent1 = {true} showRightOrder = {true} content = {this.state.rightOrder} onBackOrderClick={this.handleBackOrderClick} statuses={statuses} fleets={fleets} />
                        {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                        </td></tr></table></div></div></div></div></div> */}
                    </div>
                );
            }else{
                return (
                    <React.Fragment>
                    {
                    this.state.showRefundPopUp && 
                    <RefundPopup 
                        userName={this.state.rightContent1 && this.state.rightContent1.name ? this.state.rightContent1.name : "Customer"}
                        userId={this.state.rightContent1 && this.state.rightContent1.id ? this.state.rightContent1.id : ""}
                        goMoney={this.state.rightContent1 && this.state.rightContent1.go_app_money ? this.state.rightContent1.go_app_money : "0"}
                        orderId={this.state.orderId ? this.state.orderId : "No Order"}
                        refundId={this.state.refundId}
                        totalRefund={this.state.totalRefund} 
                        paidAmount={this.state.paidAmount}
                        theme={this.props.theme}
                        source={this.state.source}
                        header={this.state.popupHeader}
                        orderType={this.state.orderType}
                        closeClick={() => {this.setState({showRefundPopUp: false})}}    
                        selectedTab={this.props.selectedTab}
                        onSendVoucher={this.handleSendVoucher}
                        sentVoucher={this.props.sentVoucher}
                    />  
                    }
                    <div id="dashboardchatmainright" className={this.props.theme == "light" ? "rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box" : "rightmainpanel border-box bgdark-theme2 eespan weight500 shadow-box"} onScroll={this.handleScroll}>
                        
                        
                        <PanelRight1 
                            selectedTab={this.props.selectedTab}
                            theme={this.props.theme}
                            thread = {thread}
                            showContent1 = {this.state.showRightContent1}
                            content1 = {this.state.rightContent1}
                            lastOrder = {this.state.rightContent2 ? this.state.rightContent2[0] : {}}
                            showRightOrder = {false}
                            statuses={statuses}
                            fleets={fleets}
                            orderId={this.state.orderId}
                            handleEscalationOnclick={this.handleEcalation}
                            isEscalation={this.state.escalation}
                            onTransferChat={this.handleTransferChat}
                            // onSendVoucher={this.handleSendVoucher}
                            // handleAddAmount={(source, text) => {this.setState({showRefundPopUp: true, source: source, popupHeader : text})}}
                            // sentVoucher={this.props.sentVoucher}
                            leadCreated={this.props.leadCreated}
                            totalOrder={this.state.rightContent2 ? this.state.rightContent2.length : 0}
                            />
                        <PanelRight2 
                            isOnline = {this.props.isOnline}
                            theme={this.props.theme}
                            showContent2 = {this.state.showRightContent2}
                            content1 = {this.state.rightContent1}
                            content2 = {this.state.rightContent2}
                            goAppEarning = {this.state.rightContent1.go_app_earning ? this.state.rightContent1.go_app_earning : "0"}
                            showLoader={this.state.showLoader}
                            onRightOrderClick={this.handleRightOrderClick} 
                            statuses={statuses} fleets={fleets} 
                            handleEscalationOnclick={this.handleEcalation}
                            handleAddAmount={(obj) => this.handleOrderActions(obj)}
                            isEscalation={this.state.escalation}
                            // leadCreated={this.props.leadCreated}
                            // thread = {thread}
                        />
                        {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                        </td></tr></table></div></div></div></div></div> */}
                        
                    </div>
                    </React.Fragment>
                );
            }
            
            // if (this.props.content2.length > 0){
                
            // }else{
            //     return (
            //         <div id="dashboardchatmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
            //             <PanelRight1 showContent1 = {this.props.showContent1} content1 = {this.props.content1} />
            //             <PanelRight2 showContent2 = {this.props.showContent2} content2 = {this.props.content2} />
            //             {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">                        
            //             </td></tr></table></div></div></div></div></div> */}
            //         </div>
            //     );
            // }
                    
        }
    }
  }

export default PanelRight;