import React from 'react';
import "./login/account1.css"

import API from './api';
import Constants from './constants';
import {Redirect} from 'react-router-dom';

const axios = require('axios');










class PanelLogout extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }
  
    render() {
      return (
        <div className="account_postsignup clearfix">
            <h1 className="colorprim500span centeralign">Goodbye :(</h1>
            <p className="colorprimlight500span centeralign">Logging you out ...</p>
        </div>
      );
    }
  }













class LogoutMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {status : 'logging out'};
  }

  componentWillMount() {

    }

  componentDidMount() {
    var that = this;

    if(localStorage.getItem("access_token") === null || new Date(localStorage.getItem("token_expiration_date")) <= new Date()){
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_expiration_date");
        that.setState({status : "logged out"});
    }else{
        API.logout().then(function (response) {
            console.log(response.data);
            if(response.data.status){
              // localStorage.removeItem("user_id");
              // localStorage.removeItem("custom_token");
              //   localStorage.removeItem("access_token");
              //   localStorage.removeItem("token_expiration_date");
              API.postSessionDetails(false);  
              localStorage.clear();
              sessionStorage.removeItem("reloaded");
              that.setState({status : "logged out"});    
            }else{
                that.setState({status : 'error'});
            }
            })
            .catch(function (error) {
            // handle error
            console.log(error);
            })
            .finally(function () {
            // always executed
            });
    }
  }

  componentWillUnmount() {

  }

  render() {
      if(this.state.status == "logged out"){
        return <Redirect to="/login"/>
      }else{
        return (
            <React.Fragment>
                <div id="accounttopbuffer"></div>
                <div id="accountmaindiv" className="border-box">
                    <div className="account_header border-box centeralign">
                        <div className="border-box shadow-box bgwhite">
                            <PanelLogout />
                        </div>
                    </div>
                </div>
            </React.Fragment>
            // <div id="containermainouter1">
            // 	<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab}/>
            // 	<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
            // 	<PanelMiddle tag={this.state.selectedTab} selectedRow={this.state.selectedRow} content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick}/>
            // </div>
        );
      }
  }
}


export default LogoutMain;
