import React from 'react'
// import "./dashboard/dashboard.css"
import RowArchive from "./rowArchive";
import Constants from "../constants";

class KeyArchiveRow extends React.Component {
    constructor(props) {
      super(props);
  
    //   this.handleClick = this.handleClick.bind(this);
    }

    // componentDidMount() {
    //     console.log("chatRef1");
    //     // console.log(this.props.ref.current);
    //     console.log("referring");
    //     console.log(this.ref);
    //     // this.scrollToBottom();
    //     this.props.scrollChat();
    //   }
  
    //   handleClick() {
    //     //   console.log("clicking");
    //       this.props.onRowClick(this.props.data, this.props.tag);
    //       /*this.setState(state => ({
    //         isToggleOn: !state.isToggleOn
    //       }));*/
    //   }
  
    render() {
        var that = this;

        var chatList = this.props.data.chat;
        const ordered = {};
        Object.keys(chatList).sort().forEach(function(key) {
            ordered[key] = chatList[key];
        });

        var arr = [];
        var count = 0;
        for(var key in ordered){
            count += 1;
            var message = ordered[key];
            var textH = '';
            var textM = '';
                
            if (message.notification){
                textH = message.type.toUpperCase();
                textM = message.text;
                
            } else{
                textH = '';
                textM = message.text;
            }

            var message= {orderID:message.orderID,
                        senderName:message.senderName,
                        senderUserID:message.senderUserID,
                        time:message.time,
                        notification:message.notification,
                        type:message.type,
                        senderInternalID : message.senderInternalID,
                        textH : textH,
                        textM : textM,
                        action: message.action,
                        action_type:message.action_type,
                        logic : message.logic
                        }
                arr.push(message);
        }
        
        const rows = arr.map((obj, index) =>
              <RowArchive key={index} theme={this.props.theme} data={obj} tag={that.props.tag} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText}
                prevModel={arr.indexOf(obj) == 0 ? null : arr[arr.indexOf(obj)-1]} nextModel={arr.indexOf(obj) == (arr.length-1) ? null : arr[arr.indexOf(obj)+1]} />
            );

        // const se_users = JSON.parse(sessionStorage.getItem("se_users"));
        // var senderInternalID = parseInt(this.props.data.senderInternalID);
        // var senderInternalName = null;
        // if (senderInternalID in se_users){
        //     senderInternalName = se_users[senderInternalID]['name'];
        // }

        return(
            <div className="keyArchiveBox border-box clearfix">
                <div className={this.props.theme == "light" ? "keyArchiveBoxInner border-box clearfix bgwhite1" : "keyArchiveBoxInner border-box clearfix bgdark-theme1"}>
                    <div className={this.props.theme == "light" ? "header border-box clearfix weight600 blackspan1 leftalign" : "header border-box clearfix weight600 whitespan1 leftalign"}>
                        <span className="floatleft weight500">Started : &nbsp;&nbsp;</span><span className="floatleft weight600"> {this.props.data.created_at && this.props.data.created_at.$date ? Constants.get_DateStringX(this.props.data.created_at.$date) : this.props.data.created_at ? Constants.get_DateStringX(this.props.data.created_at) : Constants.get_DateStringX(this.props.data.last_updated)}</span>
                    </div>
                    <div className="chats border-box clearfix">
                        {rows}    
                    </div>
                    
                </div>
            </div>
        );
        
        // $(".chat").append(innerHTML);

        // $('.chat').scrollTop($('.chat').prop("scrollHeight"));
        
    }
  }

export default KeyArchiveRow;