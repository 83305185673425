import React from 'react';
import SupportChatArchivesByKey from './support_chat/support_chat_archive_by_key';
import {Helmet} from "react-helmet";
import HttpsRedirect from 'react-https-redirect';


class AppChatArchives extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {key : this.props.match.params.key};
    
    // this.changeTheme = this.changeTheme.bind(this);
  }

  // changeTheme(theme){
  //   this.setState({theme : theme});
  //   localStorage.setItem("theme", theme);
  // }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>GoMechanic Support</title>
        </Helmet>
      {/* <div id="containermain1">
      <Topbar /> */}

      <SupportChatArchivesByKey theme="light" mobile={this.props.phoneno} executiveLink={this.props.isExecutiveLink} userAccess={this.props.userAccess} country={this.props.country}/>
      {/* </div> */}
      </React.Fragment>
    );
  }
}


export default AppChatArchives;

// function App() {
//   console.log("protocol", window.location.protocol);
//   console.log("href", window.location.href);
//   // if (window.location.protocol !== 'https:') {
//   //   window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
//   //   return // to stop app from mounting
//   // }
//   return (
//     <React.Fragment>
//       <Helmet>
//         <title>GoMechanic Support</title>
//       </Helmet>
//     {/* <div id="containermain1">
//     <Topbar /> */}

//     <SupportChat />
//     {/* </div> */}
//     </React.Fragment>
//   );
// }

// export default App;
