import React, {useState , useContext} from 'react';
import API from '../../api';
import {NotificationManager} from 'react-notifications';
import PopupWrapper from './PopupWrapper';
import 'react-notifications/lib/notifications.css';
import Voucher from '../voucher/Voucher';
import { countryContext } from '../NewRightPannel/NewRightPanel.js'
import { getCurrencySymbol, disposition } from '../../constants' 
import MultiSelect from "../../custom/MultiSelect";

const RefundPopup = (props) => {
    const orderId = props && props.orderId ? props.orderId : "";
    const skew_code = props && props.skewId ? props.skewId : "";
    const orderType = props && props.orderType ? props.orderType : "";
    const [refundAmount, setRefundAmount] = useState();
    const [remarks, setRemarks] = useState();
    const [showErrorBox, setErrorBox] = useState(false);
    const [error, setError] = useState();
    const [refund, setRefund] = useState(false);
    const [headerText, setHeaderText] = useState(props.header);
    const [isRefund, setRefundFlag] = useState(false);
    const { country } = useContext(countryContext)
    const [dispositionOption, setDisposition] = useState();

    const handleRefund = () => {
        if(!refund){
            if(!refundAmount){
                setErrorBox(true);
                setError("Please fill refund amount.");
                return;
            }else if(refundAmount == "0"){
                setErrorBox(true);
                setError("Please fill refund amount.");
                return;
            }
            if(!remarks){
                setErrorBox(true);
                setError("Please fill remarks.");
                return;
            }
            setRefund(true);

            if(!isRefund){
                addGoAppMoney();
                return;
            }else{
                console.log('refundAmount',refundAmount);
                // return;
                API.intitateRefund(orderId, refundAmount, remarks, country).then(function (response) {
                    console.log(response,'response');
                    if(response.status){
                        NotificationManager.success(`Refund Initiated`, 'Successfully', 5000);
                        props.closeClick();
                        // setRefund(false);
                        cancelOrder();
                        return;
                    }else{
                        NotificationManager.error(`Please try again`, 'Error', 5000);
                        setRefund(false);
                    }
                })
                .catch(function (error) {
                    // handle error
                    NotificationManager.error(`Please try again`, 'Error', 5000);
                    console.log(error);
                    setRefund(false);
                });
            }
        }
    }

    const cancelOrder = () => {
        if(!refund && !skew_code){
            if(!dispositionOption){
                setErrorBox(true);
                setError("Please fill disposition.");
                return;
            }
            if(!remarks){
                setErrorBox(true);
                setError("Please fill remarks.");
                return;
            }
            setRefund(true);

            let obj={
                "order_id": orderId,
                "order_type" : orderType,
                "remark" : remarks,
                "sub_status_id" : dispositionOption && dispositionOption.id ? dispositionOption.id : null
            }
            console.log(obj,'obj');
            // return;
            API.cancelOrder(obj,country).then(function (response) {
                console.log(response,'response');
                if(response.data.status){
                    NotificationManager.success(`Order Cancelled`, 'Successfully', 5000);
                    props.closeClick();
                    setRefund(false);
                }else{
                    NotificationManager.error(`Please try again`, 'Error', 5000);
                    setRefund(false);
                }
            })
            .catch(function (error) {
                // handle error
                NotificationManager.error(`Please try again`, 'Error', 5000);
                console.log(error);
                setRefund(false);
            });
        }
        else if(!refund){
            setRefund(true);
            let obj={
                "order_id": orderId,
                "sku_code": skew_code,
                "remark":remarks,
            }
            API.cancelAccessOrder(obj,country).then(function (response) {
                if(response.data.status){
                    NotificationManager.success(`Order Cancelled`, 'Successfully', 5000);
                    props.closeClick();
                    setRefund(false);
                }else{
                    NotificationManager.error('Please Try again', 'Error', 5000);
                    setRefund(false);
                }
            })
            .catch(function (error,response) {
                // handle error
                NotificationManager.error(error.response && error.response.data ?error.response.data.message:'Please Try again', 'Error', 5000);
                console.log(error);
                setRefund(false);
            });
        }
    }

    const addGoAppMoney = () => {
        let d = new Date();
        d.setMonth(d.getMonth() + 3);
        let expiryDate = d.toISOString().slice(0, 10);
        let obj = {};
        obj['user_id'] = props.userId;
        obj['amount'] = refundAmount;
        obj['expiry_date'] = expiryDate;
        obj['source'] = "Credited";
        obj['remark'] = "Chat Support";
        obj['agent_id'] = localStorage.getItem("user_id");
        obj['agent_remarks'] = remarks;
        obj['country'] = country
        console.log(obj,'obj');
        API.addGoAppMoney(obj).then(function (response) {
            console.log(response,'response');
            if(response.status){
                NotificationManager.success(`GoApp Money Added`, 'Successfully', 5000);
                props.closeClick();
                setRefund(false);
            }else{
                NotificationManager.error(`Please try again`, 'Error', 5000);
                setRefund(false);
            }
        })
        .catch(function (error) {
            // handle error
            NotificationManager.error(`Please try again`, 'Error', 5000);
            console.log(error);
            setRefund(false);
        });
    }

    const handleRadioToggle = (event) => {
        setRefundFlag(event.target.checked);
        if(event.target.checked){
            setHeaderText("Do you want to intiate refund ?");
        }else{
            setHeaderText("Do you want to add go app money?");
        }
    }

    const handleRemarkChange = (e) => {
        let remark =  e.target.value ? e.target.value.trim() : null;
        setRemarks(remark);
        setErrorBox(false);
    }

    return (
        <>
            <PopupWrapper headerText={headerText}>
                    <React.Fragment>
                    {(() => {
                                switch (props.source) {
                                case 'voucher':
                                    return(
                                        <div style={{marginTop:"20px"}}>
                                            <Voucher 
                                                onSendVoucher={props.onSendVoucher} 
                                                selectedTab={props.selectedTab} 
                                                theme={props.theme}
                                                mobile={props.selectedTab}
                                                sentVoucher={props.sentVoucher}
                                                closeBtn={props.closeClick}
                                            />
                                        </div>
                                    )
                                case 'cancellation':
                                    return(
                                        <React.Fragment>
                                            <div style={{display:"flex",marginTop:"20px"}}>
                                                <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Order ID: </span><span className="weight700">{props.orderId}</span></div>
                                                <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"} style={{marginLeft:'25px'}}><span style={{textTransform:'none'}}>Username: </span><span className="weight700">{props.userName}</span></div>
                                            </div>
                                            <div style={{margin: "20px"}}>
                                                <MultiSelect                
                                                    options={disposition()}
                                                    labelKey="sub_status"
                                                    checkbox_id="sub_status"
                                                    placeholder="Select Disposition"
                                                    disabledItems={[]}
                                                    selectedOptions={dispositionOption}
                                                    onInputClick={() => {}}
                                                    onChange={(value) =>
                                                    setDisposition(value)
                                                    }
                                                /> 
                                            </div>
                                            <div className="" style={{margin: "20px"}}>
                                                <div className="border-box centeralign fieldbox double" style={{marginTop: "20px"}}>
                                                    <textarea onChange={(e) => handleRemarkChange(e)} maxLength="50" type="text" name="refund-remark" placeholder="Enter Remarks" className="field border-box small"/>
                                                </div>
                                                {showErrorBox &&
                                                <div className="refund-error">{error}</div>
                                                }
                                            </div>
                                            <div className="messageBox-btn">
                                                <div onClick={() => cancelOrder()}>
                                                {refund ? (
                                                    <div className="loader-hc"></div>
                                                ) : (
                                                    <span>Yes</span>
                                                )}
                                                </div>
                                                <div onClick={() => props.closeClick()}>No</div>
                                            </div>
                                        </React.Fragment>
                                    )
                                default:
                                    return(
                                <React.Fragment>
                                <div style={{ display: 'flex', margin: '20px' }}>
                                    <div className="flexContainerCenter">
                                        Add Refund : 
                                    </div>
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="toggleOTPSwitch"
                                        id={`toggleSwitchMoney`}
                                        onChange={(e) => handleRadioToggle(e)}
                                        defaultChecked={false}
                                        // checked={is_online}
                                    />
                                    <label className="toggle-switch-label" htmlFor={`toggleSwitchMoney`}>
                                        <span className="toggle-switch-inner" />
                                        {/* <span className="toggle-switch-switch" /> */}
                                    </label>
                                </div>
                                <div className="" style={{margin: "20px"}}>
                                    <div style={{display:"flex"}}>
                                        <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>{ isRefund ? `Paid Amount : `:`GoApp Money : `} </span><span className="weight700">{getCurrencySymbol(country)} { isRefund ? props.paidAmount : props.goMoney}</span></div>
                                        <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"} style={{marginLeft:'40px'}}><span style={{textTransform:'none'}}>{ isRefund ? `Order ID : `:`User : `} </span><span className="weight700">{ isRefund ? orderId : props.userName}</span></div>
                                    </div>
                                    { isRefund &&
                                    <div style={{display:"flex",marginTop:"20px"}}>
                                        <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"}><span style={{textTransform:'none'}}>Refund ID: </span><span className="weight700">{props.refundId}</span></div>
                                        <div className={props.theme == "light" ? "border-box clearfix name1 blackspan65 leftalign weight300" : "border-box clearfix name1 ccspan leftalign weight300"} style={{marginLeft:'25px'}}><span style={{textTransform:'none'}}>Refund Amount: </span><span className="weight700">{getCurrencySymbol(country)} {props.totalRefund}</span></div>
                                    </div>
                                    }
                                    <div className="border-box centeralign fieldbox double"  style={{marginTop: "20px"}}>
                                        <input type="number" 
                                            // onKeyDown={(e) => {
                                            //     var currentValue = String.fromCharCode(e.which);
                                            //     console.log(currentValue,'e.target.value',e.target.value,'e.target.value',e.which)
                                            //     if(e.which == 8){
                                            //         console.log('a')
                                            //         var finalValue = e.target.value.slice(0, -1);
                                            //     }
                                            //     else if((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)){
                                            //         console.log('b')
                                            //         var finalValue = e.target.value + currentValue;
                                            //     }else{
                                            //         var finalValue = e.target.value + currentValue;
                                            //     }
                                            //     if(finalValue!= undefined && isRefund && finalValue > props.paidAmount) {
                                            //         console.log('c')
                                            //         e.preventDefault();
                                            //         setErrorBox(true);
                                            //         setError("Refund amount cannot be greater than paid amount");                                               
                                            //     }else if(finalValue!= undefined){
                                            //         console.log('d')
                                            //         setRefundAmount(finalValue);
                                            //         setErrorBox(false);
                                            //     } 
                                            // }} 
                                            onChange={(e)=>{
                                                if(e.target.value && isRefund && e.target.value > props.paidAmount){
                                                    e.preventDefault();
                                                    setErrorBox(true);
                                                    setError("Refund amount cannot be greater than paid amount");  
                                                }else{
                                                    setRefundAmount(e.target.value);
                                                    setErrorBox(false);
                                                }
                                            }}
                                            value={refundAmount &&  refundAmount > 0 ? refundAmount : ""} max={props.paidAmount} maxLength="10" name="refund-amount" placeholder={isRefund ? "Enter Refund Amount" : "Enter Goapp Money"} className="field border-box small"/>
                                    </div>
                                    <div className="border-box centeralign fieldbox double" style={{marginTop: "20px"}}>
                                        <textarea onChange={(e) => handleRemarkChange(e)} maxLength="50" type="text" name="refund-remark" placeholder="Enter Remarks" className="field border-box small"/>
                                    </div>
                                    {showErrorBox &&
                                    <div className="refund-error">{error}</div>
                                    }
                                </div>
                                <div className="messageBox-btn">
                                    <div onClick={() => handleRefund()}>
                                    {refund ? (
                                        <div className="loader-hc"></div>
                                    ) : (
                                        <span>Yes</span>
                                    )}
                                    </div>
                                    <div onClick={() => props.closeClick()}>No</div>
                                </div>
                                </React.Fragment>
                                    )
                                }
                            })()}
                        </React.Fragment>
                </PopupWrapper>
        </>
    )
}

export default RefundPopup