import React,{ useState } from 'react';
import Loader from "../../loader";
import Order from './Order.js'

const PanelRight2 = (props) => {
    

        const clickOrderBtn = (orderId, amount, action, statusId,orderType,skuCode) => {
            props.handleEscalationOnclick(orderId, amount, action, statusId, orderType, skuCode);
        }
        const orders = props.content2 ? 
        props.content2.map(
        (order,key) => 
            <Order 
            index={key}
            key={order.order_id}
            isOnline = {props.isOnline} 
            theme={props.theme} 
            order={order} goAppEarning={props.goAppEarning} 
            onOrderClick={props.onOrderClick} 
            onRightOrderClick={props.onRightOrderClick} 
            statuses={props.statuses} 
            addEscalation={clickOrderBtn}
            handleAccountAction={props.handleAddAmount}
            selectedTab={props.selectedTab}
            onGlobalSend={props.onGlobalSend}
            handleShowAccOrderDetails={props.handleShowAccOrderDetails}
            service_id={props.service_id}
            />
        ) : [];
        
        return (
            !props.showContent2?
                    <React.Fragment />
            :
                props.showLoader ?
                    <React.Fragment>
                    <div className={props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300" : "border-box clearfix cars ccspan leftalign weight300"}>
                                    <div className={props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500" : "border-box clearfix eespan leftalign weight500"}>Orders</div>
                                </div>
                                <div className={props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                                    {orders}
                                </div>
                                <Loader fullHeight={false}/>
                        </React.Fragment>
                :
                    <React.Fragment>
                        <div style={{ border: 'none' }} className={props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300 flexBox" : "border-box clearfix cars ccspan leftalign weight300 flexBox"}>
                            {orders.length>0 && <div className={props.theme == "light" ? "border-box clearfix blackspan56 leftalign weight500 orderHead" : "border-box clearfix eespan leftalign weight500"}>Orders</div>}
                        </div>
                        {/* <NewOrder 
                            theme={props.theme}
                            orderContent={props.content1}
                            mobile={props.selectedTab}
                            leadCreated={props.leadCreated}
                            leadDetails={props.thread && props.thread.leadDetails ?  props.thread.leadDetails : {}}
                        />  */}
                        <div className={props.theme == "light" ? "border-box clearfix newmain blackspan65 leftalign weight300" : "border-box clearfix newmain ccspan leftalign weight300"}>
                                {orders}
                        </div>
                        </React.Fragment>
                );
            }

export default PanelRight2