import React from 'react'
// import "./dashboard/dashboard.css"

import Constants from "../constants";

class MessageChoiceTextTab extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleClick = this.handleClick.bind(this);
    }
  
    // componentDidMount() {
    //     console.log("chatRef1");
    //     // console.log(this.props.ref.current);
    //     console.log("referring");
    //     console.log(this.ref);
    //     // this.scrollToBottom();
    //     this.props.scrollChat();
    //   }
  
      handleClick() {
        //   console.log("clicking");
        // if(this.props.clickable){
        //   this.props.onRowClick(this.props.data, "text");
        // }
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
//   <div className={this.props.theme == "light" ? "sendername border-box blackspan rightalign" : "sendername border-box whitespan rightalign"}>
      render() {
        return(
          <div className={this.props.theme == "light" ? "textActionMainChoiceTextTab border-box clearfix bgwhite" : "textActionMainChoiceTextTab border-box clearfix"} onClick={this.handleClick}>
            <div className={this.props.theme == "light" ? "actionTabChoiceTextDate border-box clearfix colorprim500span weight600 rightalign" : "actionTabChoiceTextDate border-box clearfix colorprimdark500span weight600 rightalign"}>
            {this.props.data.title}
            </div>
          </div>
        );
      }
  }
  
  
  class MessageOrderTab extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleClick = this.handleClick.bind(this);
    }
  
    // componentDidMount() {
    //     console.log("chatRef1");
    //     // console.log(this.props.ref.current);
    //     console.log("referring");
    //     console.log(this.ref);
    //     // this.scrollToBottom();
    //     this.props.scrollChat();
    //   }
  
      handleClick() {
        //   console.log("clicking");
        // iI
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
      render() {
        var statuses = JSON.parse(localStorage.getItem("statuses"));
        if(!statuses){
          statuses = [];
        }
        var filteredStatus = statuses.find(o => (o.id == this.props.data.status && o.status_type == this.props.data.order_type));
        
        // console.log("filteredStatuses", filteredStatus);
        // var order_status = typeof(this.props.data.status);
        // var order_type = typeof(this.props.data.order_type);
        // if(filteredStatuses.length > 0){
        //   status = filteredStatuses[0].status;
        // }
    
  
        return(
          <div className={this.props.theme == "light" ? "textActionMainOrderTab border-box clearfix bgwhite" : "textActionMainOrderTab border-box clearfix"} onClick={this.handleClick}>
            <div className={this.props.theme == "light" ? "actionTabOrderTitle border-box clearfix colorprim500span weight700 rightalign" : "actionTabOrderTitle border-box clearfix colorprimdark500span weight700 rightalign"}>
            {this.props.data.title}
            </div>
            <div className="actionTabOrderDate border-box clearfix rightalign">
            <span className="weight300">Ordered on</span> {Constants.get_DateString2(this.props.data.date)}
            </div>
            <div className="actionTabOrderStatus border-box clearfix weight600 rightalign">
            <span className="weight300">Status: </span><span className="greenseaspan">{filteredStatus ? filteredStatus.status : ""}</span>
            </div>
          </div>
        );
      }
  }


  class FAQ extends React.Component {
    constructor(props) {
      super(props);
  
      this.expandDiv = this.expandDiv.bind(this);
      this.state = {expand : 0};
    }
  
    expandDiv(index) {
        this.setState({expand : index});
        if (index === this.state.expand) {
          this.setState({expand : -2});
        }
      }
  
      render() {
        const FaqQA = () => this.props.data.map((quesAns, index) => {
          const toggle = index === this.state.expand ? "arrow" : "arrow rotated";
          return (
            <div key={index} className="questionAnswerContainer border-box">
              <div className={this.props.theme == "light" ? "questionContainer border-box bgwhite" : "questionContainer border-box"} aria-expanded={this.state.expand === index} onClick={() => this.expandDiv(index)}>
                <div className="questionText border-box">{quesAns.Q}</div>
                <img loading="lazy" src="/images/ic_expand_more_24px.png" className="questionControl" alt="Expand" />
              </div>
              <div className={this.props.theme == "light" ? "answer border-box bgwhite" : "answer border-box"}>
                {quesAns.A}
              </div>
            </div>
          );
        });
  
        return(
          <div className="faq-scroll-track">
            {FaqQA()}
          </div>
        );
      }
  }


class RowArchive extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleClick = this.handleClick.bind(this);
      this.getSenderText = this.getSenderText.bind(this);
    }

    // componentDidMount() {
    //     console.log("chatRef1");
    //     // console.log(this.props.ref.current);
    //     console.log("referring");
    //     console.log(this.ref);
    //     // this.scrollToBottom();
    //     this.props.scrollChat();
    //   }
  
      handleClick() {
        //   console.log("clicking");
          this.props.onRowClick(this.props.data, this.props.tag);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }

      getSenderText(text){

        function isStringHTML(str) {
          return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(str);
        }
        function createMarkup(text) {
          return {
            __html: text
          };
        };

        return (
          text && text.includes("http") ?
          <a href={text} target="_blank">{text}</a>
          : isStringHTML(text) ? <div dangerouslySetInnerHTML={createMarkup(text)} /> :
          <div>{text}</div>
        );
        
      }
  
    render() {
        // console.log(this.props);
        // console.log(this.props.tag);
        // console.log(Constants.orderTypes()[this.props.data.status_id]);
        var date = new Date(this.props.data.time);

        var dateString = Constants.get_TimeString(date);

        const se_users = JSON.parse(sessionStorage.getItem("se_users"));
        var senderInternalID = parseInt(this.props.data.senderInternalID);
        var senderInternalName = null;
        if (se_users && senderInternalID in se_users){
            senderInternalName = se_users[senderInternalID]['name'];
        }
        // console.log("senderInternalID", senderInternalID);
        // console.log("senderInternalName", senderInternalName);
        // const 

        // var textH = ""; var textM = "";

        // if (this.props.data.notification){
        //     textH = this.props.data.type.toUpperCase();
        //     textM = this.props.data.text.toUpperCase();
        // } else{
        //     textH = '';
        //     textM = this.props.data.text;
        // }

        // console.log("botting");
        // console.log(this.props.data.senderName);
        
        if(!this.props.data.senderName){
            dateString = Constants.get_DateString1Date(date);
            return(
                // <div className="archiveMessage clearfix border-box">
                //     <div className="divider centeralign border-box clearfix weight700">
                //     <table className="stdtable"><tr>
                //         <td valign="center"><div className="dividerline bgblack7"></div></td>
                //         <td valign="center" style={{width : "auto", padding : "0px 20px"}}>{dateString}</td>
                //         <td valign="center"><div className="dividerline bgblack7"></div></td>
                //     </tr></table>
                //     </div>
                // </div>
                <div className="archiveMessage clearfix border-box">
                    <div className="buffer" />
                    <div className="divider centeralign border-box clearfix weight700">
                        <div className={this.props.theme == "light" ? "dividerline bgblack9" : "dividerline bggray3"}></div>
                        <div className={this.props.theme == "light" ? "dividertext blackspan7" : "dividertext aaspan"}>{dateString}</div>
                        <div className={this.props.theme == "light" ? "dividerline bgblack9" : "dividerline bggray3"}></div>
                    </div>
                    <div className="buffer" />
                </div>
            );
        }
        else if(this.props.data.senderName != 'bot'){
            if(this.props.prevModel && this.props.prevModel.senderUserID == this.props.data.senderUserID){
                if(Constants.get_TimeComparison1(this.props.prevModel.time, this.props.data.time)){
                    return(
                        <div className="archiveMessage clearfix border-box">
                        <div className="others leftalign border-box">
                        {/* <div className="sender border-box clearfix">
                        <div className="senderimage border-box">
                            <img height="10px" width="10px" src="/images/face_blue.png" />
                        </div>
                        </div> */}
                        <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix leftalign bgwhite7 blackspan4" : "text border-box clearfix leftalign bgdark-theme2 eespan")}><span>
                        {this.getSenderText(this.props.data.textM)}
                        </span></div>
                        {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                        </div>
                        </div>
                    );
                }else{
                    return(
                        <div className="archiveMessage clearfix border-box">
                        <div className="others leftalign border-box">
                        <div className="sender border-box clearfix">
                        <div className="senderimage border-box">
                            <img height="10px" width="10px" src="/images/face_blue.png" />
                        </div>
                        <div className="sendertext border-box">
                        <div className="sendertime border-box aaspan leftalign">
                            {dateString}
                        </div>
                        </div>
                        </div>
                        <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix leftalign bgwhite7 blackspan4" : "text border-box clearfix leftalign bgdark-theme2 eespan")}><span>
                        {this.getSenderText(this.props.data.textM)}
                        </span></div>
                        {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                        </div>
                        </div>
                    );
                }
            }else{
                return(
                    <div className="archiveMessage clearfix border-box">
                    <div className="others leftalign border-box">
                    <div className="sender border-box clearfix">
                    <div className="senderimage border-box">
                        <img height="30px" width="30px" src="/images/face_blue.png" />
                    </div>
                    <div className="sendertext border-box">
                    <div className={this.props.theme == "light" ? "sendername border-box blackspan leftalign" : "sendername border-box whitespan leftalign"}>
                        {this.props.data.senderName}
                    </div>
                    <div className="sendertime border-box aaspan leftalign">
                        {dateString}
                    </div>
                    </div>
                    </div>
                    <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix leftalign bgwhite7 blackspan4" : "text border-box clearfix leftalign bgdark-theme2 eespan")}><span>
                    {this.getSenderText(this.props.data.textM)}
                    </span></div>
                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                    </div></div>	
                );
            }
        }else{
            if(true){
                if(this.props.prevModel && this.props.prevModel.senderUserID == this.props.data.senderUserID && this.props.prevModel.senderInternalID == this.props.data.senderInternalID){
                    if(Constants.get_TimeComparison1(this.props.prevModel.time, this.props.data.time)){
                        if(this.props.data.notification){
                            return(
                              <div className="archiveMessage clearfix border-box">
                              <div className="self rightalign border-box">
                              
                  
                              <div className="textNotif border-box clearfix leftalign">
                                    <div className="textNotifHeader border-box clearfix">
                                    {this.props.data.textH}
                                    </div>
                                    {this.props.data.type == "BOOKING" ? 
                                          this.getSenderText(this.props.data.textM)
                                          :
                                          <div className="textNotifMessage border-box clearfix">
                                          {this.getSenderText(this.props.data.textM)}
                                          </div>
                                    }
                                    </div>
    
                                  {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                              </div></div>
                            );
                          }else if(this.props.data.action){
                            var tabs; var faqs;
                            let title = this.props.data && this.props.data.action_type && this.props.data.action_type.title ? this.props.data.action_type.title.replace(/\./g,'.\n') : "";

                            if(this.props.data.action_type.ctas.list.type == "action_choice_orders"){
                              tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                                <MessageOrderTab key={index}  theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                              );
                            }else if(this.props.data.action_type.ctas.list.type == "action_choice"){
                              tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                                <MessageChoiceTextTab key={index} theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                              );
                            }
                            if(this.props.data.action_type.ctas.faq){
                              faqs = <FAQ data={this.props.data.action_type.ctas.faq}></FAQ>
                            }
                            return(
                              <div className="archiveMessage clearfix border-box">
                              <div className="self rightalign border-box">
                              
                  
                              <div className="textAction border-box clearfix rightalign">
                                  <div className="textActionHeader border-box clearfix">
                                  {title}
                                  {faqs}
                                  </div>
                                  <div className="textActionMain border-box clearfix">
                                    {tabs}
                                  </div>
                                  </div>
    
                                  {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                              </div></div>
                            );
                          }else{
                            return(
                                <div className="archiveMessage clearfix border-box">
                                <div className="self rightalign border-box">
                                
                    
                                    <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix rightalign bgwhite7 blackspan4" : "text border-box clearfix rightalign bgdark-theme3 whitespan")}>
                                    {this.getSenderText(this.props.data.textM)}
                                    </div>
    
                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                            );
                        }
                        
                    }else{
                        if(this.props.data.notification){
                            return(
                                <div className="archiveMessage clearfix border-box">
                                <div className="self rightalign border-box">
                                <div className="sender border-box clearfix">
                                <div className="sendertext border-box clearfix">
                                
                                <div className="sendertime border-box aaspan rightalign">
                                {dateString}
                                </div>
                                </div>
                                <div className="senderimage border-box">
                                    <img height="10px" width="10px" src="/images/app_icon_rounded-1.png" />
                                </div>
                                </div>
                    
                                <div className="textNotif border-box clearfix rightalign">
                                <div className="textNotifHeader border-box clearfix">
                                {this.props.data.textH}
                                </div>
                                {this.props.data.type == "BOOKING" ? 
                                    this.getSenderText(this.props.data.textM)
                                    :
                                    <div className="textNotifMessage border-box clearfix">
                                    {this.getSenderText(this.props.data.textM)}
                                    </div>
                                }
                                </div>

                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                                );
                        }else if(this.props.data.action){
                            var tabs; var faqs;
                            let title = this.props.data && this.props.data.action_type && this.props.data.action_type.title ? this.props.data.action_type.title.replace(/\./g,'.\n') : "";

                            if(this.props.data.action_type.ctas.list.type == "action_choice_orders"){
                              tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                                <MessageOrderTab key={index}  theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                              );
                            }else if(this.props.data.action_type.ctas.list.type == "action_choice"){
                              tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                                <MessageChoiceTextTab key={index}  theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                              );
                            }
                            if(this.props.data.action_type.ctas.faq){
                              faqs = <FAQ data={this.props.data.action_type.ctas.faq}></FAQ>
                            }
                            
                            return(
                              <div className="archiveMessage clearfix border-box">
                              <div className="self rightalign border-box">
                              <div className="sender border-box clearfix">
                                <div className="sendertext border-box clearfix">
                                
                                <div className="sendertime border-box ccspan rightalign">
                                {dateString}
                                </div>
                                </div>
                                <div className="senderimage border-box">
                                    <img height="10px" width="10px" src="/images/app_icon_rounded-1.png" />
                                </div>
                                </div>
                              
                  
                              <div className="textAction border-box clearfix rightalign">
                              <div className="textActionHeader border-box clearfix">
                              {title}
                              {faqs}
                              </div>
                              <div className="textActionMain border-box clearfix">
                                {tabs}
                              </div>
                              </div>
  
                                  {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                              </div></div>
                            );
                        }else{
                            return(
                                <div className="archiveMessage clearfix border-box">
                                <div className="self rightalign border-box">
                                <div className="sender border-box clearfix">
                                <div className="sendertext border-box clearfix">
                                
                                <div className="sendertime border-box aaspan rightalign">
                                {dateString}
                                </div>
                                </div>
                                <div className="senderimage border-box">
                                    <img height="10px" width="10px" src="/images/app_icon_rounded-1.png" />
                                </div>
                                </div>
                    
                                    <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix rightalign bgwhite7 blackspan4" : "text border-box clearfix rightalign bgdark-theme3 whitespan")}>
                                    {this.getSenderText(this.props.data.textM)}
                                    </div>

                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                            );
                        }
                    }
                }else{
                    if(this.props.data.notification){
                        return(
                            <div className="archiveMessage clearfix border-box">
                            <div className="self rightalign border-box">
                            <div className="sender border-box clearfix">
                            <div className="sendertext border-box clearfix">
                            <div className={this.props.theme == "light" ? "sendername border-box blackspan rightalign" : "sendername border-box whitespan rightalign"}>
                            {/* if (child.val().senderInternalID != null){ */}
                                {/* innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")"; */}
                            {/* }else{ */}
                            {senderInternalName ? (senderInternalName) : "GoMechanic"}
                            {/* } */}
                            </div>
                            <div className="sendertime border-box aaspan rightalign">
                            {dateString}
                            </div>
                            </div>
                            <div className="senderimage border-box">
                                <img height="30px" width="30px" src="/images/app_icon_rounded-1.png" />
                            </div>
                            </div>
                
                            <div className="textNotif border-box clearfix rightalign">
                            <div className="textNotifHeader border-box clearfix">
                            {this.props.data.textH}
                            </div>
                            {this.props.data.type == "BOOKING" ? 
                              this.getSenderText(this.props.data.textM)
                              :
                              <div className="textNotifMessage border-box clearfix">
                              {this.getSenderText(this.props.data.textM)}
                              </div>
                            }
                            </div>
    
                                {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                            </div></div>
                        );
                    }else if(this.props.data.action){
                        var faqs;
                        let title = this.props.data && this.props.data.action_type && this.props.data.action_type.title ? this.props.data.action_type.title.replace(/\./g,'.\n') : "";

                        if(this.props.data.action_type.ctas.list.type == "action_choice_orders"){
                            tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                              <MessageOrderTab key={index}  theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                            );
                          }else if(this.props.data.action_type.ctas.list.type == "action_choice"){
                            tabs = this.props.data.action_type.ctas.buttons.map((obj, index) =>
                              <MessageChoiceTextTab key={index}  theme={this.props.theme} data={obj} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} clickable={this.props.nextModel == null} />
                            );
                          }
                          if(this.props.data.action_type.ctas.faq){
                            faqs = <FAQ data={this.props.data.action_type.ctas.faq}></FAQ>
                          }
                          return(
                                <div className="archiveMessage clearfix border-box">
                                <div className="self rightalign border-box">
                                
                                <div className="sender border-box clearfix">
                            <div className="sendertext border-box clearfix">
                            <div className={this.props.theme == "light" ? "sendername border-box blackspan rightalign" : "sendername border-box whitespan rightalign"}>
                            {/* if (child.val().senderInternalID != null){ */}
                                {/* innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")"; */}
                            {/* }else{ */}
                            {senderInternalName ? (senderInternalName) : "GoMechanic"}
                            {/* } */}
                            </div>
                            <div className="sendertime border-box ccspan rightalign">
                            {dateString}
                            </div>
                            </div>
                            <div className="senderimage border-box">
                                <img height="30px" width="30px" src="/images/app_icon_rounded-1.png" />
                            </div>
                            </div>
                                
                                
                    
                                  <div className="textAction border-box clearfix rightalign">
                                <div className="textActionHeader border-box clearfix">
                                {title}
                                {faqs}
                                </div>
                                <div className="textActionMain border-box clearfix">
                                  {tabs}
                                </div>
                                </div>
        
                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                          );
                    }else{
                        return(
                            <div className="archiveMessage clearfix border-box">
                            <div className="self rightalign border-box">
                            <div className="sender border-box clearfix">
                            <div className="sendertext border-box clearfix">
                            <div className={this.props.theme == "light" ? "sendername border-box blackspan rightalign" : "sendername border-box whitespan rightalign"}>
                            {/* if (child.val().senderInternalID != null){ */}
                                {/* innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")"; */}
                            {/* }else{ */}
                            {senderInternalName ? (senderInternalName) : "GoMechanic"}
                            {/* } */}
                            </div>
                            <div className="sendertime border-box aaspan rightalign">
                            {dateString}
                            </div>
                            </div>
                            <div className="senderimage border-box">
                                <img height="30px" width="30px" src="/images/app_icon_rounded-1.png" />
                            </div>
                            </div>
                
                                <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? (this.props.theme == "light" ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgcolorprimdark500 whitespan") : (this.props.theme == "light" ? "text border-box clearfix rightalign bgwhite7 blackspan4" : "text border-box clearfix rightalign bgdark-theme3 whitespan")}>
                                {this.getSenderText(this.props.data.textM)}
                                </div>
    
                                {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                            </div></div>
                        );
                    }
                }
            }
            
        }
        
        // $(".chat").append(innerHTML);

        // $('.chat').scrollTop($('.chat').prop("scrollHeight"));
        
    }
  }

export default RowArchive;