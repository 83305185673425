import React from 'react';
import SupportChat from './support_chat/support_chat';
import {Helmet} from "react-helmet";
import HttpsRedirect from 'react-https-redirect';


class AppChat extends React.Component {
  constructor(props) {
    super(props);

    if(localStorage.getItem("theme")){
      this.state = {theme : localStorage.getItem("theme")};
    }else{
      this.state = {theme : "dark"};
    }

    this.changeTheme = this.changeTheme.bind(this);
  }

  changeTheme(theme){
    this.setState({theme : theme});
    localStorage.setItem("theme", theme);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>GoMechanic Support</title>
        </Helmet>
      {/* <div id="containermain1">
      <Topbar /> */}

      <SupportChat theme={this.state.theme} onChangeTheme={this.changeTheme}/>
      {/* </div> */}
      </React.Fragment>
    );
  }
}


export default AppChat;

// function App() {
//   console.log("protocol", window.location.protocol);
//   console.log("href", window.location.href);
//   // if (window.location.protocol !== 'https:') {
//   //   window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
//   //   return // to stop app from mounting
//   // }
//   return (
//     <React.Fragment>
//       <Helmet>
//         <title>GoMechanic Support</title>
//       </Helmet>
//     {/* <div id="containermain1">
//     <Topbar /> */}

//     <SupportChat />
//     {/* </div> */}
//     </React.Fragment>
//   );
// }

// export default App;
