import React, {useEffect, useState, lazy, useContext} from 'react';
import API from '../../api';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './NewOrder.css';
import MultiSelect from "../../custom/MultiSelect";
import firebase from '../../firebase';
import Constants,{databaseFolder} from '../../constants';
// const GoogleLocator = lazy(() => import('../mapLocator/MapLocator'));
import MapLocator from '../mapLocator/MapLocator';
import { countryContext } from '../NewRightPannel/NewRightPanel';

const NewOrder = (props) => {
    const [ carList, setCarList ] = useState([]) 
    const [remarks, setRemarks]                 = useState("");
    const [address, setAddress]                 = useState("");
    const [inputAddress, setInputAddress]       = useState("");
    const [selectedCar, setSelectedCar]         = useState({});
    const [cities, setCity]                     = useState([]);
    const [selectedCity, setSelectedcity]       = useState({});
    const [date, setDate]                       = useState("");
    const [time, setTime]                       = useState("");
    const [showCreateOrder, setCreateOrder]     = useState(false);
    const [btnClicked, setBtnClicked]           = useState(false);
    const [isLeadCreated, setLeadCreated]       = useState(props.leadCreated ? props.leadCreated : false);
    const [services, setServies] = useState();
    const [sosServices, setSosServices] = useState()
    const [selectedSosServices, setSelectedSosServices] = useState({})
    const [selectedService, setSelectedService] = useState({});
    const { country } = useContext(countryContext)
    const times = [ "Select Time","10:00:00", "11:00:00", "12:00:00", "13:00:00", "14:00:00", "15:00:00", "16:00:00", "17:00:00"] 

    const amPmTimes = {
        "10:00:00":"10:00 AM",
        "11:00:00":"11:00 AM",
        "12:00:00":"12:00 PM",
        "13:00:00":"01:00 PM",
        "14:00:00":"02:00 PM",
        "15:00:00":"03:00 PM",
        "16:00:00":"04:00 PM",
        "17:00:00":"05:00 PM",
    }
    
    const updateCityAccordingToMapLocation = (childData, location, address) => {
		let obj = {
			name: childData,
		};
		if (childData && location) {
			obj.location = location;
			if (address) {
				obj.address = address;
			}
		}
		// checkForCityExistence(obj);
        citychanger(obj);
        console.log('updateCityAccordingToMapLocation',obj);
	};

    function citychanger(value) {
        let city_found = false;
        if (value) {
            cities.forEach((element) => {
                if (element.name === value.name) {
                    setSelectedcity({
                        latitude: parseFloat(value.location.lat),
                        longitude: parseFloat(value.location.lng),
                        map_location: value.address,
                        id: element.id,
                        name: element.name,
                    });
                    setAddress(value.address);
                    city_found = true;
                }
            });
          if (!city_found) {
            // console.log("=======3==== city not found=")
            if (value.location) {
                let obj = {};
                cities.forEach((city) => {
                    obj[city.id] = {
                    name: city.name,
                    location: [city.latitude, city.longitude],
                    };
                });
                const city = Constants.getNearestCity(
                    value.location.lat,
                    value.location.lng,
                    obj
                );
                if (city) {
                    setSelectedcity({
                        name: obj[city].name,
                        latitude: parseFloat(value.location.lat),
                        longitude: parseFloat(value.location.lng),
                        map_location: value.address,
                        id: Number(city),
                    });
                    setAddress("");
                } else {
                    setSelectedcity({
                        name: "",
                        latitude: parseFloat(value.location.lat),
                        longitude: parseFloat(value.location.lng),
                        map_location: value.address,
                        id: null,
                    });
                    setAddress("");
                    NotificationManager.error('Currently we are not serviceable at this location, Kindly select the correct city', 'Error', 5000);
                }
            } else {
                setSelectedcity({});
            }
          }
        } else {
            setSelectedcity({});
        }
      }

      useEffect(()=>{
        if(selectedService.name === 'SOS' && selectedCar.id && selectedCity.id){  
            setSelectedSosServices({})  
            API.getSOSServiceList(country,selectedCar.id,selectedCity.id).then(function (response) {
                if (response.data.status){
                    let sosServiceList = response.data.data.service_list ? response.data.data.service_list : [];
                    setSosServices(sosServiceList);
                }
            })
            .catch(function (error) {
                console.log(error);
                NotificationManager.error('Error in fetching services', 'Error', 5000);
            })
        }
      },[selectedService,selectedCar,selectedCity])

    useEffect(() =>{
        if(isLeadCreated && props.leadDetails && props.leadDetails.mobile){
            setSelectedService(props.leadDetails.service_details);
            setSelectedcity(props.leadDetails.city);
            setSelectedCar(props.leadDetails.car_details);
            setRemarks(props.leadDetails.remark);
            setDate(props.leadDetails.pick_up_date);
            setTime(props.leadDetails.pick_up_time);
            setSelectedcity(props.leadDetails.city);
        }

        if(props.orderContent && props.orderContent.address &&  props.orderContent.address[0] && props.orderContent.address[0].address_2){
            setAddress(props.orderContent.address[0].address_2);
        }

        if(!isLeadCreated){
                let orderItems = props.orderContent && props.orderContent.cars && props.orderContent.cars[0] ? props.orderContent.cars[0] : "";
                let brand = orderItems && orderItems.car_tbl ? orderItems.car_tbl.brand.name : "";
                let model = orderItems && orderItems.car_tbl ? orderItems.car_tbl.model.name : "";
                let fuel_type = orderItems && orderItems.car_tbl ? orderItems.car_tbl.type.name : "";
                let car_id = orderItems && orderItems.car_type_id ? orderItems.car_type_id : "";
                let cars_details = `${brand} ${model} ${fuel_type}`;
                console.log(cars_details,'cars_details',orderItems);

                let obj = {"id":car_id, "car_detail": cars_details, "is_luxury": 0};
                setSelectedCar(obj);
        

                let addressItem = props.orderContent && props.orderContent.address && props.orderContent.address[0] ? props.orderContent.address[0] : "";

                var latitude = addressItem.latitude ? addressItem.latitude : "";
                var longitude = addressItem.longitude ? addressItem.longitude : "";
        }
        getServices();
        getCities(latitude, longitude);

    },[props.orderContent]);

    useEffect(() => {
        API.getCars(country).then((response)=>{
        if(response.data.status) 
            setCarList(response.data.data)
        else 
            NotificationManager.error('Error in fetching cars', 'Error', 5000);
        }).catch(()=> NotificationManager.error('Error in fetching cars', 'Error', 5000))       
    },[])

    const getCities = (latitude, longitude) => {
        API.getCityList(latitude, longitude, country).then(function (response) {
            if (response.data.status){
                console.log('city_list',response.data);

                let cityList = response.data.data && response.data.data.city_list ? response.data.data.city_list : [];
                let currentCity = response.data.data && response.data.data.city ? response.data.data.city : [];

                if(!isLeadCreated && currentCity && latitude && longitude){
                    let cityObj = {"id": currentCity.id, "name": currentCity.name, "latitude": currentCity.latitude, "longitude": currentCity.longitude};
                    setSelectedcity(cityObj);
                }

                let cityOptions = [];
                if(cityList.length > 0){
                    cityList.map((obj) => {
                        let tempCity = {"id": obj.id, "name" : obj.name, "latitude": obj.latitude, "longitude": obj.longitude};
                        cityOptions.push(tempCity);
                    });
                    setCity(cityOptions);
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Error in fetching cities', 'Error', 5000);
        })
    }
    const getServices = () => {
        API.getOrderServiceList(country).then(function (response) {
            if (response.data.status){
                console.log('service_list',response.data);

                let serviceList = response.data.data ? response.data.data : [];
                setServies(serviceList);
            }
        })
        .catch(function (error) {
            console.log(error);
            NotificationManager.error('Error in fetching services', 'Error', 5000);
        })
    }

    const onChange = (val, type) => {
        console.log(val);
        if(val.id){
            if(type == "car")
                setSelectedCar(val);
            if(type == "city"){
                setSelectedcity(val);
                setAddress("");
            }
        }
    }
    const handleRemarkChange = (e) => {
        let remark =  e.target.value ? e.target.value : null;
        setRemarks(remark);
    }

    const createLead = (e) => {
        setBtnClicked(true);
        let params = {};
        params['address']           = address;
        params['car_details']       = selectedCar;
        params['city']              = selectedCity;
        params['service_details']   = selectedService;
        params['pick_up_date']      = date;
        params['pick_up_time']      = time;
        params['create_date']       = new Date().getTime();
        params['remark']            = remarks;
        params['service_amount']    = selectedService && selectedService.name==='SOS' ? selectedSosServices.price_details && selectedSosServices.price_details.length > 0 && selectedSosServices.price_details[0].total:selectedService.price;
        params['service_name']      = selectedService && selectedService.name==='SOS' ? selectedSosServices.name : selectedService.name;
        params['service_id']        = selectedService.id;
        params['mobile']            = props.orderContent.mobile ? props.orderContent.mobile : "";
        params['created_by']        = localStorage.getItem("user_id") ? parseInt(localStorage.getItem("user_id")) : "";
        params['location_id']       = selectedCity.id ? selectedCity.id : "";
        params['car_type_id']       = selectedCar.id ? selectedCar.id : "";
        params['lead_type']         = selectedService.type;
        params['latitude']          = selectedCity.latitude ? selectedCity.latitude : "";
        params['longitude']         = selectedCity.longitude ? selectedCity.longitude : "";
        params['retail_service_type_id'] = selectedSosServices.id || ''
        params['price_details']     = selectedSosServices && selectedSosServices.price_details ? selectedSosServices.price_details[0] : [] 
        console.log('createLead',params);
        // return;
        
        API.createLead(params, country, selectedService.name).then(function (response) {
            if (response.status){
                let mtext = `Dear ${props.name||'Customer'},
                We have booked your service for your ${selectedCar&&selectedCar.car_detail} 🚗 to the nearest GoMechanic workshop.
                Our Service Buddy 👨‍💼 would get in touch with you shortly.
                - Date 📅 :  ${params['pick_up_date']} 
                - Time Slot 🕐 : ${amPmTimes[params['pick_up_time']]}
                `;
                props.onGlobalSend(mtext,'',props.mobile)
                setLeadCreated(true);
                setBtnClicked(false);
                console.log(response.data,'response');
                let message = selectedService && selectedService.name==='SOS' ? 'SOS Order Created' : 'Lead Created';
                NotificationManager.success(message, 'Successfully', 5000);
                var app = firebase.app();
                var database = app.database(Constants.getDatabaseURL());
                database.ref(databaseFolder()+"/supportchat/"+props.orderContent.mobile+"/lead_details").set(params);
            }
        })
        .catch(function (error) {
            console.log(error);
            setBtnClicked(false);
            NotificationManager.error('Something went wrong', 'Error', 5000);
        })
    }

    return (
        <>
            { !showCreateOrder ? 
                <div style={{ border: 'none' }} className={props.theme == "light" ? "border-box clearfix cars blackspan65 leftalign weight300 flexBox" : "border-box clearfix cars ccspan leftalign weight300 flexBox"}>
                    <button style={{marginTop: "0px"}} className={props.theme == "light" ? "send-voucher-btn" : "send-voucher-btn send-voucher-btn-dark"} onClick={() => {setCreateOrder(true)}}>
                        + Create New Order
                    </button>
                </div>
            :
            <div className={props.theme == "light" ? "lastOrderDetailContainer bgwhite" : "lastOrderDetailContainer bgdark-theme1"} style={{marginTop: "20px"}}>
                <div className={props.theme == "light" ? "border-box clearfix voucher-heading" : "border-box clearfix prime voucher-heading"}>
                    New Order
                </div>
                {   
                    isLeadCreated &&
                    <div className="voucher-sent">Lead has already been created</div>
                }
                <div className="comments-label"> Service * :</div>
                {/* <div className="border-box centeralign fieldbox double order-service-container" style={{marginTop: "5px"}}>
                    <div type="text" disabled type="text" name="order-service" className="order-service field border-box small">Chat Car Inspection </div>
                    <div className="service-amount">&#x20B9; 499</div>
                </div> */}
                <React.Fragment>
                    <MultiSelect
                    options={services}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select Service"
                    disabledItems={[]}
                    disabled={isLeadCreated}
                    selectedOptions={selectedService}
                    onChange={(value) =>
                        setSelectedService(value)
                    }
                    />
                </React.Fragment>
                <div className="comments-label"> City * :</div>
                <React.Fragment>
                    <MultiSelect
                    options={cities}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select City"
                    disabledItems={[]}
                    disabled={isLeadCreated}
                    selectedOptions={selectedCity}
                    onChange={(value) =>
                        onChange(value, "city")
                    }
                    />
                </React.Fragment>
                {/* <div className="border-box centeralign fieldbox double order-service-container" style={{marginTop: "5px"}}>
                    <div type="text" disabled type="text" name="order-city" value="Chat cars Inspection" className="order-service field border-box small">
                        {selectedCity.name}
                    </div>
                </div> */}
                <div className="comments-label"> Address * :</div>
                <div className="border-box centeralign fieldbox double" style={{marginTop: "5px"}}>
                    <input type="text" value={address} onChange={(e) => setAddress(e.target.value)}  maxLength="50" type="text" name="order-address" placeholder="Enter Address" className="order-input field border-box small" disabled={isLeadCreated}/>
                </div>
                {/* {address ?  
                <div className="border-box centeralign fieldbox double order-service-container" style={{marginTop: "5px"}}>
                    <div type="text" disabled type="text" name="order-address" value="Chat cars Inspection" className="order-service field border-box small">
                        {address}
                    </div>
                </div>
                : */}
                <div className="comments-label"> Map Location  :</div>
                <MapLocator 
                    data={selectedCity && selectedCity.map_location ? selectedCity.map_location : null}
                    parentCallback={updateCityAccordingToMapLocation}
                    isDisabled={isLeadCreated}
                />
                {/* } */}
                <div className="comments-label"> CAR MAKE MODEL * :</div>
                <React.Fragment>
                    <MultiSelect
                    options={carList}
                    labelKey="car_detail"
                    checkbox_id="car_detail"
                    placeholder="Select car"
                    disabledItems={[]}
                    disabled={isLeadCreated}
                    selectedOptions={selectedCar}
                    onChange={(value) =>
                        onChange(value, "car")
                    }
                    />
                </React.Fragment>
                { selectedService.name==='SOS' && selectedCity.id && selectedCar.id && 
               <>
               <div className="comments-label"> SOS Service * :</div>
                    <MultiSelect
                    options={sosServices}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select SOS Service"
                    disabledItems={[]}
                    disabled={isLeadCreated}
                    selectedOptions={selectedSosServices}
                    onChange={(value) =>
                        setSelectedSosServices(value)
                    }
                    />
                </>}
                <div className="comments-label"> SELECT PICKUP DATE & TIME * :</div>
                <div className="border-box centeralign fieldbox double" style={{marginTop: "5px"}}>
                    <input
                    className="order-date"
                    type="date"
                    style={{ padding: "10px" }}
                    onChange={(e) => setDate(e.target.value)}
                    disabled={isLeadCreated}
                    defaultValue={date}
                    min={Constants.getCalenderMinMax()}
                    max={Constants.getCalenderMinMax(2)}
                    />
                    {/* <input
                      className="order-date"
                      type="time"
                      style={{ padding: "10px" }}
                      onChange={(e) =>
                        setTime(e.target.value)
                      }
                      disabled={isLeadCreated}
                      defaultValue={time}
                    /> */}
                    <select  
                        className="select-time"
                        style={{ padding: "10px" }} 
                        disabled={isLeadCreated}
                        value={time}
                        onChange={(e) =>
                            setTime(e.target.value)
                        }
                    >
                      {times.map((each,index)=><option hidden={index===0} value={each}key={index}>{each}</option>)}
                    </select>
                </div>
                <div className="comments-label"> REMARKS * :</div>

                <div className="border-box centeralign fieldbox double" style={{marginTop: "5px"}}>
                    <textarea  disabled={isLeadCreated} onChange={(e) => handleRemarkChange(e)} value={remarks}   maxLength="150" type="text" name="order-remark" placeholder="Enter Remarks Here" className="voucher-textarea field border-box small"/>
                </div>
                <div className="border-box fieldbox double">
                    <div className="order-note">
                        Note : Lead will be send to CR on WF App
                    </div>
                </div>
                <div className="border-box fieldbox double">
                    <button 
                    disabled={isLeadCreated || !date || !time || !selectedService.id || !selectedCity.id || !remarks || (!address && !inputAddress) || (selectedService.name==='SOS'?!selectedSosServices.id:false)}
                    onClick={(e) => createLead(e)}
                    style={{height:"40px"}}
                    className={props.theme == "light" ? "send-voucher-btn" : "send-voucher-btn send-voucher-btn-dark"}
                    >
                    {btnClicked ? 
                    (
                        <div className="loader-or"></div>
                    ) : (
                        <span>Create Order</span>
                    )}</button>
                </div>
                <button className={props.theme == "light" ? "send-voucher-btn" : "send-voucher-btn send-voucher-btn-dark"} onClick={() => {setCreateOrder(false)}}>
                    CANCEL
                </button>
            </div>
            }
        </>
    )
}

export default NewOrder