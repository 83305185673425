import React from 'react';
import "../dashboard/dashboard.css"
import  "../dashboard/dashboard2chat.css"
import $ from 'jquery';

import PanelLeft from './leftpanel';
import PanelRight from './rightpanel';
import NewRightPanel from './NewRightPannel/NewRightPanel';
import PanelMiddle from './middlepanel';
import Loader from '../loader';
import Topbar from '../topbar';

import API from '../api';
import Constants,{databaseFolder} from '../constants';
import {Redirect} from 'react-router-dom';

import firebase from '../firebase';
import ChatUtils from './chat_utils';
import ChatUtilsArchive from './chat_utils_archive';
import LogoutMain from '../LogoutMain';
import '../notifications';
import SupportChatArchives from './support_chat_archives';

// var firebase = require('firebase/app');
// require('firebase/auth');
// require('firebase/database');
// require('firebase/messaging');

const axios = require('axios');
const isObdExecutive = localStorage.getItem('phone')==='9882421364'


const tabList = [{tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];





class Overlay extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.currentView == this.props.currentView)
      return false;
    else
      return true;
  }

  render() {
    switch(this.props.currentView){
      case "archives" : {
        return(
          <SupportChatArchives theme={this.props.theme}/>
        );
        break;
      }
      default : {
        return(
          <SupportChatArchives theme={this.props.theme} />
        );
        break;
      }
    }
  }
}



class SupportChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		status : "logged in",
      rightContent1: NaN,
      rightContent2: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent1: false,
      showRightContent2: false,
      rightOrder: NaN,
      showLoader : false,
      showLeftLoader : false,
      showLeftSkelton:false,
      searchText : NaN,
      selectedTab: 0,
      selectedTabLenght: 0,
		selectedRow: "",
		filter_showing : false,
		filter_args : undefined,
		threads_list : [],
    search_threads_list : [], 
		divK : [],
		divM : [],
		divH : [],
    all_models : {},
    archived_threads_list : [],
		archived_divK : [],
		archived_divM : [],
		archived_divH : [],
    archived_all_models : {},
    live: true,
    is_online : true,
    keyArchives : NaN,
    currentView : "live",
    firstLoading : true,
    voucher : "",
    leadCreated : false, 
    showCustomerDetailPopup: false,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
	this.handleRowClick = this.handleRowClick.bind(this);
	this.handleFilterClick = this.handleFilterClick.bind(this);
	this.handleSearchClick = this.handleSearchClick.bind(this);
	this.handleThreadSearch = this.handleThreadSearch.bind(this);

	this.handleGlobalAIMessageClick = this.handleGlobalAIMessageClick.bind(this);
  this.handleGlobalSend = this.handleGlobalSend.bind(this);
  this.handleRightScroll = this.handleRightScroll.bind(this);
  this.handleLeftScroll = this.handleLeftScroll.bind(this);
  this.handleRightOrderClick = this.handleRightOrderClick.bind(this);
  this.handleBackOrderClick = this.handleBackOrderClick.bind(this);
  this.handleHistorySwitchClick = this.handleHistorySwitchClick.bind(this);
  this.handleOnlineStatusSwitchClick = this.handleOnlineStatusSwitchClick.bind(this);
  this.handleThemeSwitchClick = this.handleThemeSwitchClick.bind(this);
  this.handlePanelButtonClick = this.handlePanelButtonClick.bind(this);
  this.imageUploadButton = this.imageUploadButton.bind(this);
  this.transferChat = this.transferChat.bind(this);
  this.sendVouchers = this.sendVouchers.bind(this);
  this.updateThreadList = this.updateThreadList.bind(this);
  this.handleCustomerOverview = this.handleCustomerOverview.bind(this);
  }

  imageUploadButton(img, firebaseImages, selectedTab) {
    ChatUtils.sendImages(img, firebaseImages, selectedTab, this);
  }

  handleGlobalAIMessageClick(mText, mType, selectedTab){
	ChatUtils.sendAIMessage(mText, mType, selectedTab, this)
  }

  handleGlobalSend(mText, selectedTab){
	ChatUtils.sendMessage(mText, selectedTab, this);
  }

  handleThreadSearch(val){
    if(this.state.live){
      ChatUtils.searchThreads(val, this);
    }else{
      ChatUtilsArchive.searchThreads(val, this);
    }
  }

  handleFilterClick() {
	this.setState({filter_showing : !this.state.filter_showing});
  }

  handleSearchClick(args) {
	  console.log("searching");
	  console.log(args);
	  this.setState({filter_args : args, filter_showing : !this.state.filter_showing});
	  API.tabSearchCall(this.state.selectedTab, this, args);
	//this.setState({filter_showing : !this.state.filter_showing});
  }

  handleRowClick(data, tag) {
	console.log(data);
	console.log(tag);
	// this.setState({selectedRow : data.order_id, showRightContent : true, rightContent : data});
  }

  handleTabClick(tag) {
    var that = this;
    if(this.state.live){
      ChatUtils.clickTab(tag, this, this.state.threads_list);
    }else{
      ChatUtilsArchive.clickTab(tag, this, this.state.archived_threads_list);
    }
    // API.getChatUserDetails(tag, this);
    // API.getSupportChatArchivesByKey(tag, this);
    // API.getOrderDetailsPaginate(tag, [], this);
	// API.tabClickCall(tag, that);
  }

  handleRightScroll(tag, orders) {
    var that = this;
    console.log("scrollHandling");
    API.getOrderDetailsPaginate(tag, orders, this);
	// API.tabClickCall(tag, that);
  }

  handleLeftScroll(live, mine) {
    var that = this;
    console.log("this", this);
    console.log("scrollLeftHandling");
    if(!live){
      API.getSupportChatArchives(this, this.state.archived_threads_list.length, mine, this);
    }
	// API.tabClickCall(tag, that);
  }

  transferChat(){
    this.setState({selectedTab : 0});
  }

  sendVouchers(details){
    let obj = {
      message : details.message, 
      title : "Voucher Code", 
      notif_type: "Discount Coupon",
      remarks: "",
      code : details.code,
    };
    ChatUtils.sendAIMessage(obj, "notification", this.state.selectedTab, this)
  }
  
  handleRightOrderClick(order) {
    var that = this;
    console.log("RightRightOrder");
    console.log(order);
    this.setState({rightOrder : order});
	// API.tabClickCall(tag, that);
  }

  handleBackOrderClick() {
    var that = this;
    console.log("RightRightOrder");
    this.setState({rightOrder : NaN});
	// API.tabClickCall(tag, that);
  }

  handleHistorySwitchClick(type, mine) {
    var that = this;
    if(type == "live"){
      this.setState({live : true});
    } else{
      this.setState({live : false});
      if(this.state.archived_threads_list.length == 0){
        API.getSupportChatArchives(this, this.state.archived_threads_list.length, mine, this);
      }
    }
  }
  

  handleOnlineStatusSwitchClick(type, online){
    // this.setState({is_online : online});
    if(this.state.is_online != online){
      ChatUtils.setOnlineStatus(this, online);
      API.postSessionDetails(online);
    }
  }

  handleThemeSwitchClick(type, theme){
    console.log("theme1", theme);
    this.props.onChangeTheme(theme);
  }

  handlePanelButtonClick(type){
    if(this.state.currentView != type){
      this.setState({currentView : type});
    }
  }
  handleCustomerOverview(value){
     this.setState({showCustomerDetailPopup:value}) 
  }
  updateThreadList(list){
    // console.log('updateThreadList')
    this.setState({threads_list: list});
  }
  componentWillReceiveProps(){
    
  }

  componentWillMount() {
    if(localStorage.getItem("access_token") === null){
      this.setState({status : 'not logged in'});
    }else{
      var that = this;
        if(sessionStorage.getItem("reloaded")){
          this.setState({showLeftSkelton:true})
          ChatUtils.loadInitial(that, false);
        }else{
          sessionStorage.setItem("reloaded", true);
          window.location.reload();
        }
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    console.log("UnMOunting");
    // ChatUtils.clearListeners();
    // this.setState({
    //   status : "logged in",
    //     rightContent1: NaN,
    //     rightContent2: NaN,
    //     middleContent: NaN,
    //     showMiddleContent: false,
    //     showRightContent1: false,
    //     showRightContent2: false,
    //     rightOrder: NaN,
    //     showLoader : false,
    //     searchText : NaN,
    //   selectedTab: 0,
    //   selectedRow: "",
    //   filter_showing : false,
    //   filter_args : undefined,
    //   threads_list : [],
    //   divK : [],
    //   divM : [],
    //   divH : [],
    //   all_models : {}
    //   });
  }

  render() {
	  if(this.state.status === "not logged in" || new Date(localStorage.getItem("token_expiration_date")) <= new Date()){
		  localStorage.removeItem("access_token");
		  localStorage.removeItem("token_expiration_date");
		  return <Redirect to="/login"/>
	  }else if((localStorage.getItem("user_id") == 951161438 || localStorage.getItem("user_id") == "951161438")){
      localStorage.removeItem("access_token");
		  localStorage.removeItem("token_expiration_date");
      // return <Redirect to="/chat"/>
      var app = firebase.app();
      var database = app.database(Constants.getDatabaseURL());
      var chatRef = database.ref(databaseFolder()+'/supportchat/hack');
      chatRef.child(localStorage.getItem("user_id")).child("is_loggedOut").set(true);


        var ipV4 = null;
        var ipV6 = null;
        var location = null;
        API.getIPV4Address().then(function (response) {
            // console.log("ipaddress", response.data);
            if (response.data.ip){
                ipV4 = response.data.ip;
            }
            })
            .catch(function (error) {
            // handle error
            console.log(error);
            })
            .finally(function () {
                API.getIPV6Address().then(function (response) {
                    if (response.data.ip){
                        ipV6 = response.data.ip;
                    }
                    console.log("ipaddress", ipV4, ipV6);
                    
                    })
                    .catch(function (error) {
                    // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        API.getIPLocation().then(function (response) {
                            // if (response.data.ip){
                            //     ipV6 = response.data.ip;
                            // }
                            // console.log("location", response.data);
                            location = {
                                country: response.data.country_name ? response.data.country_name : null,
                                countryCode: response.data.country_code ? response.data.country_code : null,
                                region: response.data.region_code ? response.data.region_code : null,
                                regionName: response.data.region ? response.data.region : null,
                                city: response.data.city ? response.data.city : null,
                                zip: response.data.postal ? response.data.postal : null,
                                lat: response.data.latitude ? response.data.latitude : null,
                                long: response.data.longitude ? response.data.longitude : null,
                                timezone: response.data.timezone ? response.data.timezone : null
                            }
                            
                            })
                            .catch(function (error) {
                            // handle error
                                console.log(error);
                            })
                            .finally(function () {
                                // const browser = Bowser.getParser(window.navigator.userAgent);
                                // console.log("browser", browser.parsedResult);
                                chatRef.child(localStorage.getItem("user_id")).child("info").child("ip").set({ipV4 : ipV4, ipV6 : ipV6});
                                chatRef.child(localStorage.getItem("user_id")).child("info").child("device").set({type : "web"});
                                chatRef.child(localStorage.getItem("user_id")).child("info").child("location").set(location);
                            });
                    });
            });
            return <Redirect to="/login"/>
    }else{
      // if((localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1) || localStorage.getItem("phone") == "9990903321"){
        if(true){
        return(
          <div id="containermain1" className={this.props.theme == "light" ? "bgwhite" : "bgdark-theme3 dark-theme"}>
            <Topbar isOnline = {this.state.is_online} onOnlineStatusSwitchClick={this.handleOnlineStatusSwitchClick} theme={this.props.theme} onThemeSwitchClick={this.handleThemeSwitchClick} onPanelButtonClick={this.handlePanelButtonClick} 
            currentView={this.state.currentView}/>
            
            {this.state.currentView == "live" ? 
            <div id="containermainouter1">
              {/* <PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedTab={this.state.selectedTab} allModels={this.state.all_models} 
                divK={this.state.divK} divM={this.state.divM} divH={this.state.divH}/> */}
              <PanelLeft theme={this.props.theme} onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab} archivedThreadsList = {this.state.archived_threads_list}
                threadsList = {isObdExecutive ? this.state.threads_list.filter((each)=>each.source==='obd_chat_link') : this.state.threads_list} archivedAllModels = {this.state.archived_all_models} allModels = {this.state.all_models} onThreadSearch={this.handleThreadSearch}
                searchText={this.state.searchText} onHistorySwitchClick={this.handleHistorySwitchClick} live={this.state.live} onLeftScroll={this.handleLeftScroll} showLeftLoader={this.state.showLeftLoader}
                showLeftSkelton={this.state.showLeftSkelton}  handleCustomerOverview={this.handleCustomerOverview} search_threads_list = {this.state.search_threads_list} 
                /> 
              <PanelMiddle theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedThreadsList = {this.state.archived_threads_list} live={this.state.live} 
                isOnline = {this.state.is_online} threadsList = {this.state.threads_list} archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models} searchText={this.state.searchText}
                archivedDivK={this.state.archived_divK} archivedDivM={this.state.archived_divM} archivedDivH={this.state.archived_divH} keyArchives = {this.state.keyArchives}
                divK={this.state.divK} divM={this.state.divM} divH={this.state.divH} onRowClick={this.handleRowClick} onGlobalAIMessageClick={this.handleGlobalAIMessageClick} imageUploadButton={this.imageUploadButton} onGlobalSend={this.handleGlobalSend}
                selectedTabLenght={this.state.selectedTabLenght} firstLoading={this.state.firstLoading} updateThreadList={this.updateThreadList}  showCustomerDetailPopup={this.state.showCustomerDetailPopup} handleCustomerOverview={this.handleCustomerOverview}
                 />
               <NewRightPanel theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models} 
                isOnline = {this.state.is_online} threadsList = {this.state.threads_list} showLoader={this.state.showLoader} rightOrder={this.state.rightOrder} onRightOrderClick={this.handleRightOrderClick}
                content1={this.state.rightContent1} content2={this.state.rightContent2} showContent1={this.state.showRightContent1} showContent2={this.state.showRightContent2} onRightScroll={this.handleRightScroll}
                onBackOrderClick={this.handleBackOrderClick} transferChat={this.transferChat} sendVouchers={this.sendVouchers} sentVoucher={this.state.voucher} leadCreated={this.state.leadCreated} onGlobalSend={this.handleGlobalAIMessageClick}/>

              {/* <PanelRight theme={this.props.theme} tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models} 
                isOnline = {this.state.is_online} threadsList = {this.state.threads_list} showLoader={this.state.showLoader} rightOrder={this.state.rightOrder} onRightOrderClick={this.handleRightOrderClick}
                content1={this.state.rightContent1} content2={this.state.rightContent2} showContent1={this.state.showRightContent1} showContent2={this.state.showRightContent2} onRightScroll={this.handleRightScroll}
                onBackOrderClick={this.handleBackOrderClick} transferChat={this.transferChat} sendVouchers={this.sendVouchers} sentVoucher={this.state.voucher} leadCreated={this.state.leadCreated}/> */}


            </div>
            : <div id="containermainouter1" className={this.props.theme == "light" ? "bgwhite" : "bgdark-theme3 dark-theme"}><Overlay theme={this.props.theme}
            currentView={this.state.currentView} /></div>
            }
          </div>
        );
      }else{
        return (
          <Redirect to="/"/>
        );
      }
	  }
  }
}


export default SupportChat;
