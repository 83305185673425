import React from 'react';
import MiddleMainArchives from './middlemain_archives';
import ChatUtils from './chat_utils';


class PanelMiddleArchives extends React.Component {
    constructor(props) {
      super(props);
  //    this.handleTabClick = this.handleTabClick.bind(this);
  //    this.state = {showContent: false};
      this.sendieRef = React.createRef();
      this.chatRef = React.createRef();
      this.scrollToBottom = this.scrollToBottom.bind(this);

      this.handleTextChange = this.handleTextChange.bind(this);
      
      this.state = {text : ""};
    }
  
    componentDidMount() {
      ////console.log("refreshing");
      // //console.log("chatRef");
      // //console.log(this.chatRef.current);
      // this.scrollToBottom();
    }

    scrollToBottom() {
      //console.log("scrolling");
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }

    handleTextChange(e){
      //console.log(e.target.value);
      this.setState({text : e.target.value});
    }
  
    render() {
      // //console.log("rendering11", this.props.threadsList);
      var props = this.props;
      // //console.log("threads", this.props.threadsList);
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      
      return(
      <div className={this.props.theme == "light" ? "middlemain border-box bgwhite centeralign" : "middlemain border-box bgdark-theme3 centeralign"}>
          <div className={this.props.theme == "light" ? "chat shadow-box" : "chat shadow-box dark-theme"} id="messageContainer" ref={this.chatRef} style={{bottom : "0px"}}><MiddleMainArchives {...props} scrollChat={this.scrollToBottom} /></div>
          </div>
      );
        
    }
  }


  // const WrappedPanelMiddle = React.forwardRef((props, ref)  => {return (<PanelMiddle {...props} forwardedRef = {chatRef}/>) });
  export default PanelMiddleArchives;