import React, { useState, useEffect } from 'react';
import './ManageUserPopUp.css';
import firebase from '../firebase';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {databaseFolder} from '../constants'
import Constants from '../constants'

const ManageUserPopUp = (props) => {
  const { modal } = props;
  const [executives, setExecutives] = useState([]);

  function toggleRadio(index, e, exec_id) {
    let is_online = e.target.checked;
    let temp =  [...executives];
    console.log('temp',temp);
    temp[index]["is_online"] = is_online;
    
    var app = firebase.app();
    var database = app.database(Constants.getDatabaseURL());
    database.ref(databaseFolder()+"/executives/"+exec_id+"/is_online").set(is_online);
    NotificationManager.success('Data saved', 'Successfully', 5000);
    setExecutives(temp);
  }
  useEffect(() => {
    var app = firebase.app();
  
    var database = app.database(Constants.getDatabaseURL());
    var seRef = database.ref(databaseFolder()+'/executives');
  
    if(executives.length == 0){
      let listener1 = seRef.once('value').then(function(data) {
        // console.log("quick",data);
        let exec = [];
        data.forEach(function(childNode){
          let temp = {};
          temp['id'] = childNode.key;
          temp['name'] = childNode.val().name;
          temp['is_online'] = childNode.val().is_online;
          // temp['chat_count'] = childNode.val().chat_count ? childNode.val().chat_count : 0;
          exec.push(temp);
        })
        setExecutives(exec);
      });
    }
  })
  return (
      <>
        <div className="modalComponent-mu" onClick={modal} />
        <div className="modalBackground-mu">
          <div className="subComponent-mu">
            <div className="list-mu">Manage Users</div>
            <table className="table-mu">
              <tr>
                <th>Sr. No.</th>
                <th>Executive Name</th>
                {/* <th>Chat Count</th> */}
                <th>Active</th>
              </tr>
              {
                executives.map((item, index) => {
                  let sr_no = index + 1;
                  let executive_name = item && item.name ? item.name : "";
                  let is_online = item && item.is_online ? item.is_online : "";
                  // let chat_count = item && item.chat_count ? item.chat_count : "NA";
                  
                  return(
                    executive_name ? 
                    <tr>
                      <td>{sr_no}</td>
                      <td>{executive_name}</td>
                      {/* <td>{chat_count}</td> */}
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleOTPSwitch"
                            id={`toggleSwitch` + index}
                            onChange={(e) => toggleRadio(index, e, item.id)}
                            defaultChecked={false}
                            checked={is_online}
                          />
                          <label className="toggle-switch-label" htmlFor={`toggleSwitch` + index}>
                            <span className="toggle-switch-inner" />
                            {/* <span className="toggle-switch-switch" /> */}
                          </label>
                        </div>
                      </td>
                    </tr> : ""
                  )
                })
              }
            </table>
          </div>
        </div>
    </>
  )
}

export default ManageUserPopUp;